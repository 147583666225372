import classNames from 'classnames';
import "wicg-inert";
import { Button, Paragraph, VideoConsent } from 'ui/components';
import styles from './VideoList.module.scss';
import { ReactComponent as IconNext } from 'ui/icons/icon_btn-right.svg';
import { ReactComponent as IconPrev } from 'ui/icons/icon_btn-left.svg';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useState, useEffect, useRef } from 'react';
import GlueFrame from 'glueframe';

export interface VideoListProps {
	listDescription?: string;
	moduleMargin?: boolean;
	videos?: {
		embedLink?: string;
		heading?: string;
		description?: string;
		id: string;
	}[];
}

export const VideoList: React.FC<VideoListProps> = ({ videos, listDescription, moduleMargin }) => {
	const dictionary = useDictionaryContext();
	const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
	const [currentVideo, setCurrentVideo] = useState(videos[currentVideoIndex]);
	const [currentVideoApp, setCurrentVideoApp] = useState(null);
	const [playAll, setPlayAll] = useState(false);
	const [fullScreen, setFullScreen] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const videoContainer = useRef(null);
	const setVideoFullScreen = (e) => {
		videoContainer.current.classList.add(styles.VideoList_fullScreen);
		setFullScreen(true);
		// currentVideoApp.set('fullscreen', true);
	};

	const exitVideoFullScreen = (e) => {
		videoContainer?.current?.classList.remove(styles.VideoList_fullScreen);
		setFullScreen(false);
	};

	const closeEscape = (e) => {
		if (e.keyCode === 27) {
			exitVideoFullScreen(e);
		}
	};

	const gotoNextVideo = () => {
		if (currentVideoIndex === videos.length - 1) 
			setCurrentVideoIndex(0);
		if (currentVideoIndex < videos.length - 1) 
			setCurrentVideoIndex(currentVideoIndex + 1);
	};

	const gotoPrevVideo = () => {
		if (currentVideoIndex !== 0) 
			setCurrentVideoIndex(currentVideoIndex - 1);
	};

	const videoListClick = (index) => {
		setCurrentVideo(videos[index]);
		setCurrentVideoIndex(index);
	};

	useEffect(() => {
		setCurrentVideo(videos[currentVideoIndex]);
	}, [currentVideoIndex, videos]);

	useEffect(() => {
		if(!videoContainer.current.querySelector('iframe')) {
			return
		}
		const videoApp = new GlueFrame(videoContainer.current.querySelector('iframe'), 'Player') || '';
		setCurrentVideoApp(videoApp);
		if (!isFirstLoad) { 
			videoApp.bind("player:video:ready", () => {
				videoApp.set('playing', true);
			});
		}

		videoApp.set('playing', true);
		videoApp.bind('player:video:ended', () => {
			if (playAll) {
				gotoNextVideo();
			}
		});

		return () => {
			videoApp.destroy();
		};
		setIsFirstLoad(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentVideo, playAll]);

	

	useEffect(() => {
		const keyDownHandler = event => {
			
			if (event.key === 'Enter') {
				if (event.target.className.includes("SkipToMain"))
				return
				event.preventDefault();
				//if (event.target.classList.contains("keyhandler-parent")){
					if (event.target.classList.contains("keyHandler-parent") && event.target.firstChild.classList.contains('keyHandler')) {
						event.target.firstChild.click();
					}
				//}
				if (event.target.classList.contains('wcag-enter-click')) {
					event.target.click();
				}
			}
		};
	
		document.addEventListener('keydown', keyDownHandler);
	
		return () => {
		  document.removeEventListener('keydown', keyDownHandler);
		};
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', closeEscape);

		const videoListDiv = document.querySelectorAll('.inert-trigger') || [];
		videoListDiv.forEach((div) => {
			div.setAttribute('inert', '')
		})
	}, []);

	if (!currentVideo) return (
		<div id="currentVideo" ref={videoContainer}>No video</div>
	);

	return (
		<div className={classNames(
				styles.VideoList,
				{ [styles.VideoList___margin]: moduleMargin === true },
			)}>
			{videos && (
				<>
					<div id="currentVideo" ref={videoContainer}>
						<VideoConsent video={currentVideo.embedLink } />
					</div>

					<div className={styles.VideoList_controls}>
						<div className={classNames(styles.VideoList_controlButtons)}>
							<div>
								<Button
									className='wcag-enter-click'
									style="secondary"
									onClick={() => {
										setPlayAll(!playAll);
									}}
								>
									{dictionary.getValue('Action.PlayAll', null, 'Afspil alle')}
								</Button>
							</div>
							<div>
								<Button
									className='wcag-enter-click'
									style="secondary"
									onClick={(e) => {
										setVideoFullScreen(e);
									}}
								>
									{dictionary.getValue('Action.Fullscreen', null, 'Se i fuldskærm')}
								</Button>
							</div>
							{fullScreen &&
								<Button
									className={styles.VideoList_exitVideoFullScreenButton}
									style="secondary"
									onClick={(e) => {
										exitVideoFullScreen(e);
									}}
								>
									{dictionary.getValue('Action.FullscreenClose', null, 'Afslut fuldskærm')}
								</Button>
							}
						</div>
						
						<div className={classNames(styles.VideoList_controlButtons, styles.VideoList_controlButtons___arrow)}>
							<div
								onClick={() => {
									gotoPrevVideo();
								}}
							>
								<Button
									className={classNames(styles.VideoList_navigation, styles.VideoTeaser_navigation___prev, 'wcag-enter-click')}
									ariaLabel={dictionary.getValue('Action.Previous', null, 'Forrige')}
									style="secondary"
								>
									<IconPrev aria-hidden="true" />
								</Button>
								<div>
									<Paragraph style="sm">{dictionary.getValue('Action.Previous', null, 'Forrige')}</Paragraph>
								</div>
							</div>

							<div
								onClick={() => {
									gotoNextVideo();
								}}
							>
								<div>
									<Button
										className={classNames(styles.VideoList_navigation, styles.VideoTeaser_navigation___next, 'wcag-enter-click')}
										ariaLabel={dictionary.getValue('Action.Next', null, 'Næste')}
										style="secondary"
									>
										<IconNext aria-hidden="true" />
									</Button>
								</div>

								<div>
									<Paragraph style="sm">{dictionary.getValue('Action.Next', null, 'Næste')}</Paragraph>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.VideoList_description} dangerouslySetInnerHTML={{ __html: listDescription }} />

					<div className={styles.VideoList_list}>
						{videos.map((item, index) => (
							<div id={item.id} key={index} tabIndex={0} className="keyHandler-parent">
								<div className={classNames(styles.VideoList_listMediaWrapper, "keyHandler")} 
									onClick={() => videoListClick(index)}
								>
									<div
										className={classNames(styles.VideoList_listMedia,
											"inert-trigger",
											{ [styles.VideoList_listMedia___Active]: index === currentVideoIndex ? true : false })} 
										dangerouslySetInnerHTML={{ __html: item?.embedLink }} 
									/>
								</div>
								<Paragraph>{item?.heading}</Paragraph>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};
function e(e: any, currentVideoIndex: number): (this: Window, ev: KeyboardEvent) => any {
	throw new Error('Function not implemented.');
}

