import classNames from 'classnames';
import {
	Heading,
	Grid,
	GridCell,
	GridCellProps,
	Container,
	ListCard,
	ListCardProps,
	BlurbCard,
	BlurbCardProps,
	LinkButton,
} from 'ui/components';
import styles from './Teaser.module.scss';

export interface TeaserProps {
	heading?: string;
	backgroundColor?: string;
	contentPicker?: any;
	link?: Content.Link;
	listCards?: ListCardProps[];
	blurbCards?: BlurbCardProps[];
	settings?: {
		desktopWidth?: GridCellProps['desktopWidth'];
		mobileWidth?: GridCellProps['mobileWidth'];
	};
}

export const Teaser: React.FC<TeaserProps> = ({ heading, link, listCards, blurbCards, settings, backgroundColor }) => {
	
	const desktopWidth = settings?.desktopWidth ?? '';
	const mobileWidth = settings?.mobileWidth ?? '';
	const bgColor =  backgroundColor ? backgroundColor : 'var(--theme-10)';
	return (
		<div className={classNames(styles.Teaser)} style={{ backgroundColor: bgColor }}>
			<Container width="Standard">
				<div className={styles.Teaser_header}>
					{heading && (
						<div className={styles.Teaser_headingContainer}>
							<Heading className={styles.Teaser_heading} headingLevel="h2" style="lg">
								{heading}
							</Heading>
						</div>
					)}

					{link && (
						<div className={classNames(styles.Teaser_buttonContainer)}>
							<LinkButton ariaLabel={link.name} url={link.url} style="secondary" title={link.name} className={classNames(styles.Teaser_button, styles.Teaser_button___desktop)}>
								{link.name}
							</LinkButton>
						</div>
					)}
				</div>

				{listCards && (
					<>
						{/* DESKTOP RENDERING */}
						<div className={classNames(styles.Teaser_listCards, styles.Teaser_listCards___desktop)}>
							<Grid wrap>
								{listCards.slice(0, 3).map((listCard, index) => (
									<GridCell key={index} desktopWidth={'33'} mobileWidth={'100'} className={styles.Teaser_listCardsContainer}>
										<ListCard key={index} {...listCard} />
									</GridCell>
								))}
							</Grid>
						</div>

						{/* MOBILE RENDERING */}
						<div className={classNames(styles.Teaser_listCards, styles.Teaser_listCards___mobile)}>
							{listCards.slice(0, 1).map((listCard, index) => (
								<ListCard key={index} {...listCard} />
							))}
						</div>
					</>
				)}

				{blurbCards && (
					<>
						{/* DESKTOP RENDERING */}
						<div className={classNames(styles.Teaser_listCards, styles.Teaser_listCards___desktop)}>
							<Grid wrap>
								{blurbCards.slice(0, 3).map((listCard, index) => (
									<GridCell key={index} desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
										<BlurbCard key={index} {...listCard} />
									</GridCell>
								))}
							</Grid>
						</div>

						{/* MOBILE RENDERING */}
						<div className={classNames(styles.Teaser_listCards, styles.Teaser_listCards___mobile)}>
							{blurbCards.slice(0, 1).map((listCard, index) => (
								<BlurbCard key={index} {...listCard} />
							))}
						</div>
					</>
				)}

				{link && (
					<LinkButton ariaLabel={link.name} url={link.url} style="secondary" className={classNames(styles.Teaser_button, styles.Teaser_button___mobile)}>
						{link.name}
					</LinkButton>
				)}
			</Container>
		</div>
	);
};
