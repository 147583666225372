//import { getDictionaryFromCms } from 'application/adapters/cms/dictionaryAdapter';

export interface Replacement {
	name: string;
	value: string;
}

export interface DictionaryInterface {
	getValue(key: string, replacements?: Replacement[], defaultValue?: string): string;
	items: Content.DictionaryItem[];
	culture: string;
}

export const DictionaryFactory = (items: Content.DictionaryItem[], culture: string): DictionaryInterface => {
	return {
		getValue(key: string, replacements?: Replacement[], defaultValue?: string): string {
			const dictionaryValue: Content.DictionaryItem = items.find((element) => element?.key.toLocaleLowerCase() === key.toLocaleLowerCase());
			let dictionaryString = dictionaryValue?.values?.find(
				(element) => element?.culture.toLocaleLowerCase() === culture.toLocaleLowerCase(),
			)?.value;

			if (!dictionaryString) {
				if (!defaultValue) {
					//Cannot find dictionary text and no default supplied - in development we use a default error text
					return process.env.NODE_ENV === 'development' ? `[DICTIONARY ${key} MISSING FOR ${culture}]` : null;
				} else {
					return (dictionaryString = defaultValue);
				}
			}

			if (!replacements) {
				return dictionaryString;
			}

			replacements.forEach((replacement) => {
				return (dictionaryString = dictionaryString.replace('${' + replacement.name + '}', replacement.value));
			});

			return dictionaryString;
		},
		items: items,
		culture: culture as string,
	};
};

export interface getDictionaryItemsProps {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
}
// export const getDictionaryItems = async ({ host, preview, previewData, accessToken }: getDictionaryItemsProps): Promise<Content.DictionaryItem[]> => {
// 	return await getDictionaryFromCms(host, preview, previewData, accessToken);
// };
