import classNames from 'classnames';
import styles from './ListCard.module.scss';
import { Heading, Paragraph, LinkButton } from 'ui/components';
import { formatDate } from 'helpers/date';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface ListCardProps {
	className?: string;
	job?: { title: string; name: string; location: string; id: string; latestDate: Date };
	event?: { title: string; date: string; dateFormatted: string; startTime: string; endTime?: string; location?: string; price: string; month?: string; url?: string };
	link?: Content.Link;
}

export const ListCard: React.FC<ListCardProps> = ({ className, job, event, link }) => {
	const dictionary = useDictionaryContext();
 
	return (
		<div className={classNames(styles.ListCard, className)}>
			{job && (
				<>
					<Heading headingLevel="h3" style="xs" className={styles.ListCard_heading}>
						{job?.title}
					</Heading>
					<div className={styles.ListCard_listInfo}>
						<Paragraph style="smCaption" className={styles.ListCard_label}>
							{dictionary.getValue('Job.Location', null, 'STED')}
						</Paragraph>
						<div>
							<Paragraph style="md">{job?.name}</Paragraph>
							<Paragraph style="md">{job?.location}</Paragraph>
						</div>
					</div>
					<div className={styles.ListCard_listInfo}>
						<Paragraph style="smCaption" className={styles.ListCard_label}>
							{dictionary.getValue('Job.Location', null, 'STED')}
						</Paragraph>
						<Paragraph style="md">{job?.id}</Paragraph>
					</div>
					<Paragraph style="lgCaption" className={styles.ListCard_latestDate}>
						{dictionary.getValue('Job.Deadline', null, 'ANSØGNINGSFRIST')}: {formatDate(job?.latestDate, 'dd.mm.yyyy')}
					</Paragraph>
				</>
			)}

			{event && (
				<div className={styles.ListCard_content}>
					<div>
						<Heading headingLevel="h3" style="xs" className={styles.ListCard_heading}>
							{event?.title}
						</Heading>
					</div>
					<div>
						<div className={styles.ListCard_listInfo}>
							<Paragraph style="smCaption" className={styles.ListCard_label}>
								{dictionary.getValue('Event.Date', null, 'DATO')}
							</Paragraph>
							<Paragraph style="md">{event?.dateFormatted}</Paragraph>
						</div>
						<div className={styles.ListCard_listInfo}>
							<Paragraph style="smCaption" className={styles.ListCard_label}>
								{dictionary.getValue('Event.Time', null, 'TID')}
							</Paragraph>
							<Paragraph style="md">{event?.startTime} - {event?.endTime}</Paragraph>
						</div>
						<div className={styles.ListCard_listInfo}>
							<Paragraph style="smCaption" className={styles.ListCard_label}>
								{dictionary.getValue('Event.Location', null, 'STED')}
							</Paragraph>
							<Paragraph style="md"  className={styles.ListCard_field}>{event?.location}</Paragraph>
						</div>
						<div className={classNames(styles.ListCard_listInfo, styles.ListCard_listInfo___lastChild)}>
							<Paragraph style="lgCaption" className={styles.ListCard_label}>
								{dictionary.getValue('Event.Price', null, 'PRIS')}
							</Paragraph>
							<Paragraph style="md">{event?.price}</Paragraph>
						</div>
						{event.url && (
							<LinkButton ariaLabel={event?.title + " " + dictionary.getValue('Event.Readmore', null, 'Se mere')} url={event?.url} style="primary">			
								{dictionary.getValue('Event.Readmore', null, 'Se mere')}
							</LinkButton>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
