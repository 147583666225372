import classNames from 'classnames';
import styles from './AgendaCard.module.scss';
import { Heading, Paragraph, Icon, Link } from 'ui/components';

export interface AgendaCardProps {
	className?: string;
	link?: {
		url: string;
		target?: string;
		name?: string;	
	};
	text?: string;
	date?: string;
	heading?:string;
	agendaType?:string;
}
export const AgendaCard: React.FC<AgendaCardProps> = ({ link, className, heading, text, date }) => {
	return (
		<div className={classNames(styles.AgendaCard, className)}>
			<div className={styles.AgendaCard_body}>
				<div className={styles.AgendaCard_head}>
				{date && (
					<div className={classNames(styles.AgendaCard_dateCircle__inner,className)}>
					<Icon className={classNames(styles.AgendaCard_dateCircle,className)} size='xl' style='circle'>
					<Paragraph style='smCaption'>{date}</Paragraph>
				</Icon>
				</div>
				)}
				<div className={classNames(styles.AgendaCard_heading__innner,className)}>
					<Heading headingLevel="h6" style="2xs" className={styles.AgendaCard_heading}>
						{heading}
					</Heading>
					</div>
					</div>
				{text && (
					<Paragraph style="xl" className={styles.AgendaCard_text}>
						{text}
					</Paragraph>
				)}
				{link && (
					<Link ariaLabel={link.name} className={styles.AgendaCard_link} hasArrow={false} url={link?.url} target={link?.target} title={link?.name}>
						{link.name}
					</Link>
				)}
			</div>
		</div>
	);
};
