import classNames from 'classnames';
import styles from './HeroCard.module.scss';
import { Heading, Paragraph, Picture, PictureProps, Container, VideoConsent, Link } from 'ui/components';
import { useEffect, useState } from 'react';

export interface HeroCardProps {
	className?: string;
	image?: PictureProps;
	video?: string;
	heading?: string;
	manchet?: string;
	isFirstLevel?: boolean;
	anchorLinkText?: string;
	showAnchorLink?: boolean;
	removeAlt?: boolean;
	fullWidthImage: boolean;
}

export const HeroCard: React.FC<HeroCardProps> = ({
	className,
	heading,
	image,
	video,
	manchet,
	isFirstLevel,
	showAnchorLink,
	anchorLinkText,
	removeAlt,
	fullWidthImage
}) => {
	const [hasNoImage, setHasNoImage] = useState<boolean>(false);
	const [hasVideo, setHasVideo] = useState<boolean>(false);

	useEffect(() => {
		if (!image && !video){
			setHasNoImage(true);
		} else if (!image && video){
			setHasVideo(true);
			setHasNoImage(false);
		} else{
			setHasNoImage(false);
			setHasVideo(false);
		}
	}, [image, video]);
	
	return (
		<div className={classNames(styles.HeroCard, 
			{ [styles.HeroCard___hasNoImage]: hasNoImage },
			{ [styles.HeroCard___fullWidthImage]: fullWidthImage }, 
			className)}>
			{image && !video && (
				<div className={styles.HeroCard_imageContainer} style={{ backgroundColor: `var(--theme-30)` }}>
					<Picture {...image} removeAlt={removeAlt} />
					<div className={classNames(styles.HeroCard_overlay)} />
				</div>
			)}

			{video && !image && (
				<div className={styles.HeroCard_videoContainer}>
					<VideoConsent
						style={{ backgroundColor: `var(--theme-30)` }} 
						video={video} />
					<div className={classNames(styles.HeroCard_overlay)} />
				</div>
			)}

			{/* IF BOTH IMAGE AND VIDEO EXISTS, SHOW ONLY IMAGE */}
			{image && video && (
				<div className={styles.HeroCard_imageContainer} style={{ backgroundColor: `var(--theme-30)` }}>
					<Picture {...image} />
					<div className={classNames(styles.HeroCard_overlay)} />
				</div>
			)}

			<div
				style={{ backgroundColor: `var(--theme-30)` }}
				className={classNames(
					styles.HeroCard_textboxContainer,
					{ [styles.HeroCard_textboxContainer___hasNoImage]: hasNoImage && !isFirstLevel },
					{ [styles.HeroCard_textboxContainer___hasVideo]: hasVideo },

				)}
			>
				{(heading || manchet) && (
					<Container width={hasNoImage ? 'Standard' : 'Full width'} className={classNames(styles.HeroCard_textboxContainer_inner)}>
						<div className={classNames(styles.HeroCard_textbox, { [styles.HeroCard_textbox___hasNoImage]: hasNoImage })}>
							{heading && (
								<Heading headingLevel="h1" style="xl" className={styles.HeroCard_heading}>
									{heading}
								</Heading>
							)}
							{manchet && (
								<Paragraph style="manchet" className={classNames(styles.HeroCard_manchet)}>
									<span dangerouslySetInnerHTML={{ __html: manchet.replace(/(?:\r\n|\r|\n)/g, '<br>')  }}></span>
								</Paragraph>
							)}
							{showAnchorLink && (
								<Link ariaLabel={anchorLinkText} url="#contactBox" hasArrow={true} className={styles.HeroCard_contactAnchorLink}>
									{anchorLinkText}
								</Link>
							)}
						</div>
					</Container>
				)}
			</div>
		</div>
	);


};
