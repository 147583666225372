import classNames from 'classnames';
import styles from './FooterNavigation.module.scss';
import { Container, Grid, Link } from 'ui/components';
import { GridCell } from 'ui/components/4-habitats/Grid';
import { ReactComponent as Arrow } from '../../../../icons/arrow-right.svg';

export interface FooterNavigationProps {
	className?: string;
	footerNavigation?: any;
}

export const FooterNavigation: React.FC<FooterNavigationProps> = ({ className, footerNavigation }) => {
	return (
		<nav className={classNames(styles.FooterNavigation, className)}>
			<Container width="Standard">
				<Grid wrap={true} container={true}>
					{footerNavigation.map((navigationItem: Content.Link, index) => (
						<GridCell key={index} mobileWidth="100" desktopWidth="33" className={styles.Footer_navContainer}>
							<Link ariaLabel={navigationItem.name} url={navigationItem.url} className={styles.FooterNavigation_headerLink} title={navigationItem.title}>
								{navigationItem.name}
							</Link>
							{navigationItem.descendants && (
								<ul role='list' className={styles.FooterNavigation_list}>
									{navigationItem.descendants.slice(0, 3).map((navigationItem: Content.Link, index) => (
										<li role='listitem' key={index} className={styles.FooterNavigation_levelTwoLinksItem}>
											<Link
												ariaLabel={navigationItem.name}
												url={navigationItem.url}
												className={styles.FooterNavigation_levelTwoLink}
												title={navigationItem.title}
											>
												{navigationItem.name}
												<Arrow aria-hidden="true" className={styles.FooterNavigation_levelTwoLinkIcon} />
											</Link>
										</li>
									))}
								</ul>
							)}
						</GridCell>
					))}
				</Grid>
			</Container>
		</nav>
	);
};
