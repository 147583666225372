import { Button } from 'ui/components';

export interface ScreenReaderProps {
	buttonText: string;
	text: string;
}

export const ScreenReader: React.FC<ScreenReaderProps> = ({ buttonText, text }) => {
	let msg = new SpeechSynthesisUtterance();
	msg.text = `${text}`;

	let voices = speechSynthesis.getVoices();
	msg.voice = voices[5];

	const readUp = () => {
		window.speechSynthesis.speak(msg);
	};

	return (
		<div>
			<Button style="primary" onClick={() => readUp()}>
				{buttonText}
			</Button>
			<p>{text}</p>
		</div>
	);
};
