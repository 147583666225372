import classNames from 'classnames';
import { Heading, Container, LinkButton } from 'ui/components';
import styles from './UrgentContact.module.scss';
import { UrgentContactItem, UrgentContactItemProps } from './UrgentContactItem';

export interface UrgentContactProps {
	className?: string;
	heading?: string;
	headingColor?: string;
	link?: Content.Link;
	urgentContactItems?: UrgentContactItemProps[];
	backgroundColor?: string;
}

export const UrgentContact: React.FC<UrgentContactProps> = ({
	className,
	heading,
	headingColor,
	link,
	urgentContactItems,
	backgroundColor,
}) => {
	return (
		<div
			className={classNames(styles.UrgentContact, className)}
			style={{ backgroundColor: backgroundColor ? backgroundColor : `var(--theme-10)` }}
		>
			<Container className={styles.UrgentContact_container} width="Standard">
				{(heading || link) && (
					<div className={styles.UrgentContact_header}>
						{heading && (
							<div className={styles.UrgentContact_headingContainer}>
								<Heading className={styles.UrgentContact_heading} headingLevel="h2" style="lg" color={headingColor && headingColor}>
									{heading}
								</Heading>
							</div>
						)}

						{link && (
							<div className={classNames(styles.UrgentContact_buttonContainer)}>
								<LinkButton ariaLabel={link.name} url={link.url} style="secondary" className={classNames(styles.UrgentContact_button, styles.UrgentContact_button___desktop)}>
									{link.name}
								</LinkButton>
							</div>
						)}
					</div>
				)}

				{urgentContactItems && (
					<ul role='list' className={styles.UrgentContact_content}>
						{urgentContactItems.map((item, index) => (
							<UrgentContactItem key={index} {...item} />
						))}
					</ul>
				)}

				{link && (
					<div className={classNames(styles.UrgentContact_buttonContainer)}>
						<LinkButton ariaLabel={link.name} url={link.url} style="secondary" className={classNames(styles.UrgentContact_button, styles.UrgentContact_button___mobile)}>			
							{link.name}
						</LinkButton>
					</div>
				)}
			</Container>
		</div>
	);
};
