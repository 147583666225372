import classNames from 'classnames';
import { Heading, Container, RichText, Link } from 'ui/components';
import styles from './Linkbox.module.scss';
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg';

export interface LinkboxProps {
	className?: string;
	heading?: string;
	teaser?: string;
	linkboxItems?: { text: string; link: Content.Link }[];
	newWindow?: string;
	backgroundColor?: string;
}

export const Linkbox: React.FC<LinkboxProps> = ({
	className,
	heading,
	teaser,
	linkboxItems,
	backgroundColor = '#FFFFFF',
	newWindow,
}) => {
	return (
		<Container width="Standard" className={styles.Linkbox_container}>
			<div
				className={classNames(styles.Linkbox, className)}
				style={{ backgroundColor: backgroundColor ? backgroundColor : `var(--theme-20)` }}
			>
				<Heading headingLevel="h2" style="lg" className={classNames(styles.Linkbox_heading)}>
					{heading}
				</Heading>
				{teaser && <RichText className={styles.Linkbox_teaser} content={teaser} />}
				{linkboxItems && (
					<ul role='list' className={classNames(styles.Linkbox_linksContainer)}>
						{linkboxItems.map((linkboxItem, index) => (
							<li role='listitem' key={index} className={classNames(styles.Linkbox_links)}>
								<Link
									ariaLabel={linkboxItem?.text}
									url={linkboxItem.link?.url}
									className={classNames(styles.Linkbox_link)}
									target={linkboxItem.link?.target || newWindow}
								>
									{linkboxItem?.text}
									<div className={styles.Linkbox_iconWrapper}>
										<ArrowRight className={styles.Linkbox_icon} />
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}
			</div>
		</Container>
	);
};
