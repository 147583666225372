import classNames from 'classnames';
import { RichText, Paragraph } from 'ui/components';
import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import styles from './AccordionItem.module.scss';
import { generateId } from 'helpers/id';
import { ReactComponent as ChevronUp } from 'ui/icons/icon-chevron-down-big.svg';

export interface AccordionItemProps {
	openByDefault?: boolean;
	title: string;
	anchor?: string;
	htmlContent: string;
}

// TODO: Mangler håndtering af allowMultiple: true/false
export const AccordionItem: React.FC<AccordionItemProps> = ({ title, htmlContent, anchor, openByDefault = false }) => {
	const id = generateId();
	const [open, isOpen] = useState(openByDefault);
	const toggle = () => isOpen(!open);

	const router = useRouter();
	const accordionItemRef = useRef<HTMLButtonElement>(null);

	const onHashHasChanged = () => {
		accordionItemRef.current.id === window.location.hash.replace("#","") ? isOpen(true) : isOpen(false) ;
	};

	useEffect(() => {
		onHashHasChanged();
		window.addEventListener('hashchange', onHashHasChanged);

		return () => {
			window.removeEventListener('hashchange', onHashHasChanged);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ router.asPath ]);

	return (
		<div className={styles.AccordionItem}>
			<button onClick={toggle} ref={accordionItemRef} id={anchor ? anchor : `anchor-${id}`} className={styles.AccordionItem_triggerContainer}>
				<Paragraph style="lg" className={styles.AccordionItem_title}>
					{title}
				</Paragraph>
				<div className={styles.AccordionItem_iconContainer} >
					<div>
						<ChevronUp className={classNames(styles.AccordionItem_icon, { [styles.AccordionItem_icon___isOpen]: open })} />
					</div>
				</div>
			</button>
			<div id={`panel-${id}`} role="region" aria-labelledby={`trigger-${id}`} className={styles.AccordionItem_panel} hidden={!open}>
				<RichText content={htmlContent} className={styles.AccordionItem_content} />
			</div>
		</div>
	);
};
