import classNames from 'classnames';
import { Heading, Container, Link } from 'ui/components';
import styles from './LinkboxAutomatic.module.scss';
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg';

export interface LinkboxAutomaticProps {
	className?: string;
	heading?: string;
	linkboxItems?: { text: string; link: Content.Link }[];
	backgroundColor?: string;
}

export const LinkboxAutomatic: React.FC<LinkboxAutomaticProps> = ({ className, heading, linkboxItems, backgroundColor = '#FFFFFF' }) => {
	return (
		<Container width="Standard" className={styles.LinkboxAutomatic_container}>
			<div
				className={classNames(styles.LinkboxAutomatic, className)}
				style={{ backgroundColor: backgroundColor ? backgroundColor : `var(--theme-20)` }}
			>
				<Heading headingLevel="h2" style="lg" className={classNames(styles.LinkboxAutomatic_heading)}>
					{heading}
				</Heading>

				{linkboxItems.length !== 0 && (
					<ul role='list' className={classNames(styles.LinkboxAutomatic_linksContainer)}>
						{linkboxItems.map((linkboxItem, index) => (
							<li role='listitem' key={index} className={classNames(styles.LinkboxAutomatic_links)}>
								<Link ariaLabel={linkboxItem?.text} url={linkboxItem.link?.url} className={classNames(styles.LinkboxAutomatic_link)} target={linkboxItem.link?.target}>
									{linkboxItem?.text}
									<div className={styles.LinkboxAutomatic_iconWrapper}>
										<ArrowRight className={styles.LinkboxAutomatic_icon} />
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}
			</div>
		</Container>
	);
};
