import { Heading, RichText } from 'ui/components';

export interface SliderItemProps {
	heading: string;
	text: string;
}

export const SliderItem: React.FC<SliderItemProps> = ({ heading, text }) => (
	<div>
		<Heading headingLevel="h3">{heading}</Heading>
		<RichText content={text} />
	</div>
);
