import classNames from 'classnames';
import styles from './Navigation.module.scss';
import { Heading, Link } from 'ui/components';

export interface NavigationProps {
	className?: string;
	style?: 'primary';
	heading?: string;
	navigationItems?: Navigation.NavigationItem[];
	isFrontPage?: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ className, style, heading, navigationItems, isFrontPage }) => {

	return (
	<nav className={classNames(styles.Navigation, styles[`Navigation___${style}`], className)}>
		{heading && (
			<Heading headingLevel="h6" className={styles.Navigation_heading} style="sm">
				{heading}
			</Heading>
		)}
		<ul role='list' className={styles.Navigation_list}>
			{navigationItems &&
				navigationItems.map((navigationItem: Navigation.NavigationItem, index) => (
					<li role='listitem' key={index} className={classNames(styles.Navigation_item)}>
						<Link 
							ariaLabel={navigationItem.title || navigationItem.name}
							className={classNames(styles.Navigation_link, {
								[styles.isActive]: navigationItem.active === true,
								[styles.isCurrent]: navigationItem.current === true,
								[styles.isFrontPage]: isFrontPage,
							})}
							url={navigationItem.url}
						>
							{navigationItem.title || navigationItem.name}
						</Link>
					</li>
				))}
		</ul>
	</nav>
)};
