import classNames from 'classnames';
import styles from './FormfieldCheckboxSelect.module.scss';
import { ErrorMessage, Label, Paragraph } from 'ui/components';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ChevronUp } from 'ui/icons/icon-chevron-down-big.svg';
import { array } from 'prop-types';

export type Option = {
	value: string;
	text: string;
	disabled?: boolean;
	hidden?: boolean;
	selected?: boolean;
};

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
};

export interface FormfieldCheckboxSelectProps {
	className?: string;
	id?: string;
	name?: string;
	label?: string;
	options?: Option[];
	register?: any;
	errorMessage?: string;
	state?: State;
	placeholder?: string;
	selectedOptionValues?: string[];
	onKeyPress?: (event: React.KeyboardEvent, optionName) => void;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, optionName) => void;
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, name: string, id: string) => {
	if (state?.['hasError']) return name + id;
};

export const FormfieldCheckboxSelect: React.FC<FormfieldCheckboxSelectProps> = ({
	className,
	register,
	errorMessage,
	state,
	id,
	label,
	options,
	name,
	placeholder,
	onChange,
	onKeyPress,
}) => {
	const [activeFilters, setActiveFilters] = useState([]);
	const { required, disabled, hasError } = state ?? {};
	const [isActive, setIsActive] = useState<boolean>(false);
	const onClickDisplayActiveFilters = (e,item) => {
		const arr = [...activeFilters];
		const target = e.target;
		if (!target.selected && !activeFilters.includes(item.value)) {
			arr.push(item.value);
			setActiveFilters(arr);
		} else {
			const _arr = arr.filter((name) => {
				return name !== item.value;
			});
			setActiveFilters(_arr);
		}
	};
	useEffect(() => {}, [activeFilters]);

	return (
		<div className={classNames(styles.FormfieldCheckboxSelect, className, { [styles.hasError]: hasError }, { [styles.isDisabled]: disabled })}>
			<button
				className={classNames(styles.FormfieldCheckboxSelect_input, { [styles.FormfieldCheckboxSelect_input___isActive]: isActive })}
				onClick={() => setIsActive(!isActive)}
			>
				{placeholder}
				<div className={styles.FormfieldCheckboxSelect_iconContainer}>
					<ChevronUp
						className={classNames(styles.FormfieldCheckboxSelect_icon, {
							[styles.FormfieldCheckboxSelect_icon___isActive]: isActive,
						})}
					/>
					{activeFilters.map((filt, index) => (
						<span key={index} className={classNames(styles.FormfieldCheckboxSelect__used__filters)}>
							{(activeFilters.indexOf(filt) === 0 && activeFilters.indexOf(filt) === activeFilters.length - 1) ||
							activeFilters.indexOf(filt) === activeFilters.length - 1
								? filt
								: filt + ', '}
						</span>
					))}
				</div>
			</button>

			{isActive && (
				<ul role="list" className={styles.FormfieldCheckboxSelect_resultsContainer}>
					{options &&
						options.map((item, index) => (
							<li role="listitem" key={index} className={styles.FormfieldCheckboxSelect_results}>
								<input
									id={item.value}
									name={item.value}
									type="checkbox"
									value={item.value}
									checked={item.selected}
									onChange={(e) => {
										onChange(e, item.value);
										onClickDisplayActiveFilters(e,item);
									}}
									onKeyPress={(e) => onKeyPress(e, item.value)}
									aria-label={ariaLabel(state, label)}
									aria-describedby={ariaError(state, name, id)}
									className={styles.FormfieldCheckboxSelect_checkboxInput}
								/>
								<label htmlFor={item.value}>{item.text}</label>
							</li>
						))}
				</ul>
			)}

			{hasError && <ErrorMessage id={name + id}>{errorMessage}</ErrorMessage>}
		</div>
	);
};
