import { Heading, Paragraph, Picture, PictureProps } from 'ui/components';
import { LinkButtonFeature } from 'features/Modules/Linkbutton';
import styles from './PictureText.module.scss';
import classNames from 'classnames';

export interface PictureTextProps {
    picture: PictureProps;
    title: string;
    paragraph: string;
    cTALink: {
		url: string;
		name?: string;
		target?: string;
	};
    pictureLast: boolean;
}

export const PictureText: React.FC<PictureTextProps> = ({ picture, title, paragraph, cTALink, pictureLast }) => {
	return (
		<div className={classNames(styles.gridish, pictureLast ? styles.pictureLast : styles.pictureFirst)}>
            {picture && (
                <div>
                    <Picture {...picture} />
                </div>
            )}
            <div className={styles.textbox}>
                <div className={styles.padding}>
                    <Heading
                        headingLevel='h2'
                        style='xlMaxWidth'
                    >
                        {title}
                    </Heading>
                    <Paragraph className={styles.paragraph}>
                        {paragraph}
                    </Paragraph>
                    {cTALink && (
                        <LinkButtonFeature className={styles.button} {...cTALink} style="secondary" >
                            {cTALink.name}
                        </LinkButtonFeature>
                    )}
                </div>
            </div>
        </div>
	)
};
