import React from 'react';
import classNames from 'classnames';
import styles from './Paragraph.module.scss';

export interface ParagraphProps {
	children?: React.ReactNode;
	className?: string;
	style?: 'manchet' | 'sm' | 'md' | 'mdSemibold' | 'lg' | 'lgBold' | 'xl' | 'lgCaption' | 'smCaption';
}

export const Paragraph: React.FC<ParagraphProps> = ({ children, className, style = 'md' }) => (
	<p className={classNames(styles.Paragraph, styles[`Paragraph___${style}`], className)}>{children}</p>
);
