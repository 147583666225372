import classNames from 'classnames';
import { Fragment } from 'react';
import styles from './LinkButton.module.scss';

export interface LinkButtonProps {
	children: React.ReactNode;
	state?: 'inactive';
	style?: 'primary' | 'secondary' | 'inactive' | 'sm';
	title?: string;
	ariaLabel?: string;
	className?: string;
	url?: string;
	target?: string;
	LinkWrapper?<T extends unknown>(arg: T): JSX.Element;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ children, style, title, ariaLabel, target, className, state, url, LinkWrapper }) => {
	function LinkWrapperElement({ wrappedUrl, children }): JSX.Element {
		return LinkWrapper ? <LinkWrapper href={wrappedUrl}>{children}</LinkWrapper> : <>{children}</>;
	}

	return (
		<Fragment>
			<LinkWrapperElement wrappedUrl={url}>
				<a
					href={url}
					title={title}
					target={target}
					aria-label={ariaLabel}
					className={classNames(styles.LinkButton, styles[`LinkButton___${style}`], styles[`LinkButton___${state}`], className)}
				>
					{children}
				</a>
			</LinkWrapperElement>
		</Fragment>
	);
};
