import classNames from 'classnames';
import styles from './Spinner.module.scss';
import { ReactComponent as RSJSpinner } from 'ui/icons/rsj-loader.svg';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export const Spinner: React.FC = () => (
	<>
		<div className={classNames(styles.Spinner_screenReader, styles.Spinner_loadingMsg)} role="alert">
			loading...
		</div>
		<div className={classNames(styles.Spinner)} aria-hidden="true">
			<RSJSpinner />
		</div>
	</>
);
