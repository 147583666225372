import classNames from 'classnames';
import styles from './ArticleMedia.module.scss';
import { Picture } from 'ui/components';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface ArticleMediaProps {
	className?: string;
	image: {
		url: string;
		altText: string;
		focalPoint?: { left?: number; top?: number };
	};
	caption?: string;
	removeAlt?: boolean;
	imageLink?: boolean;
}

export const ArticleMedia: React.FC<ArticleMediaProps> = ({ className, image, caption, removeAlt, imageLink }) => {
	const dictionary = useDictionaryContext();
	
	return(
		<div className='u-grid__cell u-grid__cell--width-100 u-bottom-padding--md'>
			<figure className={classNames(styles.ArticleMedia, className)}>
				{image && (
					<>
						<div className={styles.ArticleMedia_mediabox}>
							<Picture
								aspectRatio={0.5625}
								focalPoint={image.focalPoint}
								url={image.url}
								altText={image.altText}
								removeAlt={removeAlt}
								sizes="100vw"
								className={styles.ArticleMedia_mediabox}
							/>
						</div>
						{imageLink && (
							<a className={styles.Link} href={image.url}>{dictionary.getValue('Aticle.BigPicture', null, 'Hent billede i stort format')}</a>
						)}
					</>
				)}
				<div className="">
					<figcaption className={styles.ArticleMedia_caption}>{caption}</figcaption>
				</div>
			</figure>
		</div>
	);
};
