import classNames from 'classnames';
import { Heading, Paragraph } from 'ui/components';
import styles from './UrgentContactItem.module.scss';
import { ReactComponent as IconPhone } from 'ui/icons/icon_phone.svg';

export interface UrgentContactItemProps {
	number?: string;
	text?: string;
}

export const UrgentContactItem: React.FC<UrgentContactItemProps> = ({ number, text }) => {
	return (
		<li role='listitem' className={classNames(styles.UrgentContactItem)}>
			<Heading style="lg" headingLevel="h3" hasHTML={true}>
				<a className={styles.UrgentContactItem_heading} href={`tel:${number}`}>{number}</a>
			</Heading>
			<div className={styles.UrgentContactItem_text}>
				<div>
					<IconPhone />
				</div>
				<Paragraph style="manchet">{text}</Paragraph>
			</div>
		</li>
	);
};
