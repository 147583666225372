import styles from './ArticleDate.module.scss';
import { formatDate } from 'helpers/date';
import { Fragment } from 'react';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface ArticleDateProps {
	date?: string;
	dateAndText?: string;
	old?: boolean;
}

export const ArticleDate: React.FC<ArticleDateProps> = ({ date, dateAndText, old }) => {
	const dictionary = useDictionaryContext();
	return (
		<Fragment>
			{date &&
				<p className={styles.ArticleDate}>{formatDate(new Date(date), 'dd.mm.yyyy')}</p>
			}
			<div className={styles.Container}>
				{old &&
					<p className={styles.ArticleOld}>{dictionary.getValue('Article.Old', null, 'MERE END 90 DAGE GAMMEL')}</p>
				}
				{dateAndText &&
					<p className={styles.ArticleDate__Text}>{dateAndText}</p>
				}
			</div>
		</Fragment>
	)
};
