// import { Item } from '@storybook/api/dist/ts3.9/lib/stories';
// import { ignoreSsrWarning } from '@storybook/theming';
// import { getCmsNavigation } from 'application/adapters/cms/navigationAdapter';
 import { usePageContext } from 'application/contexts/PageContext';
// import { Url } from 'ui/components/2-molecules/Form/FormfieldString/FormfieldString.stories';

export interface GetNavigationOptions {
	byName?: string;
	byUrl?: string;
	currentUrl?: string;
	includeHidden?: boolean;
	includeRootItem?: boolean;
	byId?: number;
}
export interface NavigationRepositoryInterface {
	getNavigation: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
	getBreadcrumb: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
}

export const NavigationRepositoryFactory = (data: Navigation.NavigationItem): NavigationRepositoryInterface => {
	const pageContext = usePageContext();
	if (!data) return;

	const createNavigationItem = (navigation: Navigation.NavigationItem, options?: GetNavigationOptions): Navigation.NavigationItem => {
		if (navigation.hidden && !options?.includeHidden) {
			return null;
		}
		
		const descendants = navigation.descendants
			?.map((item) => {
				return createNavigationItem(item, options);
			})
			?.filter((item) => item);
		const isCurrent = options?.currentUrl ? options?.currentUrl === navigation.url : false;
		const isActive = isCurrent || descendants?.find((item) => item.current || item.active) ? true : false;
		//pageArea = pageContext.getSettings('siteTheme').theme?.properties?.pageArea || 'TEST'
		return {
			id: navigation.id,
			name: navigation.name,
			url: navigation.url,
			//pageArea: navigation.pageArea,
			descendants: descendants?.length == 0 ? [] : descendants,
			title: navigation.title ?? "",
			hidden: navigation.hidden ?? false,
			current: isCurrent,
			active: isActive,
		};
	};

	const getNavigationItems = (options?: GetNavigationOptions): Navigation.NavigationItem[] => {
		let root = data;
		if (options.byName) {
			root = findByName(options?.byName, data);
		}

		if (options.byId) {
			root = findById(options?.byId, data);
		}

		if (options.byUrl) {
			root = findByUrl(options?.byUrl, data);
			
		}

		if (!root) return null;
		const itemsToReturn = options.includeRootItem ? [root] : root.descendants;
		return itemsToReturn?.map((item) => createNavigationItem(item, options))?.filter((item) => item);
	};

	return {
		// Return visible navigation items.
		// This is only done for the first level for now
		// and could be refined if the need arises.
		getNavigation(options): Navigation.NavigationItem[] {
			return getNavigationItems(options);
		},

		// Returns the bread crumb
		// For now just the root and the current item (if any)
		getBreadcrumb(options): Navigation.NavigationItem[] {
			const flattenItems = (items: Navigation.NavigationItem[]) => {
				const flattened = [];
				items.forEach((element) => {
					flattened.push(element);
					if (element.descendants) {
						const flattenedDesc = flattenItems(element.descendants);
						flattenedDesc.forEach((f) => flattened.push(f));
					}
				});
				return flattened;
			};
			const items = getNavigationItems(options);
			return flattenItems(items).filter((item) => item.active);
		},
	};
};

export interface GetNavigationInterface {
	url: string;
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
	locale?:string;
}
// Return full set of nested navigation items.
// This is pure data for static props and must be serializable
// Preferably it should have been part of the repository factory function
// but objects cannot be passed as static props
// export const getNavigationData = async ({
// 	url,
// 	host,
// 	preview,
// 	previewData,
// 	accessToken,
// 	locale,
// }: GetNavigationInterface): Promise<Navigation.NavigationItem> => {
// 	const navigationResponse = await getCmsNavigation({
// 		url,
// 		host,
// 		preview,
// 		previewData,
// 		accessToken,
// 		locale,
// 	});
// 	return navigationResponse;
// };

// Return navigation items by its name.
// This is only done for the first level of descendants for now
// and could be refined if the need arises.
const findByName = (name: string, item: Navigation.NavigationItem): Navigation.NavigationItem => {

	const normalizedName = name.toLowerCase();

	if (item.name === normalizedName) {
		return item;
	} else {
		const foundItem = item.descendants.filter((item) => {
		
			return item.name.toLowerCase() === normalizedName;
		});
	
		return foundItem.length
			? foundItem[0]
			: {
					id: 0,
					name: '',
					url: '',
			  };
	}
};

const findByUrl = (target: string, item: Navigation.NavigationItem): Navigation.NavigationItem =>{
	
	try {

		if(target === "#"){
			return null;
		}

		let targetUrl = new URL(target);
		let currentUrl = new URL(item.url);
		
		if(targetUrl.pathname === currentUrl.pathname){
			return item;
		}
		
		for(let i = 0; i < item.descendants.length; i++){
			let child = item.descendants[i];
			let match = findByUrl(target, child);
			if(match)	
				return match;
		}
		return null;
	} catch (e) {
		return null;
	}

	
}

// Return navigation items by its id.
// This is only done for the first level of descendants for now
// and could be refined if the need arises.
export const findById = (id: number, item: Navigation.NavigationItem): Navigation.NavigationItem => {
	if (item.id === id) {
		return item;
	} else {
		const foundItem = item.descendants.filter((item) => {
			if (item.id === id) {
				return item.id;
			} else {
				
				return findById(id, item);
			}
		});

		return foundItem.length >= 1
			? foundItem[0]
			: {
					id: 0,
					name: '',
					url: '',
			  };
	}
};
