import { useNavigationContext } from 'application/contexts/NavigationContext';
import { PageContext } from 'application/contexts/PageContext';
import { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from 'ui/components';

export const BreadcrumbFeature: React.FC = () => {
	const navContext = useNavigationContext();
	const pageContext = useContext(PageContext);
	const navigationItems = navContext?.getNavigation({ includeHidden: true, includeRootItem: true });
	const [isPrintButtonActive, setIsPrintButtonActive] = useState<boolean>(false);
	const [useTheme, setUseTheme] = useState<boolean>(false);
	const breadcrumbData: Navigation.NavigationItem[] = new Array<Navigation.NavigationItem>();
	const visitNavItem = (navItem: Navigation.NavigationItem, breabcrumbList: Navigation.NavigationItem[]): boolean => {
		if (navItem.id == pageContext.id) {
			if (navItem.hidden != true) breadcrumbData.push(navItem);
			return true;
		}

		return navItem.descendants.some((child) => {
			var found = visitNavItem(child, breadcrumbData);
			if (found == true) {
				if (navItem.hidden != true) breadcrumbData.push(navItem);
				return true;
			}
		});
	}
	
	if (navigationItems?.length > 0) {
		visitNavItem(navigationItems[0], breadcrumbData);
	}

	useEffect(() => {
		let docType = pageContext._pageContent?.page.documentType;
		setUseTheme(docType === 'contentPage' || docType === 'eventsPage' ? true: false);
		pageContext?.content?.pageSettings.map((item) => {
			if (item.documentType === 'isPrintButtonActive') {
				setIsPrintButtonActive(true);
			}
		});
	}, [pageContext.url]);


	return pageContext._pageContent?.page.documentType === 'homePage' ?	null:
	<Breadcrumb breadcrumbItems={breadcrumbData.reverse()} printButtonActive={isPrintButtonActive} useTheme={useTheme}  />;
};
