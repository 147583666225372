import classNames from 'classnames';
import styles from './CenteredText.module.scss';
import { RichText, Container } from 'ui/components';

export interface CenteredTextProps {
	content: string;
	className?: string;
}

export const CenteredText: React.FC<CenteredTextProps> = ({ className, content }) => {
	return (
		<Container width="Standard" className={styles.CenteredText_container}>
			<div className={classNames(styles.CenteredText, className)}>	
				<RichText className={styles.CenteredText} content={content} />
			</div>
		</Container>
	);
};

