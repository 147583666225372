/* eslint-disable  */
// TODO: Fix types
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { SubNavigationItem } from './SubNavigationItem';
import AnimateHeight from 'react-animate-height';
import styles from './SubNavigation.module.scss';

export interface SubNavigationProps {
	items: Navigation.NavigationItem[];
	className?: string;
	navId: number;
	currentPageId: number;
	isMobile?: boolean;
	closeMenu: () => void;
}

export const SubNavigation: React.FC<SubNavigationProps> = ({
	navId,
	currentPageId,
	items,
	className,
	isMobile,
	closeMenu,
}) => {
	const [currentSubNav, setCurrentSubNav] = useState({ id: navId, navLevel: 0 });
	const [duration, setDuration] = useState(0);

	let timeout = null;

	useEffect(() => {
		timeout = setTimeout(() => {
			setDuration(300);
		}, 300);

		return () => clearTimeout(timeout);
	}, []);

	return (
		<AnimateHeight className={styles.SubNavigation} height={"auto"} duration={duration}>
			<div
				className={cx(styles.SubNavigation_wrapper, className)}
				style={{
					transform: `translateX(${currentSubNav.navLevel * -100}%)`,
					transitionDuration: `${duration}ms`,
				}}
			>
				<SubNavigationItem
					navLevel={0}
					className={styles.Navigation_subNav}
					currentPageId={currentPageId}
					navId={navId}
					parentId={navId}
					currentSubNav={currentSubNav}
					setCurrentSubNav={setCurrentSubNav}
					items={items}
					isMobile={isMobile}
					closeMenu={closeMenu}
				/>
				{/* <button onClick={closeMenu} cssStyle="position:absolute; z-index: 900;">Toggle</button> */}
			</div>
		</AnimateHeight>
	);
};
