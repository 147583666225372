import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './ImageSlider.module.scss';
import { Picture, Container, ArticleMedia } from 'ui/components';
import { ReactComponent as ButtonNext } from 'ui/icons/icon_circle_arrow_button_right.svg';
import { ReactComponent as ButtonPrevious } from 'ui/icons/icon_circle_arrow_button_left.svg';

interface ImagePicker {
  image?: Image;
  imageDescription: string;
}

interface Image {
  url: string;
  altText: string; 
  focalPoint?: {left: number; top: number}
}

export interface ImageSliderProps {
  className?: string;
  images: ImagePicker[];
  removeAlt?: boolean;
  isAutoChangeEnabled: boolean;
}

export const ImageSlider: React.FC<ImageSliderProps> = ({ images, removeAlt, isAutoChangeEnabled }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const prevImg = () =>
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));

  const nextImg = () =>
    setCurrentImageIndex((prevIndex) =>
      prevIndex >= images.length - 1 ? 0 : prevIndex + 1
    );

  if (isAutoChangeEnabled) {
    const autoChangeRef = React.useRef(null);

    const resetAutoChange = () => {
      if (autoChangeRef.current) {
        clearTimeout(autoChangeRef.current);
      }
    }
    useEffect(() => {
      resetAutoChange();
      autoChangeRef.current = setTimeout(
        () =>
          setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          ),
        8000
      );

      return () => {
        resetAutoChange();
      };
    }, [currentImageIndex]);

  }

  return (
      <div className={styles.ImageSlider_container}>
        <div className={styles.ImageSlider_desktop}>
          <div className={styles.ImageSlider_prevButtonDesktop} onClick={prevImg}>
            <ButtonPrevious />
          </div>
          <div className={styles.ImageSlider_image}>
            <Picture 
              {...images[currentImageIndex].image} 
              aspectRatio={0.5625} 
              sizes="100vw" 
              removeAlt={removeAlt} 
              className={classNames(styles.image)}
            />
            <div className={styles.ImageSlider_imageDescription_desktop}>
              <caption className={styles.caption}>{images[currentImageIndex]?.imageDescription}</caption>
            </div>
          </div>
          <div className={styles.ImageSlider_nextButtonDesktop} onClick={nextImg}>
            <ButtonNext />
          </div>
        </div>
        <div className={styles.ImageSlider_mobile_background}>
        <div className={styles.ImageSlider_buttonsMobile}>
          <div className={styles.ImageSlider_prevButtonMobile} onClick={prevImg}>
            <ButtonPrevious />
          </div>
          <div className={styles.ImageSlider_spacer}/>
          <div className={styles.ImageSlider_nextButtonMobile} onClick={nextImg}>
            <ButtonNext />
          </div>
        </div>
          <div className={styles.ImageSlider_imageDescription_mobile}>
              <caption className={styles.caption}>{images[currentImageIndex]?.imageDescription}</caption>
          </div>
        </div>
      </div>
  );
};