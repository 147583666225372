import { postVideoListTagsData } from 'application/adapters/cms/videoListTagsAdapter';
export interface postVideoListTagsDataProps {
	tags?: string;
	pageNumber?: number;
	pageSize?: number;
	totalPages?:number
}
export const postVideoListTags = async (params :any):Promise<Models.VideoListTags> => {
	const {tags, itemOffset, itemsPerPage} = params;
	return await postVideoListTagsData(tags, itemOffset, itemsPerPage);
};
