import { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

export interface MapMarkerProps {
	title: string;
	text?: string;
	latitude: number;
	longitude: number;
}

export const MapMarker: React.FC<MapMarkerProps> = ({ latitude: lat, longitude: lng, title, children }) => {
	const [selected, setSelected] = useState(false);

	const position = {
		lat,
		lng,
	};

	return (
		<Marker position={position} onClick={() => setSelected(true)} title={title}>
			{children && selected && (
				<InfoWindow position={position} onCloseClick={() => setSelected(false)}>
					{children}
				</InfoWindow>
			)}
		</Marker>
	);
};
