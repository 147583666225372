import classNames from 'classnames';
import styles from './Header.module.scss';
import { useRef } from 'react';
import { useRouter } from 'next/router'
import { BreadcrumbFeature } from 'features/Modules/Breadcrumb';
import { Container, Grid, GridCell, Logo, Search } from 'ui/components';
import { ReactComponent as IconSearch } from 'ui/icons/icon_search.svg';
import { ReactComponent as IconClose } from 'ui/icons/icon_close_cross.svg';
import { Fragment, useState, useCallback, useEffect } from 'react';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';

export interface HeaderProps {
	className?: string;
	logo?: any;
	searchPage?: Content.Link;
	logoLink?: Content.Link;
	logoSize?: string;
	mobileNavigation?: React.ReactNode;
	serviceNavigation?: React.ReactNode;
	navigation?: React.ReactNode;
	languageSelector?: React.ReactNode;
	subsiteBar?: any;
	isFrontPage?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
	className,
	logo,
	logoLink,
	logoSize,
	searchPage,
	mobileNavigation,
	serviceNavigation,
	languageSelector,
	navigation,
	subsiteBar,
	isFrontPage = false,
}) => {
	const [toggleSearchField, setToggleSearchField] = useState<boolean>(false);
	const dictionary = useDictionaryContext();
	const [isMobile, setIsMobile] = useState(false);
	const router = useRouter();

	const pageContext = usePageContext();

	const detectMobileOnResize = useCallback(() => {
		setIsMobile(window.innerWidth <= 1215);
	}, [isMobile]);

	useEffect(() => {
		detectMobileOnResize();
		window.addEventListener('resize', detectMobileOnResize);
		return () => {
			window.removeEventListener('resize', detectMobileOnResize);
		};
	}, []);


	
	const handleSearchUp = useCallback(() => {
		searchField.current.classList.add(styles.Header_searchFieldContainer___isClosing);
		searchField.current.classList.remove(styles.Header_searchFieldContainer___isActive);
		setTimeout(() => {setToggleSearchField(toggleSearchField);}, 650)
	}, []);

	const searchField = useRef(null);

	const openSearchClick = () => {
		setToggleSearchField(!toggleSearchField)

		window.setTimeout(() => {
		if (document.getElementById('search')) {
				document.getElementById('search').focus();
			}
		}, 200)
	}

	const onEnterEvent = (event) => {
		if (event.keyCode === 13) {
			setToggleSearchField(toggleSearchField);
		}
	};

	const onEnterEventUp = (event) => {
		if (event.keyCode === 13) {
			handleSearchUp();
		}
	};

	useEffect(() => {
		setToggleSearchField(false);
	}, [pageContext.url, router.asPath]);

	return (
		<Fragment>
			<header
				className={classNames(
					styles.Header,
					className,
					{ [styles.Header___isFrontPage]: isFrontPage },
					{ [styles.Header___hasSubsiteBar]: !subsiteBar?.props.hideSubsiteBar },
				)}
				role="banner"
			>
				<div
					className={classNames(
						styles.Header_inner,
						{ [styles.Header_inner___isFrontPage]: isFrontPage },
						{ [styles.Header_inner___hasSubsiteBar]: !subsiteBar?.props.hideSubsiteBar },
					)}
				>
					{subsiteBar}

					<Container width="Full width">
						<Grid nogutter={true}>
							<div className={styles.Header_options}>
								<div className={styles.Header_search}>
									<button
										title={dictionary.getValue('Search.OpenSearch', null, 'Åbn søgning')}
										className={classNames(styles.Header_searchIcon)}
										onClick={openSearchClick}
										onKeyDown={onEnterEvent}
										tabIndex={toggleSearchField && -1}
									>
										<IconSearch />
									</button>
									{toggleSearchField &&
										<div
											ref={searchField}
											className={classNames(
												styles.Header_searchFieldContainer,
												{ [styles.Header_searchFieldContainer___isActive]: toggleSearchField },
												{ [styles.Header_searchFieldContainer___isClosed]: !toggleSearchField },
												{ [styles.Header_searchFieldContainer___isFrontPage]: isFrontPage },
											)}
										>
											<div className={styles.Header_search}>
												<button
													title={dictionary.getValue('Search.CloseSearch', null, 'Luk søgning')}
													tabIndex={0}
													id="headerSearchClose" 
													className={classNames(styles.Header_searchIcon, 'u-mobile-hidden')}
													onClick={handleSearchUp}
													onKeyDown={onEnterEventUp}
												>
													<IconClose/>
												</button>
											</div>
											<Search
												searchPage={searchPage}
												searchButtonText={dictionary.getValue('Search.Search', null, 'Søg')}
												searchInputPlaceholder={dictionary.getValue('Search.EnterKeyword', null, 'Indtast din søgning her')}
												requiredFieldErrorMessage={dictionary.getValue('Search.RequiredField', null, 'Feltet er påkrævet')}
											/>
										</div>
									}
								</div>
								<div className={styles.Header_language} title={dictionary.getValue('Language.LanguageSelector', null, 'vælg sprog')}>{languageSelector}</div>
								{isMobile && mobileNavigation}
							</div>

							<div className={classNames(styles.Header_navigation)}>
								{serviceNavigation && navigation && (
									<div className={styles.Header_nav} data-ref="header__navPanel">
										<Grid nogutter={true} wrap={true}>
											<GridCell>
												{serviceNavigation} {navigation}
											</GridCell>
										</Grid>
									</div>
								)}
							</div>

							<div className={styles.Header_identity}>
								<Logo logo={logo} logoLink={logoLink} className={classNames(
									styles.Header_logo,
									className,
									{ "logo-koncern": logoSize === "Koncern" },
									{ "logo-small": logoSize === "Smal" },
									{ "logo-smaller": logoSize === "Smallere" },
								)} />
							</div>
						</Grid>
					</Container>
				</div>
				<BreadcrumbFeature />
			</header>
		</Fragment>
	);
};
