import classNames from 'classnames';
import { Container, Heading, Link, RichText } from 'ui/components';
import styles from './DocumentList.module.scss';
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg';

export interface DocumentListProps {
	className?: string;
	documents?: Content.MediaFile[];
	header?: string;
	teaser?: string;
	newWindow?: string;
}

export const DocumentList: React.FC<DocumentListProps> = ({ className, header, teaser, documents, newWindow = "_blank"}) => {
	const links = documents.map((doc) => {
		const fileName = doc.extension ?`${doc.name}.${doc.extension}` : doc.name;
		const linkage: Content.Link = {
			name: fileName,
			url: doc.url,
			title: fileName,
		};
		return {
			text: fileName,
			link: linkage,
			extension: doc.extension
		};
	});

	return (
		<Container width="Standard" className={styles.DocumentList_container}>
			<div
				className={classNames(styles.DocumentList, className)}
			>
				<Heading headingLevel="h2" style="lg" className={classNames(styles.DocumentList_heading)}>
					{header}
				</Heading>
				{teaser && <RichText className={styles.DocumentList_teaser} content={teaser} />}
				{links.length > 0 && (
					<ul role="list">
						{links.map((link, index) => (
							<li role="listitem" key={index} className={classNames(styles.DocumentList_links)}>
								<Link
									url={link.link?.url}
									className={classNames(styles.DocumentList_link)}
									target={link.link?.target || newWindow}
									ariaLabel={link?.text}
								>
									{link?.text}
									<div className={styles.DocumentList_iconWrapper}>
										<ArrowRight className={styles.DocumentList_icon} />
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}
			</div>
		</Container>
	);
};
