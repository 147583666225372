import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import styles from './VideoConsent.module.scss';

export interface VideoConsentProps {
	video?: string;
	className?: string;
	style?: any;
}

export const VideoConsent: React.FC<VideoConsentProps> = ({ video, className, style}) => {
	const [hasConsent, setHasConsent] = useState(false)
    // TODO: implement Umbraco dicionary in VideoConsentFeature
	const dictionary = useDictionaryContext();
	const cookieChangeSettings =  dictionary.getValue('Cookie.ChangeSettings', null, 'Klik for at åben cookiepanel');
	const cookieAcceptMarketingCookieText =  dictionary.getValue('Cookie.AcceptStatisticsCookieText', null, 'Du kan ikke se videoer hvis du ikke har accepteret statistik cookies');
	const [statisticsCookieValue, setStatisticsCookieValue] = useState(null)

	const loadCookie = (cookie) => {
		// @ts-ignore
		if (cookie) {
			setHasConsent(true);
		}	else {
			setHasConsent(false);
		}
	}

	const onClickEvent = (event) => {
		event.preventDefault();
		// @ts-ignore
		Cookiebot.renew();
	}
	
	useEffect(() => {

		window.addEventListener('CookiebotOnAccept', function (e) {

			//@ts-ignore
			if (Cookiebot.consent && Cookiebot.consent.statistics) {
				setHasConsent(true);
			}	else {
				setHasConsent(false);
			}
			}, false);
			if(statisticsCookieValue === null) {
				setStatisticsCookieValue(!statisticsCookieValue)
				//@ts-ignore
				if (Cookiebot.consent && Cookiebot.consent.statistics) {
					//@ts-ignore
					loadCookie(Cookiebot.consent.statistics);
				}
			}
	}, [hasConsent, statisticsCookieValue])

	return (
		<Fragment>
			{/* This is implementation for cookiebot!! not cookieinformation */}
			{hasConsent &&
				<div className={classNames("cookieconsent-optin-statistics", className)} style={style}>
					<div className={styles.VideoConsent_Video} dangerouslySetInnerHTML={{ __html: video }}></div>
				</div>
			}
			{!hasConsent &&
				<div className={classNames("cookieconsent-optout-statistics", className)} style={style}>
                    <div className={styles.VideoConsent_NotAccepted}>
					<a aria-label={cookieChangeSettings} href="#" onClick={onClickEvent} className={styles.VideoConsent_NotAcceptedAction}>{cookieChangeSettings}</a>
					<p className={styles.VideoConsent_NotAcceptedText}>{cookieAcceptMarketingCookieText}</p>
                    </div>
				</div>
			}
		</Fragment>
	)
};