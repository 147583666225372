import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Heading, Container, Link } from 'ui/components';
import styles from './TopTasks.module.scss';

export interface TopTasksProps {
	className?: string;
	heading?: string;
	topTasksItem?: { shortcuts?: Content.Link; text?: string }[];
	noBackground?: boolean;
	isFrontPage?: boolean;
}

export const TopTasks: React.FC<TopTasksProps> = ({ className, heading, topTasksItem, noBackground = false, isFrontPage = true }) => {
	const toptasksRef = useRef(null);
	const [height, setHeight] = useState(0)
	useEffect(() => {
		setHeight(toptasksRef.current.clientHeight /2 + 30)
	}, [])


	return (
		<Container width="Standard" className={styles.TopTasks_container}>
			<div
				 ref={toptasksRef}
				className={classNames(
					styles.TopTasks,
					{ [styles.TopTasks___noBg]: noBackground },
					{ [styles.TopTasks___isFrontPage]: isFrontPage },
					{ [styles.TopTasks___standard]: !isFrontPage },
					className,
				)}
				style={noBackground ? {marginTop: -height} : { backgroundColor: `var(--theme-20)`, marginTop: -height }}
			>
				{heading && (
					<Heading headingLevel="h2" style="sm" className={styles.TopTasks_heading}>
						{heading}
					</Heading>
				)}

				{topTasksItem && (
					<div className={styles.TopTasks_shortcuts}>
						{topTasksItem.map((topTasksItem, index) => (
							<Link ariaLabel={topTasksItem.text} key={index} url={topTasksItem?.shortcuts?.url} target={topTasksItem?.shortcuts?.target} className={styles.TopTasks_shortcutsLink}>
								{topTasksItem.text}
							</Link>
						))}
					</div>
				)}
			</div>
		</Container>
	);
};
