import React, { useContext,useState } from 'react';

export interface ILocale{
	currentLocale?:string
}
export type LocaleType ={
	currentLocale?:string
	setCurrentLocale?:(locale:string)=>void
}
const LocaleContext = React.createContext<LocaleType>({currentLocale:process.env.DEFAULT_LOCALE || 'da'});
const useLocaleContext = ()=> useContext(LocaleContext);

const LocaleContextProvider = ({children, initialLocale="da"}) =>{
	const [currentLocale, setCurrentLocale] =useState<string>(initialLocale);
	return (
		<LocaleContext.Provider value={{currentLocale, setCurrentLocale}}>{children}</LocaleContext.Provider>
	)
}
export { LocaleContext, useLocaleContext, LocaleContextProvider}
