import classNames from 'classnames';
import styles from './VideoCard.module.scss';
import { Container, Heading, VideoConsent } from 'ui/components';

export interface VideoCardProps {
	className?: string;
	heading?: string;
	text?: string;
	mediaURL: string;
}

export const VideoCard: React.FC<VideoCardProps> = ({ className, mediaURL, heading, text }) => {
	return (
		<Container width="Standard" className={styles.VideoCard_Container}>
			<div className={classNames(styles.VideoCard, className)}>
				{heading && (
					<Heading headingLevel="h2" style="sm" className={styles.VideoCard_heading}>
						{heading}
					</Heading>
				)}

				{text && (
					<div className={styles.VideoCard_textbox}>
						<div className={styles.VideoCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
					</div>
				)}

				{mediaURL && <VideoConsent className={styles.VideoCard_video} video={mediaURL} />}
			</div>
		</Container>
	);
};
