import classNames from 'classnames';
import { Container, Heading, Paragraph, RichText, LinkButton } from 'ui/components';
import styles from './Event.module.scss';
import { formatTime } from 'helpers/date';

export interface EventProps {
	className?: string;
	heading?: string;
	signUp?: { name: string; url: string, target: string };
	manchet?: string;
	category?: string;
	eventDateFormatted?: string;
	startTime?: string;
	endTime?: string;
	eventLocation?: string;
	dateLabel?: string;
	timeLabel?: string;
	timePrefix?: string;
	priceLabel?: string;
	locationLabel?: string;
	price?: string;
	richText?: string;
}

export const Event: React.FC<EventProps> = ({
	className,
	heading,
	signUp,
	manchet,
	category,
	eventDateFormatted,
	startTime,
	endTime,
	eventLocation,
	price,
	richText,
	dateLabel,
	timeLabel,
	timePrefix,
	locationLabel,
	priceLabel,
}) => {
	return (
		<div className={classNames(styles.Event, className)}>
			<Container width="Standard">
				<Heading style="lg" headingLevel="h1" className={styles.Event_heading}>
					{heading}
				</Heading>

				{manchet && (
					<Paragraph style="lg" className={styles.Event_manchet}>
						{manchet}
					</Paragraph>
				)}

				<div className={styles.Event_metaBox}>
					<div className={styles.Event_metaBoxCategory}>{category}</div>
					<div className={styles.Event_metaBoxInner}>
						<div className={styles.Event_metaBoxContent}>
							<strong className={styles.Event_metaLabel}>{dateLabel}</strong>
							<div className={styles.Event_metaContent}>{eventDateFormatted}</div>
						</div>
						<div className={styles.Event_metaBoxContent}>
							<strong className={styles.Event_metaLabel}>{timeLabel}</strong>
							<div className={styles.Event_metaContent}>
								{timePrefix} {formatTime(new Date(startTime), 'hh:mm')} - {formatTime(new Date(endTime), 'hh:mm')}
							</div>
						</div>
						<div className={styles.Event_metaBoxContent}>
							<strong className={styles.Event_metaLabel}>{locationLabel}</strong>
							<div className={styles.Event_metaContent}>{eventLocation}</div>
						</div>
					</div>
					<div className={styles.Event_metaBoxPrice}>
						{priceLabel} {price}
					</div>
				</div>

				<div className={styles.Contactbox_content}>{richText && <RichText className={styles.ContentCard_richText} content={richText} />}</div>

				{signUp && (
					<LinkButton ariaLabel={signUp.name} url={signUp.url} target={signUp.target} style="primary" className={styles.Event_signUpLink}>
						{signUp.name ? signUp.name : signUp.url}
					</LinkButton>
				)}
			</Container>
		</div>
	);
};
