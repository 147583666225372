import React, { useEffect, useRef } from 'react';
import styles from './ScriptModule.module.scss';
import { Heading } from 'ui/components';
import { Container} from 'ui/components/4-habitats';


export interface ScriptModuleProps {
	script?: string;
	heading?: string;
}

export const ScriptModule: React.FC<ScriptModuleProps> = ({ script, heading }) => {
	const scriptRef = useRef<HTMLDivElement | null>(null);

	const scriptBlock = `${script}`;
	useEffect(() => {
		const fragment = document.createRange().createContextualFragment(scriptBlock);
		scriptRef?.current?.append(fragment);
	  }, [scriptBlock, scriptRef]);
	return (
		<Container width="Standard">
			{heading &&
				<Heading headingLevel="h2" style="sm" className={styles.ScriptModule_Heading}>
					{heading}
				</Heading>
			}
			<div ref={scriptRef}></div>
		</Container>
	)
};