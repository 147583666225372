import classNames from 'classnames';
import { Container, Heading, Paragraph, RichText, LinkButton, Link } from 'ui/components';
import styles from './ContactBox.module.scss';
import { ReactComponent as IconLocation } from 'ui/icons/icon_location.svg';
import { ReactComponent as IconPhone } from 'ui/icons/icon_phone.svg';
import { ReactComponent as IconEmail } from 'ui/icons/icon_email.svg';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useEffect } from 'react';

export interface ContactBoxProps {
	className?: string;
	heading?: string;
	manchet?: string;
	contactCard?: Content.Visitkort;
	address: string;
	writeUs: string;
	callUs: string;
	openingHours: string;
	visitingHours: string;
	otherInformationLabel: string;
	sundhedsplatformTekstSiteSetting: string;
	digitalMail?: string;
	seeMap?: string;
}

export const ContactBox: React.FC<ContactBoxProps> = ({
	className,
	heading,
	manchet,
	contactCard,
	address,
	writeUs,
	callUs,
	openingHours,
	visitingHours,
	otherInformationLabel,
	sundhedsplatformTekstSiteSetting,
	digitalMail,
	seeMap,
}) => {
	function isNullOrWhitespace(input) {
		return !input || !input.trim();
	}

	// Format phone nr if 8 digits
	function phoneFormat(phoneStr) {
		const regex = new RegExp(/^[0-9]{8}$/);
		if (regex.test(phoneStr.trim())) return phoneStr.match(/.{2}/g).join(' ');
		return phoneStr;
	}
	const dictionary = useDictionaryContext();
	const phoneLabel = dictionary.getValue('Contact.Tlf', null, 'tlf');

	useEffect(() => {}, [contactCard, heading, address]);

	return (
		<div className={classNames(styles.Contactbox, className)} style={{ backgroundColor: `var(--color-concern--10)` }} id="contactBox">
			<Container width="Standard">
				{heading && (
					<div className={classNames(styles.Contactbox_headingContainer)}>
						{heading && (
							<div className={classNames(styles.Contactbox_alignMiddle)}>
								<Heading style="lg" headingLevel="h2" className={styles.Contactbox_heading}>
									{heading}
								</Heading>
							</div>
						)}
					</div>
				)}

				{manchet && (
					<>
						<Paragraph style="md" className={classNames(styles.Contactbox_description, styles.Contactbox_description___mobile)}>
							<span dangerouslySetInnerHTML={{ __html: manchet.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
						</Paragraph>
					</>
				)}

				<div className={styles.Contactbox_content}>
					{contactCard && (
						<div className={styles.Contactbox_addressCard}>
							<div className={classNames(styles.Contactbox_flexContainer, styles.Contactbox_flexContainer___icon)}>
								<IconLocation />
								<Paragraph style="lgBold">{address}</Paragraph>
							</div>
							<div>
								<Paragraph style="md">{contactCard?.hospitalName}</Paragraph>
								<Paragraph style="md">{contactCard?.department}</Paragraph>
								<Paragraph style="md">{contactCard?.section}</Paragraph>
								<Paragraph style="md">{contactCard?.entrance}</Paragraph>
								<Paragraph style="md">
									{contactCard?.street} {contactCard?.streetNumber}
									{contactCard?.floor ? `, ${contactCard?.floor}` : ''}
								</Paragraph>
								<Paragraph style="md">
									{contactCard?.zipcode} {contactCard?.city}
								</Paragraph>
								{contactCard?.hospitalUrl && (
									<>
										<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
											{seeMap}
										</Paragraph>
										<Link
											ariaLabel={contactCard?.hospitalUrl.name}
											url={contactCard?.hospitalUrl.url}
											target={contactCard?.hospitalUrl.target}
											className={classNames(styles.Contactbox_link)}
										>
											{contactCard?.hospitalUrl.name}
										</Link>
									</>
								)}
							</div>
							{contactCard?.externalMapLocationUrl && (
								<div>
									<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
										{seeMap}
									</Paragraph>
									<Link
										ariaLabel={contactCard?.externalMapLocationUrl.name}
										url={contactCard?.externalMapLocationUrl.url}
										target="_blank"
										className={classNames(styles.Contactbox_link)}
									>
										{contactCard?.externalMapLocationUrl.name}
									</Link>
								</div>
							)}
							{contactCard?.openingHours && (
								<div>
									<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
										{openingHours}
									</Paragraph>
									<div dangerouslySetInnerHTML={{ __html: contactCard?.openingHours.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>
								</div>
							)}

							{contactCard?.visitingHours && (
								<div>
									<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
										{visitingHours}
									</Paragraph>
									<div dangerouslySetInnerHTML={{ __html: contactCard?.visitingHours.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>
								</div>
							)}
						</div>
					)}

					{contactCard && (
						<div className={styles.Contactbox_phoneCard}>
							<div>
								<div className={classNames(styles.Contactbox_flexContainer, styles.Contactbox_flexContainer___icon)}>
									<IconPhone />
									<Paragraph style="lgBold">{callUs}</Paragraph>
								</div>
								<div>
									{/* <Paragraph style="md"> */}
									<div dangerouslySetInnerHTML={{ __html: contactCard?.phoneHours.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>
									{/* </Paragraph> */}
									<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
										{contactCard?.phoneNumberName}
									</Paragraph>
									{contactCard?.phoneNumber && (
										<div>
											<Paragraph
												style="mdSemibold"
											>
												<a className={classNames(styles.Contactbox_phoneNumber, styles.Contactbox_phoneNumber___desktop)} href={`tel:${contactCard?.phoneNumber}`}>{phoneLabel} {phoneFormat(contactCard?.phoneNumber)}</a>
											</Paragraph>
											<LinkButton
												ariaLabel={`${phoneLabel} ${contactCard?.phoneNumber}`}
												url={`tel: ${phoneFormat(contactCard?.phoneNumber)}`}
												style="primary"
												className={classNames(styles.Contactbox_phoneNumber, styles.Contactbox_phoneNumber___mobile)}
											>
												{phoneLabel} {phoneFormat(contactCard?.phoneNumber)}
											</LinkButton>
										</div>
									)}
									{contactCard?.phoneNumber2 && (
										<div>
											<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
												{contactCard?.phoneNumberName2}
											</Paragraph>
											<Paragraph
												style="mdSemibold"
											>
												<a className={classNames(styles.Contactbox_phoneNumber, styles.Contactbox_phoneNumber___desktop)} href={`tel:${contactCard?.phoneNumber2}`}>{phoneLabel} {phoneFormat(contactCard?.phoneNumber2)}</a>
											</Paragraph>
											<LinkButton
												ariaLabel={`${phoneLabel} ${contactCard?.phoneNumber2}`}
												url={`tel: ${phoneFormat(contactCard?.phoneNumber2)}`}
												style="primary"
												className={classNames(styles.Contactbox_phoneNumber, styles.Contactbox_phoneNumber___mobile)}
											>
												{phoneLabel} {phoneFormat(contactCard?.phoneNumber2)}
											</LinkButton>
										</div>
									)}
									{contactCard?.phoneNumber3 && (
										<div>
											<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
												{contactCard?.phoneNumberName3}
											</Paragraph>
											<Paragraph
												style="mdSemibold"
											>
												<a className={classNames(styles.Contactbox_phoneNumber, styles.Contactbox_phoneNumber___desktop)} href={`tel:${contactCard?.phoneNumber3}`}>{phoneLabel} {phoneFormat(contactCard?.phoneNumber3)}</a>
											</Paragraph>
											<LinkButton
												ariaLabel={`${phoneLabel} ${contactCard?.phoneNumber3}`}
												url={`tel: ${phoneFormat(contactCard?.phoneNumber3)}`}
												style="primary"
												className={classNames(styles.Contactbox_phoneNumber, styles.Contactbox_phoneNumber___mobile)}
											>
												{phoneLabel} {phoneFormat(contactCard?.phoneNumber3)}
											</LinkButton>
										</div>
									)}
								</div>
							</div>
						</div>
					)}

					{contactCard && (
						<div className={styles.Contactbox_emailCard}>
							<div className={classNames(styles.Contactbox_flexContainer, styles.Contactbox_flexContainer___icon)}>
								<IconEmail />
								<Paragraph style="lgBold">{writeUs}</Paragraph>
							</div>
							{contactCard?.email && (
								<Paragraph style="md" className={styles.Contactbox_emailLink}>
									<a aria-label={contactCard?.email} href={`mailto:${contactCard?.email}`}>
										{contactCard?.email}
									</a>
								</Paragraph>
							)}
							{contactCard?.digitalPost && (
								<>
									<Paragraph style="md" className={styles.Contactbox_contentTitle___smallMargin}>
										<Link
											ariaLabel={digitalMail}
											url={contactCard?.digitalPost}
											target="_blank"
											className={classNames(styles.Contactbox_link)}
										>
											{digitalMail}
										</Link>
									</Paragraph>
								</>
							)}
							<div>
								{contactCard?.other && (
									<>
										<Paragraph style="lgBold" className={styles.Contactbox_contentTitle}>
											{otherInformationLabel}
										</Paragraph>
										<RichText className={styles.Contactbox_richText} content={contactCard?.other} />
									</>
								)}
								{contactCard?.sundhedsplatformtextToggle && (
									<RichText className={styles.Contactbox_sundhedsplatformTekst} content={sundhedsplatformTekstSiteSetting} />
								)}
							</div>
						</div>
					)}
				</div>
			</Container>
		</div>
	);
};
