import styles from './Link.module.scss';

export interface AnchorProps {
	anchorName?: string;
}

export const Anchor: React.FC<AnchorProps> = ({ anchorName }) => {
	
	
	return (
		<a id={anchorName} aria-hidden="true"></a>
)};
