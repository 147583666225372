import classNames from 'classnames';
import styles from './ArticleText.module.scss';
import { RichText } from 'ui/components';
export interface ArticleTextProps {
	content: string;
	className?: string;
}

export const ArticleText: React.FC<ArticleTextProps> = ({ className, content }) => (
	<div className="u-grid__cell u-grid__cell--width-100">
		
		<RichText className={styles.ArticleText} content={content} />
	</div>
);
