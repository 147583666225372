import {
	Heading,
	Paragraph,
	Button,
	Form,
	FormfieldString,
	FormfieldTextarea,
	FormfieldCheckbox,
	FormfieldRadio,
	Spinner,
	Label,
} from 'ui/components';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import { useState } from 'react';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import styles from './FormPicker.module.scss';
import classNames from 'classnames';

export interface FormPickerProps {
	className?: string;
	properties?: any;
	postFormWithId?: (formId: any, body: any) => Promise<Models.Product>;
}

type FormDataProps = StandardForm & JobIPsyktiatrienForm & FeedbackForm;

interface StandardForm {
	nameLabel: string;
	phoneNumberLabel: string;
	emailLabel: string;
	textFieldLabel: string;
	textBoxLabel: string;
	required: boolean;
}

interface JobIPsyktiatrienForm {
	nameLabel: string;
	emailLabel: string;
	phoneNumberLabel: string;
	commentLabel: string;
	checkboxLabel: boolean;
	required: boolean;
}

interface FeedbackForm {
	questionLabel: boolean;
	commentLabel: string;
}

export const FormPicker: React.FC<FormPickerProps> = ({ properties, postFormWithId }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [message, setMessage] = useState<any>('');
	const { register, handleSubmit, setValue, control } = useForm<FormDataProps>();

	const onSubmit: SubmitHandler<FormDataProps> = (data) => {
		setIsLoading(true);
		setErrorSummary(0);

		const transformedData = [];
		Object.keys(data).forEach((key) => {
			transformedData.push({
				key: key,
				value: data[key],
			});
		});

		postFormWithId(properties.id, transformedData)
			.then(() => {
				setIsLoading(false);
				setMessage(dictionary.getValue('Action.Thanks', null, 'Tak'));
			})
			.catch(() => {
				setIsLoading(false);
				setMessage(dictionary.getValue('Form.Error', null, 'Der er desværre sket en fejl'));
			});
	};
	const { errors } = useFormState({
		control,
	});

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
	};

	const [errorSummary, setErrorSummary] = useState(0);
	const dictionary = useDictionaryContext();

	return (
		<div className={styles.FormPicker_wrapper}>
			{properties && (
				<>
					{properties.properties.subject && (
						<Heading headingLevel="h2" style="xl" className={classNames(styles.FormPicker_heading, "u-bottom-margin--md")}>
							{properties.properties.subject}
						</Heading>
					)}

					<Form onSubmit={handleSubmit(onSubmit, onError)} errorSummary={errorSummary} className="u-bottom-margin--xl">
						{/* Standard form */}
						{properties && properties.documentType === 'standardForm' && (
							<>
								<FormfieldString
									id="nameLabel"
									name="nameLabel"
									label={properties.properties.nameLabel}
									type="text"
									register={register}
									onChange={(e) => setValue('nameLabel', e.target.value)}
									placeholder={properties.properties.nameLabel}
									state={errors.nameLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									className="u-bottom-margin--md"
								/>
								{/* <input className="u-bottom-margin--md" {...register("nameLabel", { required: true, maxLength: 20 })} /> */}

								<FormfieldString
									id="phoneNumberLabel"
									name="phoneNumberLabel"
									label={properties.properties.phoneNumberLabel}
									type="text"
									register={register}
									onChange={(e) => setValue('phoneNumberLabel', e.target.value)}
									placeholder={properties.properties.phoneNumberLabel}
									state={errors.phoneNumberLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									className="u-bottom-margin--md"
								/>

								<FormfieldString
									id="emailLabel"
									name="emailLabel"
									label={properties.properties.emailLabel}
									type="email"
									register={register}
									onChange={(e) => setValue('emailLabel', e.target.value)}
									placeholder={properties.properties.emailLabel}
									state={errors.emailLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
									className="u-bottom-margin--md"
								/>

								<FormfieldString
									id="textFieldLabel"
									name="textFieldLabel"
									label={properties.properties.textFieldLabel}
									type="text"
									register={register}
									onChange={(e) => setValue('textFieldLabel', e.target.value)}
									placeholder={properties.properties.textFieldLabel}
									state={errors.textFieldLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									className="u-bottom-margin--md"
								/>

								<FormfieldTextarea
									id="textBoxLabel"
									name="textBoxLabel"
									label={dictionary.getValue('Form.Label.Message', null, 'Kommentar')}
									placeholder={dictionary.getValue('Form.Placeholder.Message', null, 'Indtast besked')}
									register={register}
									state={errors.textBoxLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									className="u-bottom-margin--md"
								/>
							</>
						)}

						{/* Job i psykiatrien form */}
						{properties && properties.documentType === 'simpleForm' && (
							<>
								<FormfieldString
									id="nameLabel"
									name="nameLabel"
									label={properties.properties.nameLabel}
									type="text"
									register={register}
									onChange={(e) => setValue('nameLabel', e.target.value)}
									placeholder={properties.properties.nameLabel}
									state={errors.nameLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									className="u-bottom-margin--md"
								/>

								<FormfieldString
									id="emailLabel"
									name="emailLabel"
									label={properties.properties.emailLabel}
									type="email"
									register={register}
									onChange={(e) => setValue('emailLabel', e.target.value)}
									placeholder={properties.properties.emailLabel}
									state={errors.emailLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
									className="u-bottom-margin--md"
								/>

								<FormfieldString
									id="phoneNumberLabel"
									name="phoneNumberLabel"
									label={properties.properties.phoneNumberLabel}
									type="text"
									register={register}
									onChange={(e) => setValue('phoneNumberLabel', e.target.value)}
									placeholder={properties.properties.phoneNumberLabel}
									state={errors.phoneNumberLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									className="u-bottom-margin--md"
								/>

								<FormfieldString
									id="commentLabel"
									name="commentLabel"
									label={properties.properties.commentLabel}
									type="text"
									register={register}
									onChange={(e) => setValue('commentLabel', e.target.value)}
									placeholder={properties.properties.commentLabel}
									state={errors.commentLabel ? { hasError: true, required: true } : { required: true }}
									errorMessage={dictionary.getValue('Form.Error.Required', null, 'Dette felt er påkrævet')}
									className="u-bottom-margin--md"
								/>

								<FormfieldCheckbox
									register={register}
									id="checkbox"
									state={errors.checkboxLabel ? { hasError: true, required: true } : { required: true }}
									name="checkboxLabel"
									multiline={true}
									className="u-bottom-margin--md"
									label={properties.properties.checkboxLabel}
								/>
							</>
						)}

						{/* Feedback form */}
						{properties && properties.documentType === 'feedbackForm' && (
							<>
								<Paragraph className="u-bottom-margin--md">{properties.properties.descriptionText}</Paragraph>


								<Label className={styles.FormPicker_feedbackLabel}>{properties.properties.questionLabel}</Label>
								<div className="u-bottom-margin--lg">
									<FormfieldRadio id="radio1" name="radio" label="Ja" register={register} value={'Yes'} />
									<FormfieldRadio id="radio2" name="radio" label="Nej" register={register} value={'No'} />
								</div>

								<FormfieldTextarea
									id="commentLabel"
									name="commentLabel"
									label={properties.properties.commentLabel}
									register={register}
									placeholder={properties.properties.commentLabel}
									className="u-bottom-margin--md"
								/>

								<Paragraph className="u-bottom-margin--md">{properties.properties.DisclaimerText}</Paragraph>
							</>
						)}

						{properties.properties.submitLabel && (
							<>
								{isLoading ? (
									<Spinner />
								) : (
									<div className="u-grid__cell u-grid__cell--width-100 u-grid__cell--width-50@md">
										{message ? (
											<p style={{ color: 'var(--color-error)' }}>{message}</p>
										) : (
											<Button style="primary" type="submit" aria-label="Send">
												{properties.properties.submitLabel}
											</Button>
										)}
									</div>
								)}
							</>
						)}
					</Form>
				</>
			)}
		</div>
	);
};
