import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { Form, SearchField } from 'ui/components';
import styles from './Search.module.scss';

export interface SearchResultsProps {
	numberOfResults: number;
	query: string;
	results: {
		heading?: string;
		link: {
			url: string;
		};
		kicker?: string;
		text?: string;
		date?: Date;
	}[];
}

export interface SearchProps {
	searchButtonText?: string;
	searchInputPlaceholder?: string;
	requiredFieldErrorMessage?: string;
	searchResultText?: string;
	searchPage?: Content.Link;
	searchErrorText?: string;
}


export const Search: React.FC<SearchProps> = ({
	searchButtonText,
	searchInputPlaceholder,
	requiredFieldErrorMessage,
	searchPage,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ query: string }>();

	const router = useRouter();

	const onSubmit = async (data, e) => {
		/**
		 * This is a hack to get the current value of the query field.
		 * When Hook Form recieves the onSubmit event from the enter key
		 * no blur event happens, so the form state (data) is not updated.
		 * Otherwise we could have just called data.query
		 */
		const currentQuery = e.target.querySelector('input[name="query"]').value;
		router.push( `${searchPage.url}#?cludoquery=${currentQuery}&cludoHospitalName=&cludopage=1&cludoinputtype=standard` )
	};


	return (
		<div className={styles.Search}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<SearchField
					buttonText={searchButtonText}
					id="search"
					name="query"
					placeholder={searchInputPlaceholder}
					register={register}
					searchPage={searchPage}
					//state={errors.query ? { hasError: true, required: true } : { required: true }}
					errorMessage={requiredFieldErrorMessage}
					className={errors.query && 'hasError'}
				/>
			</Form>
		</div>
	);
};
