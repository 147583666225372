import {
	Spinner,
	Grid,
	GridCell,
	AgendaCard,
	AgendaCardProps,
	Pagination,
	PaginationProps,
	Container,
	LocalSearchBox,
	FilterType,
	PaginationItem,
} from 'ui/components';
import styles from './AgendaList.module.scss' ;
import React, { useEffect, useState } from 'react';
import { AgendaFetcherResult } from 'features/Modules/AgendaList/AgendaFetcher';
import { PaginationReact } from 'ui/components';
export interface AgendaListProps {

	pageNumber?: number;
	heading?: string;
	agendas?: AgendaCardProps[];
	agendaPage?: {
		url?: string;
		name?: string;
		target?: string;
	};
	pageSize?: number;
	filters?: FilterType[];
	agendaFetcher?:(
		pageNumber?: number,
		pageSize?: number,
		agendaFilterOptions?: string[]) =>AgendaFetcherResult;
}
export const AgendaList: React.FC<AgendaListProps> = ({ agendaPage, heading, pageSize, filters, agendaFetcher }) => {
	const [inputValue, setInputValue] = useState('');
	const [filterOptions, setFilterOptions] = useState<string[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const allAgendas = agendaFetcher(pageNumber,pageSize ||10,filterOptions);
	const [paginationItems, setPaginationItems] = useState<PaginationItem[]>([]);
	const pagination: PaginationProps = {
		paginationItems: paginationItems,
		controlPrev: {
			direction: 'prev',
			link: '#',
			title: '#',
			ariaLabel: 'previous',
		},
		controlNext: {
			direction: 'next',
			link: '#',
			title: '#',
			ariaLabel: 'next',
		},
	};
	const createPagination = () => {
		const arr = new Array<PaginationItem>();
		for (let index = 0; index < allAgendas.totalPages; index++) {
			const cleanNmbr = index;
			arr.push({
				active: allAgendas.pageNumber == cleanNmbr,
				label: (cleanNmbr+1).toString(),
				link: '#',
			});
		}
		setPaginationItems(arr);
	};
	const onChange = (event: React.ChangeEvent<HTMLInputElement>, activeFilterName: string) => {
		const value = event.target.value;
		const index = filterOptions.indexOf(value);
		if (index > -1) {
			setFilterOptions([...filterOptions.slice(0, index), ...filterOptions.slice(index + 1)]);
		} else {
			setFilterOptions([...filterOptions, ...[value]]);
		}
	};
	const isKeyPressed = (event: React.KeyboardEvent, activeFilterName) => {
		if (event.key === 'Enter') {
			const value = event.key;
			const index = filterOptions.indexOf(value);
			if (index > -1) {
				setFilterOptions([...filterOptions.slice(0, index), ...filterOptions.slice(index + 1)]);
			} else {
				setFilterOptions([...filterOptions, ...[value]]);
			}
		}
	};
	const pressNext = (e) => {
		const correctedPage = pageNumber +1;
		if (correctedPage < allAgendas.totalPages && correctedPage !== allAgendas.totalPages) {
			setPageNumber(pageNumber + 1);
			allAgendas.pageNumber =pageNumber
		}
	};
	const pressPrev = (e) => {
		const correctedPage = pageNumber +1;
		if (correctedPage > 1) {
			setPageNumber(pageNumber-1);
			allAgendas.pageNumber =pageNumber

		}
	};
	const onNumberPagination = (nmber) => {
		const correctedPage = nmber -1;
		setPageNumber(correctedPage);
		
		allAgendas.pageNumber = pageNumber-1;
	};
	const onInputChange = (value) => {
		setInputValue(value);
	};
	useEffect(() => {
		createPagination();

	}, []);
	useEffect(() => {
		createPagination();
	}, [filterOptions,allAgendas.pageNumber]);
	useEffect(()=>{
		createPagination();
	},[allAgendas.totalPages,pageNumber]);
	const onPageClickHandler = (event: {
		index: number | null;
		selected: number;
		nextSelectedPage: number | undefined;
		event: object;
		isPrevious: boolean;
		isNext: boolean;
		isBreak: boolean;
		isActive: boolean;
	  }) => {
		setPageNumber(event.selected);
	};
	return (
		<Container width="Standard" className={styles.AgendaList}>
			<LocalSearchBox
				onInputValueChange={onInputChange}
				inputValueText={inputValue}
				heading={heading}
				onlyFilter={true}
				filters={filters}
				onFilterChange={onChange}
				onKeyPress={isKeyPressed}
			/>

			<PaginationReact itemsPerPage={10} handlePageClick={onPageClickHandler} pageCount={paginationItems.length} selectedPage={pageNumber}	/>
			<Grid wrap={true}>
				{allAgendas.agendas.map((item, index) => (
					<GridCell key={index} mobileWidth="100" desktopWidth="33">
					<AgendaCard link={{url:`${agendaPage.url}?agendaId=${item.link.url}`, name:agendaPage.name, target:agendaPage.target}} 
						agendaType={item.agendaType} heading={item.heading} date={item.date}  text={item.text}/>
					</GridCell>
				))}
			</Grid>

			<PaginationReact itemsPerPage={10} handlePageClick={onPageClickHandler} pageCount={paginationItems.length} selectedPage={pageNumber}	/>
		</Container>
	);
};
