import { formatTime } from 'helpers/date';
import { Grid, GridCell, ListCard, ListCardProps, PaginationProps, Container, Heading, PaginationReact } from 'ui/components';
import styles from './EventList.module.scss';

export interface EventListProps {
	events?:ListCardProps[];
	setOffset?:(n:number) => void
	pageOffset?:number;
	totalPages?:number;
	pagination?: PaginationProps;
	onPageClickHandler?: (ev:{
		index: number | null;
		selected: number;
		nextSelectedPage: number | undefined;
		event: object;
		isPrevious: boolean;
		isNext: boolean;
		isBreak: boolean;
		isActive: boolean;
	  }) => void;
	  pageNumber?:number;
	  limit?:number;
}

export const EventList: React.FC<EventListProps> = ({ events, totalPages, pageNumber, onPageClickHandler,limit }) => {
	const groupedEvents =Array.from(events.reduce(
		(event, key) => event.set(key.event.month, [...event.get(key.event.month)||[], key.event]),
		new Map()
	),([month, events]) => ({month, events}));


	return (
		<Container width="Standard" className={styles.EventsList}>
			<PaginationReact pageCount={totalPages} selectedPage={pageNumber} onPageNumberClick={onPageClickHandler} handlePageClick={onPageClickHandler}  itemsPerPage={limit}/>
			{groupedEvents &&
				groupedEvents.map((eventInMonth, index) => (
					<div key={index} className={styles.EventList_list}>
						 {eventInMonth.month && (
							<Heading headingLevel="h2" style="sm" className={styles.EventsList_heading}>
								{eventInMonth.month}
							</Heading>
						)} 
						{eventInMonth.events && (
							<Grid wrap={true}>
								{eventInMonth.events?.map((event,index) => (
									<GridCell key={index} mobileWidth="100" desktopWidth="33">
										<ListCard  {...{event:{date: event.date, dateFormatted: event.date, price: event.price, startTime: formatTime(event.startTime, 'hh:mm'), endTime: formatTime(event.endTime, 'hh:mm'), title: event.title, month: event.month, url: event.url, location: event.location}}} />
									</GridCell>
								))}
							</Grid>
						)}
					</div>
				))}

<PaginationReact pageCount={totalPages} selectedPage={pageNumber} onPageNumberClick={onPageClickHandler} handlePageClick={onPageClickHandler}  itemsPerPage={limit}/>
		</Container>
	);
};
