import styles from './Pagination.module.scss';
import ReactPaginate from 'react-paginate';
import { ReactComponent as ChevronLeft } from 'ui/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'ui/icons/icon-chevron-right.svg';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useEffect } from 'react';

export interface PaginationReactProps {
	
	paginationItems?:{ embedLink?: string; }[];
	itemsPerPage?: number;
	pageCount?: number;
	selectedPage?: number;
	handlePageClick?: (e) => void;
	onPageNumberClick?: (e) => void;

}

export const PaginationReact: React.FC<PaginationReactProps> = ({ pageCount, selectedPage, handlePageClick }) => {
	const dictionary = useDictionaryContext();
	const page =  dictionary.getValue('Pagination.Page', null, 'TEST');

	return (
		<ReactPaginate
			containerClassName={styles.Pagination}
			pageClassName={styles.Pagination_item}
			pageLinkClassName={styles.PaginationReact_link}
			activeLinkClassName={styles.PaginationReact_link___active}
			previousLinkClassName={styles.PaginationReact_link}
			nextLinkClassName={styles.PaginationReact_link}
			disabledLinkClassName={styles.PaginationReact_link___disabled}
			breakLabel="..."
			nextLabel={<ChevronRight />}
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			previousLabel={<ChevronLeft />}
			renderOnZeroPageCount={null}
			forcePage={selectedPage}
			ariaLabelBuilder={ (pageIndex) => {
				return `${page} ${pageIndex}`
			} }
		/>
	);

}




