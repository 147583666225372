import classNames from 'classnames';
import styles from './ServiceNavigation.module.scss';
import { Link } from 'ui/components';

export interface ServiceNavigationProps {
	className?: string;
	serviceNavigationItems?: Navigation.NavigationItem[];
	hideServiceNavigation?: boolean;
	isFrontPage?: boolean;
	closeMenu?: () => void;
}

export const ServiceNavigation: React.FC<ServiceNavigationProps> = ({ className, serviceNavigationItems, hideServiceNavigation, isFrontPage, closeMenu }) => {
	if (hideServiceNavigation) return null;
	if (!serviceNavigationItems) return null;
	return (
		<ul role='list' className={classNames(styles.ServiceNavigation, className)}>
			{serviceNavigationItems.map((item: Navigation.NavigationItem, index) => (
				<li role='listitem' key={index} className={styles.ServiceNavigation_item}>
					<Link ariaLabel={item.title || item.name} mouseDownHandler={closeMenu} className={classNames(styles.ServiceNavigation_link, { [styles.isFrontPage]: isFrontPage })} {...item}>
						{item.title || item.name}
					</Link>
				</li>
			))}
		</ul>
	);
};
