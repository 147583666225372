import { FormEventHandler } from 'react';
import { Notification } from 'ui/components';

export interface FormProps {
	className?: string;
	id?: string;
	children: React.ReactNode;
	errorSummary?: number;
	onSubmit?: FormEventHandler<HTMLFormElement>;
}

export const Form: React.FC<FormProps> = ({ className, children, errorSummary, onSubmit }) => {
	let errorMessage: React.ReactNode;
	if (errorSummary > 0) {
		//TODO: Remove hardcoded text
		errorMessage = (
			<Notification hidden>
				<p>Indsendelsen fejlede. Der er i alt {errorSummary} fejl.</p>
			</Notification>
		);
	}
	return (
		<form className={className} onSubmit={onSubmit}>
			{errorMessage}
			{children}
		</form>
	);
};
