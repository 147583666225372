import Script from 'next/script';
import { Container } from 'ui/components/4-habitats';

export const TwitterFeed: React.FC = () => {
	return (
		<Container width="Standard" className="u-bottom-padding--xl">
			<div className="TwitterFeed">
				<a aria-label="Twitter" className="twitter-timeline" href="https://twitter.com/regionsjaelland?ref_src=twsrc%5Etfw">
					Twitter
				</a>
				<Script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></Script>
			</div>
		</Container>
	);
};
