import classNames from 'classnames';
import styles from './FormfieldTextarea.module.scss';
import { ErrorMessage, Label } from 'ui/components';

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
};

export interface FormfieldTextareaProps {
	className?: string;
	defaultValue?: string;
	id: string;
	name?: string;
	placeholder?: string;
	register?: any;
	errorMessage?: string;
	rows?: number;
	label: string;
	state?: State;
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, name: string, id: string) => {
	if (state?.['hasError']) return name + id;
};

export const FormfieldTextarea: React.FC<FormfieldTextareaProps> = ({
	className,
	state,
	id,
	label,
	placeholder,
	register,
	errorMessage,
	defaultValue,
	rows,
	name,
}) => {
	const { required, disabled, hasError, isHidden } = state ?? {};

	return (
		<div className={classNames(styles.FormfieldTextarea, className, { [styles.hasError]: hasError })}>
			{!isHidden && (
				<Label id={id} className={styles.FormfieldTextarea_label} state={state}>
					{label}
				</Label>
			)}
			<div className={classNames(styles.FormfieldTextarea_wrapper, { [styles.hasError]: hasError }, { [styles.isDisabled]: disabled })}>
				<textarea
					className={classNames(styles.FormfieldTextarea_input)}
					name={name}
					id={id}
					aria-label={ariaLabel(state, label)}
					aria-describedby={ariaError(state, name, id)}
					disabled={disabled}
					placeholder={placeholder}
					defaultValue={defaultValue}
					rows={rows || 5}
					{...(register && { ...register(name, { required }) })}
				/>
			</div>
			{hasError && (
				<ErrorMessage id={name + id} className={classNames(styles.FormfieldTextarea_ErrorMessage, { [styles.hasError]: hasError })}>
					{errorMessage}
				</ErrorMessage>
			)}
		</div>
	);
};
