import { Container, Button, Link } from 'ui/components';
import styles from './Breadcrumb.module.scss';
import classNames from 'classnames';
import { ReactComponent as IconPrint } from 'ui/icons/icon_print.svg';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface BreadcrumbProps {
	className?: string;
	breadcrumbItems: Navigation.NavigationItem[];
	printButtonActive?: boolean;
	useTheme?: boolean;
}

export const Breadcrumb: React.VFC<BreadcrumbProps> = ({ className, breadcrumbItems, printButtonActive = false, useTheme = true }) => {
	const dictionary = useDictionaryContext();

	return (
		<>
			{breadcrumbItems.length !== 0 && (
				<div className={classNames(styles.Breadcrumb, className)} style={useTheme ? { background: 'var(--theme-30)' } : {}}>
					<Container width="Standard" className={styles.Breadcrumb_inner}>
						<ul role="list" className={classNames(styles.Breadcrumb_list)}>
							{breadcrumbItems.map((breadcrumbItem, index) => (
								<li role="listitem" key={index} className={styles.Breadcrumb_item}>
									{breadcrumbItem.url && index < breadcrumbItems.length - 1 ? (
										<Link className={styles.Breadcrumb_url} ariaLabel={breadcrumbItem.name} {...breadcrumbItem}>
											{breadcrumbItem.name}
										</Link>
									) : (
										<p className={classNames(styles.Breadcrumb_url, styles.Breadcrumb_url___current)}>{breadcrumbItem.name}</p>
									)}
								</li>
							))}
						</ul>
						{printButtonActive && (
							<div className={styles.Breadcrumb_printButton}>
								<Button style="secondarySmall" icon={<IconPrint />} onClick={() => window.print()}>
									{dictionary.getValue('Action.Print', null, 'Udskriv')}
								</Button>
							</div>
						)}
					</Container>
				</div>
			)}
		</>
	);
};
