import classNames from 'classnames';
import styles from './SkipToMain.module.scss';

export interface SkipToMainProps {
	children: React.ReactNode;
	link?: string;
	title?: string;
	ariaLabel?: string;
}

export const SkipToMain: React.FC<SkipToMainProps> = ({ children, title, ariaLabel, link }) => (
	<a href={link} title={title} aria-label={ariaLabel} className={classNames(styles.SkipToMain)}>
		{children}
	</a>
);
