import classNames from 'classnames';
import styles from './EmphasizedContentCard.module.scss';
import { Heading, Picture, Container, RichText, VideoConsent } from 'ui/components';
import { LinkButtonFeature } from 'features/Modules/Linkbutton';

export interface EmphasizedContentCardProps {
	className?: string;
	image: any | null;
	video?: string;
	cTALink?: {
		url: string;
		name?: string;
		target?: string;
	};
	cTASecondaryLink?: {
		url: string;
		name?: string;
		target?: string;
	};
	heading?: string;
	text?: string;
	displayLinkAsButton: boolean;
	headingColor?: string;

	orientation: 'Vertical' | 'Block' | 'Horizontal' | 'Horizontal Reverse' | '';
}

export const EmphasizedContentCard: React.FC<EmphasizedContentCardProps> = ({
	className,
	image,
	video,
	cTALink,
	cTASecondaryLink,
	heading,
	text,
	displayLinkAsButton,
	headingColor,
	orientation,
}) => {
	const contentImage = image && (
		<div className={styles.EmphasizedContentCard_media}>
			<span className={styles.EmphasizedContentCard_mediabox}>
				<Picture
					aspectRatio={0.5625}
					url={image?.url}
					altText={image?.altText}
					focalPoint={image?.focalPoint}
					sizes="100vw"
					className={video ? styles.EmphasizedContentCard_mediabox___picture : ""}
				/>
			</span>
		</div>
	)
	const contentVideo = (
		<VideoConsent
			className={classNames(styles.EmphasizedContentCard_media, styles.EmphasizedContentCard_media___videoOnly)}
			video={video}
		/>
	)
	const contentInfoBox = (
		<div className={styles.EmphasizedContentCard_box}>
			<div className={styles.EmphasizedContentCard_textbox}>
				<Heading className={styles.EmphasizedContentCard_heading} style="lg" headingLevel="h2" color={headingColor && headingColor}>
					{heading}
				</Heading>
				<div className={styles.EmphasizedContentCard_textcontent}>
					<RichText className={styles.EmphasizedContentCard_text} content={text} />
					<div className={styles.EmphasizedContentCard_LinkBox}>
						{cTALink && (
							<LinkButtonFeature ariaLabel={cTALink.name} {...cTALink} className={styles.EmphasizedContentCard_button} style="primary">
								{cTALink.name}
							</LinkButtonFeature>
						)}

						{cTASecondaryLink && (
							<LinkButtonFeature
								{...cTASecondaryLink}
								className={styles.EmphasizedContentCard_button___secondary}
								style="secondary"
								ariaLabel={cTASecondaryLink.name}
							>
								{cTASecondaryLink.name}
							</LinkButtonFeature>
						)}
					</div>
				</div>
			</div>
		</div>
	)

	const content = (
		<>
			{image && (
				<>
					{contentImage}
				</>
			)}

			{video && !image && (
				<>
					{contentVideo}
				</>
			)}
			{contentInfoBox}
		</>
	);

	if(cTALink && displayLinkAsButton) {
		return (
			<>
				<a
					className={classNames(
						styles.EmphasizedContentCard,
						{ [styles.EmphasizedContentCard___row]: orientation === 'Horizontal' },
						{ [styles.EmphasizedContentCard___rowReverse]: orientation === 'Horizontal Reverse' },
						{ [styles.EmphasizedContentCard___rowBlock]: orientation === 'Block' },
						styles.EmphasizedContentCard___link,
						className,
					)}
					href={cTALink.url}
					target={cTALink.target}
					title={cTALink.name}
					aria-label={cTALink.name}
				>
					{content}
				</a>
				<a
					className={classNames(
						styles.EmphasizedContentCard,
						{ [styles.EmphasizedContentCard___row]: orientation === 'Horizontal' },
						{ [styles.EmphasizedContentCard___rowReverse]: orientation === 'Horizontal Reverse' },
						{ [styles.EmphasizedContentCard___rowBlock]: orientation === 'Block' },
						styles.EmphasizedContentCard___link,
						className,
					)}
					href={cTASecondaryLink.url}
					target={cTASecondaryLink.target}
					title={cTASecondaryLink.name}
					aria-label={cTASecondaryLink.name}
				>
					{content}
				</a>
			</>
		)
	}
	return (
		<Container width="Standard">
			<div
				className={classNames(
					styles.EmphasizedContentCard,
					{ [styles.EmphasizedContentCard___row]: orientation === 'Horizontal' },
					{ [styles.EmphasizedContentCard___rowReverse]: orientation === 'Horizontal Reverse' },
					{ [styles.EmphasizedContentCard___rowBlock]: orientation === 'Block' },
					className,
				)}
			>
				{content}
			</div>
		</Container>
	);
};
