import classNames from 'classnames';
import {
	Heading,
	Grid,
	GridCell,
	GridCellProps,
	Container,
	BlurbCard,
	BlurbCardProps,
	LinkButton
} from 'ui/components';
import styles from './NewsTeaser.module.scss';

export interface NewsTeaserProps {
	heading?: string;
	backgroundColor?: string;
	contentPicker?: any;
	link?: Content.Link;
	blurbCards?: BlurbCardProps[];
	settings?: {
		desktopWidth?: GridCellProps['desktopWidth'];
		mobileWidth?: GridCellProps['mobileWidth'];
	};
}
export const NewsTeaser: React.FC<NewsTeaserProps> = ({ heading, link, blurbCards, settings, backgroundColor }) => {
	const desktopWidth = settings?.desktopWidth ?? '33';
	const mobileWidth = settings?.mobileWidth ?? '';
	const bgColor = backgroundColor ? backgroundColor : 'var(--theme-10)';
	return (
		<div className={classNames(styles.NewsTeaser)} style={{ backgroundColor: bgColor }}>
			<Container width="Standard">
				<div className={styles.NewsTeaser_header}>
					{heading && (
						<div className={styles.NewsTeaser_headingContainer}>
							<Heading className={styles.NewsTeaser_heading} headingLevel="h2" style="lg">
								{heading}
							</Heading>
						</div>
					)}
					{link && (
						<div className={classNames(styles.NewsTeaser_buttonContainer)}>
							<LinkButton ariaLabel={link.name} url={link.url} style="secondary" title={link.name} className={classNames(styles.NewsTeaser_button, styles.NewsTeaser_button___desktop)}>
								{link.name}
							</LinkButton>
						</div>
					)}
				</div>

				{blurbCards && (
					<>
						{/* MOBILE RENDERING */}
						<div className={classNames(styles.NewsTeaser_listCards, styles.NewsTeaser_listCards___mobile)}>
							{blurbCards.slice(0, 1).map((listCard, index) => (
								<BlurbCard key={index} {...listCard} />
							))}
						</div>
						{/* DESKTOP RENDERING */}
						<div className={classNames(styles.NewsTeaser_listCards, styles.NewsTeaser_listCards___desktop)}>
							<Grid wrap>
								{blurbCards.map((card, index) => (
									<GridCell key={index} desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
										<BlurbCard key={index} kicker={card.kicker} {...card} />
									</GridCell>
								))}
							</Grid>
						</div>
					</>
				)}

				{link && (
					<LinkButton ariaLabel={link.name} url={link.url} style="secondary" className={classNames(styles.NewsTeaser_button, styles.NewsTeaser_button___mobile)}>
						{link.name}
					</LinkButton>
				)}
			</Container>
		</div>
	);
};
