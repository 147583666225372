import classNames from 'classnames';
import styles from './ArticleHeader.module.scss';
import { Heading, Grid, Link } from 'ui/components';

export interface ArticleHeaderProps {
	className?: string;
	kicker?: string;
	teaser?: string;
	showAnchorLink?: boolean;
	anchorLinkText?: string;
	heading?: React.ReactNode;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({ className, teaser, heading, anchorLinkText, showAnchorLink}) => {
	return (
		<header className={classNames(styles.ArticleHeader, className)}>
			<Grid full wrap>
				<div className={styles.ArticleHeader_heading}>
					<Heading headingLevel="h1" style="xl">
						{heading}
					</Heading>
				</div>
				{showAnchorLink && (
					<Link ariaLabel={anchorLinkText} url="#contactBox" hasArrow={true} className={styles.ArticleHeader_contactAnchorLink}>
						{anchorLinkText}
					</Link>
				)}
				<div className={styles.ArticleHeader_lead} dangerouslySetInnerHTML={{ __html: teaser.replace(/(?:\r\n|\r|\n)/g, '<br>')  }}></div>
			</Grid>
		</header>
	);
};
