import Script from 'next/script';
import { Container } from 'ui/components/4-habitats';

export const FacebookFeed: React.FC = () => {
	return (
		<Container width="Standard" className="u-bottom-padding--xl">
			<div className="FacebookFeed" style={{ textAlign: 'center' }}>
				<div id="fb-root"></div>
				<Script
					async
					defer
					crossOrigin="anonymous"
					src="https://connect.facebook.net/da_DK/sdk.js#xfbml=1&version=v15.0"
					nonce="BQtq9UfP"
				></Script>
				<div
					className="fb-page"
					data-href="https://www.facebook.com/regionsjaelland"
					data-tabs="timeline"
					data-width=""
					data-height=""
					data-small-header="false"
					data-adapt-container-width="true"
					data-hide-cover="false"
					data-show-facepile="true"
				>
					<blockquote cite="https://www.facebook.com/regionsjaelland" className="fb-xfbml-parse-ignore">
						<a aria-label="Facebook" href="https://www.facebook.com/regionsjaelland">
							Facebook
						</a>
					</blockquote>
				</div>
			</div>
		</Container>
	);
};
