import { useEffect } from 'react';
import styles from './RichText.module.scss';
import cx from 'classnames';
export interface RichTextProps {
	content: string;
	className?: string;
}

export const RichText: React.FC<RichTextProps> = ({ content, className }) => {
	// Make tables inside richtext editor responsive
	const width = 'width';
	useEffect(() => {
		const contentTable = document.querySelectorAll('[class*="RichText"] table')
		if ( document.querySelector('.table-responsive') )
			return null; // if check is already performed we do not want to do it again.
		for (let i = 0; i < contentTable.length; i++) {
			const tableWrap = document.createElement('div');
			tableWrap.classList.add('table-responsive');
			contentTable[i].parentNode.insertBefore(tableWrap, contentTable[i]);
			tableWrap.appendChild(contentTable[i]);
			// lastly set width on table so it does not shrink and get ugly on smaller screens
			contentTable[i].setAttribute(width, contentTable[i].clientWidth.toString())
		}
	}, []);

	return <div className={cx(styles.RichText, className)} dangerouslySetInnerHTML={{ __html: content }} />;
};


