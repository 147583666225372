const pad = (number: number) => (number < 10 ? `0${number}` : number);

const convertDate = (date) => new Date(date);

export const formatDate = (dateString: Date, format: string): string => {
	let formattedDateString = format;

	const jsDate = convertDate(dateString);

	const dd = pad(jsDate.getUTCDate()).toString();
	const mm = pad(jsDate.getUTCMonth() + 1).toString();
	const yyyy = jsDate.getUTCFullYear().toString();

	formattedDateString = formattedDateString.replace('dd', dd);
	formattedDateString = formattedDateString.replace('mm', mm);
	formattedDateString = formattedDateString.replace('yyyy', yyyy);

	return formattedDateString;
};

export const formatTime = (dateString: Date, format: string): string => {
	let formattedTimeString = format;

	const jsDate = convertDate(dateString);

	const hh = pad(jsDate.getUTCHours()).toString();
	const mm = pad(jsDate.getUTCMinutes()).toString();

	formattedTimeString = formattedTimeString.replace('hh', hh);
	formattedTimeString = formattedTimeString.replace('mm', mm);

	return formattedTimeString;
};
