import classNames from 'classnames';
import { Heading, Paragraph, Container, Link } from 'ui/components';
import styles from './UndersideList.module.scss';

export interface UndersideListProps {
	className?: string;
	heading?: string;
	manchet?: string;
	videoList?: Content.Video[];
	showVideoList?: boolean;
}

export const UndersideList: React.FC<UndersideListProps> = ({ heading, manchet, videoList, showVideoList }) => {

	return (
		<Container width="Standard">
			{heading && (
				<Heading className={classNames(styles.UndersideList_heading)} headingLevel="h2" style="lg">
					{heading}
				</Heading>
			)}
			{manchet && (
				<Paragraph className={classNames(styles.UndersideList_manchet)} style="lg">
					<span dangerouslySetInnerHTML={{ __html: manchet.replace(/(?:\r\n|\r|\n)/g, '<br>')  }}></span>
				</Paragraph>
			)}

			{videoList && showVideoList && (
				<div className={classNames(styles.UndersideList_list)}>
					{videoList.map((video, index) => (
						<div key={index} className={classNames(styles.UndersideList_video)}>
							<div className={styles.UndersideList_listMedia} dangerouslySetInnerHTML={{ __html: video.properties.videoEmbed }} />
							<Link url={video.url} ariaLabel={video.properties.videoTitle}>
								<div className={styles.UndersideList_overlay} />
							</Link>
							<Paragraph>
								{video.properties.videoTitle}</Paragraph>
						</div>
					))}
				</div>
			)}
		</Container>
	);
};
