import classNames from 'classnames';
import { Heading, Grid, GridCell, ContentCard, ContentCardProps, Container } from 'ui/components';
import styles from './PrioritizedContent.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Fragment } from 'react';
import { LinkButtonFeature } from 'features/Modules/Linkbutton';

export interface PrioritizedContentProps {
	className?: string;
	heading?: string;
	contentCards?: ContentCardProps[];
	seeAllLink?: Content.Link;
}

export const PrioritizedContent: React.FC<PrioritizedContentProps> = ({ className, seeAllLink, heading, contentCards }) => {
	return (
		<div className={classNames(styles.PrioritizedContent, className)}>
			<div className={styles.PrioritizedContent___desktop}>
				<Container width="Standard">
					<div className={styles.PrioritizedContent_headerContainer}>
						{heading && (
							<Heading headingLevel="h2" style="sm" className={classNames(styles.PrioritizedContent_heading)}>
								{heading}
							</Heading>
						)}
						{seeAllLink && (
							<LinkButtonFeature url={seeAllLink.url}  ariaLabel={seeAllLink.name} style="secondary" className={styles.PrioritizedContent_seeAllLink}>
								{seeAllLink.name}
							</LinkButtonFeature>
						)}
					</div>
					{contentCards && (
						<div className={classNames(styles.PrioritizedContent_contentCards, styles.PrioritizedContent_contentCards___desktop)}>
							<Grid wrap={true}>
								{contentCards.map((contentCard, index) => (
									<GridCell key={index} desktopWidth="33">
										<ContentCard {...contentCard} />
									</GridCell>
								))}
							</Grid>
						</div>
					)}
				</Container>
			</div>

			<div className={styles.PrioritizedContent___mobile}>
				{heading && (
					<Fragment>
						<Heading headingLevel="h4" style="sm" className={classNames(styles.PrioritizedContent_heading)}>
							{heading}
						</Heading>
						{seeAllLink && (
							<LinkButtonFeature url={seeAllLink.url} ariaLabel={seeAllLink.name} style="secondary" className={styles.PrioritizedContent_seeAllLink} 
>
								{seeAllLink.name}
							</LinkButtonFeature>
						)}
					</Fragment>
				)}
				{contentCards && (
					<div className={classNames(styles.PrioritizedContent_contentCards, styles.PrioritizedContent_contentCards___mobile)}>
						<Swiper
							slidesPerView={1.15}
							pagination={{
								clickable: true,
								type: 'bullets',
							}}
							onSlideChange={(e) => {
								/* setActiveSlide(e.realIndex); */
							}}
						>
							{contentCards.map((contentCard, index) => (
								<SwiperSlide key={index}>
									<ContentCard {...contentCard} />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				)}
			</div>
		</div>
	);
};
