import classNames from 'classnames';
import styles from './NavigationLeft.module.scss';
import { Link, Paragraph } from 'ui/components';
import { useEffect, useState } from 'react';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron-left.svg';

export interface NavigationLeftProps {
	className?: string;
	heading?: string;
	parentgNavItem?: Navigation.NavigationItem;
	currentNavItem?: Navigation.NavigationItem;
}

export const NavigationLeft: React.FC<NavigationLeftProps> = ({ className, heading, currentNavItem, parentgNavItem }) => (
	<nav className={classNames(styles.NavigationLeft, className)}>
		{heading && (
			<Paragraph className={styles.NavigationLeft_heading} style="xl">
				{heading}
			</Paragraph>
		)}
		<NavigationItem parentgNavItem={parentgNavItem} currentNavItem={currentNavItem} />
	</nav>
);
const isActiveItem = (item: Navigation.NavigationItem, item2: Navigation.NavigationItem) => {
	return item.name === item2.name;
};
const NavigationItem = ({ currentNavItem, parentgNavItem }) => {
	const [parentItem, setParentItem] = useState<Navigation.NavigationItem>({});
	const [activeNavItem, setActiveNavItem] = useState<Navigation.NavigationItem>({});

	useEffect(() => {
		if (!parentgNavItem.name) {
			setParentItem(currentNavItem);
		} else {
			setParentItem(parentgNavItem);
		}
		setActiveNavItem(currentNavItem);
	}, [parentgNavItem]);

	return (
		<>
			{parentItem.descendants?.length != 0 && (
				<ul>
					{parentItem.descendants &&
						parentItem.descendants.map((item: Navigation.NavigationItem, index: number) => {
							return (
								item?.hidden !== true && (
									<li
										key={index}
										className={classNames(styles.NavigationItem_item, {
											[styles.NavigationItem_item___isOpen]: isActiveItem(item, currentNavItem),
											['']: !isActiveItem(item, activeNavItem),
										})}
									>
										<div
											className={classNames(styles.NavigationItem_dropdown, {
												[styles.NavigationItem_dropdown___isActive]: item.active === true,
												['']: item.active === false,
											})}
										>
											<div className={styles.NavigationItem_buttonIconContainer}>
												{item.descendants &&
													item.descendants?.length !== 0 &&
													item.descendants?.find((item) => item.hidden === false) && (
														<div
															className={styles.NavigationItem_buttonIconContainerInner}
															onClick={() => {
																setActiveNavItem(!isActiveItem(item, activeNavItem) ? item : {});
															}}
														>
															{isActiveItem(item, activeNavItem) ? (
																<ChevronIcon
																	className={classNames(
																		styles.NavigationItem_buttonIcon,
																		styles.NavigationItem_buttonIcon___isActive,
																	)}
																/>
															) : (
																<ChevronIcon className={classNames(styles.NavigationItem_buttonIcon)} />
															)}
														</div>
													)}
											</div>

											<Link
												ariaLabel={item.name}
												url={item.url}
												className={classNames(styles.NavigationItem_link, {
													[styles.NavigationItem_link___isActive]: isActiveItem(item, currentNavItem),
													[styles.NavigationItem_link___isCurrent]: item.current === true,
												})}
											>
												{item.name}
											</Link>
										</div>

										{isActiveItem(item, activeNavItem) && item?.descendants && (
											<NavigationItem parentgNavItem={item} currentNavItem={currentNavItem} />
										)}
									</li>
								)
							);
						})}
				</ul>
			)}
		</>
	);
};
