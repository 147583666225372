import classNames from 'classnames';
import { Heading } from 'ui/components/1-atoms';
import styles from './Accordion.module.scss';

import { AccordionItem, AccordionItemProps } from './AccordionItem';

export interface AccordionProps {
	className?: string;
	allowMultiple?: boolean;
	title?: string;
	accordionItems: AccordionItemProps[];
}

export const Accordion: React.FC<AccordionProps> = ({ className, title, accordionItems }) => {
	return (
		<div className={classNames(styles.Accordion, className)}>
			{title && (
				<Heading headingLevel="h4" style="sm" className={classNames(styles.Accordion_heading)}>
					{title}
				</Heading>
			)}

			{accordionItems.map((accordionItem, index) => (
				<AccordionItem {...accordionItem} key={index} />
			))}
		</div>
	);
};
