import classNames from 'classnames';
import styles from './FormfieldCheckbox.module.scss';
import { Label } from 'ui/components';

export interface FormfieldCheckboxProps {
	className?: string;
	defaultChecked?: boolean;
	defaultValue?: string;
	id: string;
	label: string;
	multiline?: boolean;
	name?: string;
	fieldGroup?: boolean;
	register?: any;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
	};
}

export const FormfieldCheckbox: React.FC<FormfieldCheckboxProps> = ({
	className,
	fieldGroup,
	register,
	state,
	id,
	label,
	multiline,
	name,
	defaultChecked,
	defaultValue,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const labelState = {
		isHidden: false,
		disabled: state?.disabled,
		required: state?.required && !fieldGroup,
		hasError: state?.hasError,
	};

	return (
		<div
			className={classNames(
				styles.FormfieldCheckbox,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
				{ [styles.FormfieldCheckbox___multiline]: multiline === true },
			)}
		>
			<div className={classNames(styles.FormfieldCheckbox_container)}>
				<input
					type="checkbox"
					className={classNames(styles.FormfieldCheckbox_input, styles.Input___choice, {
						[styles.hasError]: hasError,
					})}
					name={name}
					id={id}
					defaultChecked={defaultChecked}
					disabled={disabled}
					defaultValue={defaultValue}
					{...(register && { ...register(name, { required }) })}
				/>
				<Label id={id} className={styles.FormfieldCheckbox_label} state={labelState}>
					{label}{' '}
				</Label>
			</div>
		</div>
	);
};
