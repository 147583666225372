import { Container, Heading, Picture, RichText } from 'ui/components';
import styles from './Infobox.module.scss';

export interface InfoboxProps {
    title: string;
	paragraph: string;
    icon: {url: string}
}

export const Infobox: React.FC<InfoboxProps> = ({ title, paragraph, icon }) => {
	return (
            <div className={styles.Infobox}>
                <div className={styles.TitleContainer}>
                    <Picture {...icon} className={styles.Icon}/>
                    <Heading
                        style="md"
                        headingLevel="h2"
                        className={styles.Text}
                    >
                        {title}
                    </Heading>
                </div>
                <RichText className={styles.Text} content={paragraph}/>
            </div>
	)
};