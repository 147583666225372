import classNames from 'classnames';
import { SubNavigation } from '../SubNavigation/SubNavigation';
import { useEffect, useRef, useState } from 'react';
import { ServiceNavigation, LanguageSelector, Search } from 'ui/components';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { ReactComponent as IconClose } from '../../../../icons/icon_close_cross_small.svg';
import { ReactComponent as IconMenu } from 'ui/icons/icon_menu.svg';
import { ReactComponent as IconSearch } from 'ui/icons/icon_search_small.svg';
import styles from './MobileNavigation.module.scss';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';

export interface MobileNavigationProps {
	globalNavigation?: Navigation.NavigationItem[];
	currentPageId: number;
	openMenuDefault?: boolean;
	openSearchDefault?: boolean;
	serviceNavigationItems?: Navigation.NavigationItem[];
	hideServiceNavigation: boolean;
	searchPage?: Content.Link;
	languageSelector?: Content.Link[];
	currentLanguage?: string;
	isFrontPage: boolean;
	isInternalLanguageSelector?: boolean;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
	globalNavigation,
	currentPageId,
	openMenuDefault = false,
	openSearchDefault = false,
	hideServiceNavigation,
	serviceNavigationItems,
	languageSelector,
	searchPage,
	currentLanguage,
	isFrontPage,
	isInternalLanguageSelector = false,
}) => {
	const wrapperRef = useRef(null);
	const dictionary = useDictionaryContext();
	const pageContext = usePageContext();
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(openMenuDefault);
	const [isSearchOpen, setIsSearchOpen] = useState<boolean>(openSearchDefault);
	const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState<boolean>(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};
	const closeMenu = () => {
		setIsMenuOpen(false);
	};

	const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
	const closeSearch = () => setIsSearchOpen(false);

	useEffect(() => {
		if (isMenuOpen) disableBodyScroll(wrapperRef.current);
		if (!isMenuOpen) enableBodyScroll(wrapperRef.current);

		return () => clearAllBodyScrollLocks();
	}, [isMenuOpen]);

	useEffect(() => {
		setIsSearchOpen(false);
	}, [pageContext.url]);

	const onEnterEventUp = (event) => {
		if (event.keyCode === 13) {
			closeSearch();
		}
	};

	const onEnterEvent = (event) => {
		if (event.keyCode === 13) {
			setIsSearchOpen(!isSearchOpen);
		}
	};

	const onEnterEventNavigation = (event) => {
		if (event.keyCode === 13) {
			toggleMenu();
		}
	};

	return (
		<div className={classNames(styles.MobileNavigation)}>
			<div className={classNames(styles.MobileNavigation_icons)}>
				<IconMenu tabIndex={0} onKeyDown={onEnterEventNavigation} onClick={toggleMenu} />
				<IconSearch
					tabIndex={0}
					onClick={toggleSearch}
					onKeyDown={onEnterEvent}
					className={classNames({ [styles.MobileNavigation_searchIcon___isFrontPage]: isFrontPage })}
				/>
			</div>

			<CSSTransition appear={true} classNames={{ ...styles }} timeout={300} in={isSearchOpen ? isSearchOpen : false}>
				<div className={classNames(styles.MobileNavigation_search)}>
					<div className={classNames(styles.MobileNavigation_searchCloseIcon)}>
						<IconClose id="headerSearchClose" tabIndex={0} onClick={closeSearch} onKeyDown={onEnterEventUp} />
					</div>
					<Search
						searchPage={searchPage}
						searchButtonText={dictionary.getValue('Search.Search', null, 'Søg')}
						searchInputPlaceholder={dictionary.getValue('Search.EnterKeyword', null, 'Indtast din søgning her')}
						requiredFieldErrorMessage={dictionary.getValue('Search.RequiredField', null, 'Feltet er påkrævet')}
						//searchResultText={dictionary.getValue('Search.Results', null, 'Resultater')}
						//searchErrorText={dictionary.getValue('Search.ErrorText', [], 'Der er opstået en fejl i søgningen. Prøv venligst')}
					/>
				</div>
			</CSSTransition>

			<CSSTransition appear={true} classNames={{ ...styles }} nodeRef={wrapperRef} timeout={300} in={isMenuOpen ? isMenuOpen : false}>
				<div className={styles.MobileNavigation_wrapper} ref={wrapperRef}>
					<div
						className={classNames(styles.MobileNavigation_toolsDrawer, {
							[styles.MobileNavigation_toolsDrawer___isActive]: isLanguageSelectorOpen,
						})}
					>
						<button className={styles.MobileNavigation_toggle} onClick={toggleMenu} aria-label="Close">
							<IconClose aria-hidden="true" />
						</button>
						<LanguageSelector
							currentLanguage={currentLanguage}
							className={styles.MobileNavigation_languageSelector}
							languages={languageSelector}
							setIsLanguageSelectorOpen={setIsLanguageSelectorOpen}
						/>
					</div>

					<div className={styles.MobileNavigation_menu}>
						<SubNavigation isMobile={true} currentPageId={currentPageId} items={globalNavigation} navId={10} closeMenu={closeMenu} />
							<ServiceNavigation
								hideServiceNavigation={hideServiceNavigation}
								serviceNavigationItems={serviceNavigationItems}
								closeMenu={closeMenu}
							/>
					</div>
				</div>
			</CSSTransition>
		</div>
	);
};
