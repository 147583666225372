import React from 'react';
import { MapWrapper } from './MapWrapper';
import { MapMarker, MapMarkerProps } from './MapMarker';

export interface MapProps {
	zoom?: number;
	apiKey?: string;
	mapMarkers: MapMarkerProps[];
	width?: string;
	height?: string;
	cluster?: boolean;
}

export const Map: React.FC<MapProps> = ({ apiKey, mapMarkers, zoom = 1, cluster = false, width, height }) => {
	const style = {
		height: height === undefined ? '100%' : height,
		width: width === undefined ? '100%' : width,
	};

	return (
		<>
			<MapWrapper apiKey={apiKey} cluster={cluster} zoom={zoom} style={style}>
				{mapMarkers.map((mapMarker) => {
					const { title, text } = mapMarker ?? {};

					return (
						<MapMarker key={title} {...mapMarker}>
							<>
								<h1>{title}</h1>
								{text && (
									<div
										dangerouslySetInnerHTML={{
											__html: text,
										}}
									/>
								)}
							</>
						</MapMarker>
					);
				})}
			</MapWrapper>
		</>
	);
};
