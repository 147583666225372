import classNames from 'classnames';
import React, { useEffect, useRef, useState} from 'react';
import { useRouter } from 'next/router'
import { Container} from 'ui/components';
import styles from './CludoConcernSearch.module.scss';

export interface CludoConcernSearchProps {
	className?: string;
	filterValues?: string;
	searchFieldHeading?: string;
	cludoEngineId?: string;
}

export const CludoConcernSearch: React.FC<CludoConcernSearchProps> = ({ searchFieldHeading, filterValues, cludoEngineId }) => {
	const [state, setState] = useState(false);
	const router = useRouter();
	const scriptRef = useRef<HTMLDivElement | null>(null);
	const scriptBlock = `
		<script>function callback() {
			
			var myScript = document.createElement('script');
			myScript.type = 'text/javascript';
			myScript.src = 'https://customer.cludo.com/assets/3030/12934/cludo-helper.js';
			document.body.appendChild(myScript);
		}</script>
		
		<script onLoad='callback()' type="text/javascript" src="https://customer.cludo.com/scripts/bundles/search-script.min.js"></script>
        <script>
            var cludo_engineIdTop = "${cludoEngineId}"; //Engine ID
            var cludo_searchUrlTop = '/'; //Search URL - Ãndr denne til at afspejle søgesiden for den aktuelle koncern/hospital.
            var cludo_searchFieldsTop = ['#cludo-search-input', '#cludo-nav-search-mobile','cludo-nav-search']; // Tilføj eller fjern ID'er eller klasser til at passe med den aktuelle søgning der skal foretages fra toppen og dennes søgesides input.  
            var cludo_language = 'da'; //Sprog
			var cludo_filterKey = 'HospitalName'
            var cludo_filterValue = "${filterValues}"; // Hospital eller side filter.

        </script>
	`;

	useEffect(() => {
		const fragment = document.createRange().createContextualFragment(scriptBlock);
		scriptRef?.current?.append(fragment);
	}, [scriptBlock, scriptRef,router.asPath]);


	return (
		<Container width="Standard">
			<div className='u-hidden-in-print'>
				<header className="cludo-search-top-container">

					<div className={classNames("cludo-input-form-container", styles.CludoConcertSearch_formContainer)}>
						<h3>{searchFieldHeading}</h3>
						<div className="cludo-input-form" role="search" id="cludo-search-input">
							<input className="cludo-input-form__input" title="Search the page" name="searchrequest" type="text" placeholder="Hvad søger du?" autoComplete="off" aria-autocomplete="list" aria-haspopup="true" />
							<button type="submit" title="Search" className="cludo-input-form__search-button">
								Søg
								<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<ellipse cx="14.0016" cy="8.30435" rx="7.63636" ry="7.30435" stroke="#00809C" strokeWidth="1.5"></ellipse>
									<path d="M9.14545 13.993L2 20.8278" stroke="#00809C" strokeWidth="1.5" strokeLinecap="square"></path>
								</svg>
							</button>
						</div>
					</div>
				</header>
			</div>


			<div>
				<div id="cludo-search-results" className="cludo-search-results cludo-search-koncern">
					<div className="cludo-search-results__layout">
						<div className="cludo-search-results__facets search-filters" aria-controls="search-results"></div>
						<div className="cludo-search-results__results-wrapper">
							<div className="cludo-search-results__search-result-count search-result-count" role="status"></div>
							<div className="cludo-search-results__did-you-mean search-did-you-mean"></div>
							<div className="cludo-search-results__results search-results" role="region" id="search-results" aria-live="polite" aria-label="Search results"></div>
						</div>
					</div>
				</div>

			</div>

			<div ref={scriptRef}></div>

		</Container>
	);
};
