import { Heading, Container, PaginationReact, Link, VideoConsent } from 'ui/components';
import { postVideoListTags } from 'application/repositories/videoListTagsRepository';
import styles from './VideoListTags.module.scss';
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { usePageContext } from 'application/contexts/PageContext';

export interface VideoListTagsProps {
	tags?: string;
	heading?: string;
	maxItemsOnPage?: number;
	postVideoListTags?: (params: any) => Promise<Models.VideoListTags>;
}

export const VideoListTags: React.FC<VideoListTagsProps> = ({ heading, tags, maxItemsOnPage }) => {
	const context = usePageContext();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [itemsPerPage, setItemsPerPage] = useState(24);
	const [itemOffset, setItemOffset] = useState(0);
	const preview = context.preview;
	const previewData = context.previewData;

	//const {data, error} =  useSWR({tags, itemOffset, itemsPerPage, maxItemsOnPage, preview, previewData}, postVideoListTags);
	const {data, error} =  useSWR({tags:tags, itemOffset:itemOffset, itemsPerPage:itemsPerPage, preview, previewData}, postVideoListTags);

	useEffect(() => {
		if (data) {
			setIsLoading(false)
		}

	}, [data]);

	const handlePageClick = (event) => {
		setItemOffset(event.selected)
	};

	return error ? (<></>):(
		<div className={styles.VideoListTag}>
			<Container width="Standard">
				{isLoading &&
					<p>Loading...</p>
				}
				{data && (
					<>
						{heading &&
							<Heading headingLevel="h2" style="lg" className={styles.VideoListTag_heading} hasHTML={true}>
								{heading}
								<span className={styles.VideoListTag_totalVideos}>{`(${data?.totalCount})`}</span>
							</Heading>
						}
						<PaginationReact paginationItems={data?.videos} itemsPerPage={data?.pageSize} handlePageClick={handlePageClick} selectedPage={data?.pageNumber} pageCount={data?.totalPages} />
						
						<div className={styles.VideoListTag_list}>
							{data.videos &&
								data.videos.map((video, index) => (
									<div className={styles.VideoList_listMediaContainer} key={index}>
										<VideoConsent key={index} video={video.videoEmbed } />
										<Link ariaLabel={video.title} className={styles.VideoList_listLink} url={video.link}>{video.title}</Link>
									</div>
							))}
						</div>
					</>
				)}
				<PaginationReact paginationItems={data?.videos} itemsPerPage={data?.pageSize} handlePageClick={handlePageClick} selectedPage={data?.pageNumber} pageCount={data?.totalPages} />
			</Container>
		</div>
	);
};
