import classNames from 'classnames';
import styles from './FormfieldString.module.scss';
import { ErrorMessage, Label } from 'ui/components';

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
};

export interface FormfieldStringProps {
	className?: string;
	defaultValue?: string;
	id: string;
	autoComplete?: string;
	label?: string;
	name?: string;
	placeholder?: string;
	register?: any;
	errorMessage?: string;
	state?: State;
	type: 'text' | 'email' | 'search' | 'number' | 'password' | 'tel' | 'url' | 'date';
	min?: number;
	max?: number;
	pattern?: string;
	value?: string;
	onChange?: (e: any) => void;
	onKeyDown?: (e: any) => void;
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, type: string, id: string) => {
	if (state?.['hasError']) return type + id;
};

export const FormfieldString: React.FC<FormfieldStringProps> = ({
	className,
	state,
	id,
	autoComplete,
	label,
	type,
	register,
	placeholder,
	name,
	defaultValue,
	min,
	max,
	pattern,
	errorMessage,
	value,
	onChange,
	onKeyDown
}) => {
	const { required, disabled, hasError, isHidden } = state ?? {};

	return (
		<div className={classNames(styles.FormfieldString, className, { [styles.hasError]: hasError }, { [styles.isDisabled]: disabled })}>
			{!isHidden && (
				<Label id={id} className={styles.FormfieldString_label} state={state}>
					{label}
				</Label>
			)}
			<input
				type={type}
				className={classNames(styles.FormfieldString_input, styles.Input___string, {
					[styles.hasError]: hasError,
				})}
				name={name}
				id={id}
				disabled={disabled}
				placeholder={placeholder}
				defaultValue={defaultValue}
				aria-label={ariaLabel(state, label)}
				aria-describedby={ariaError(state, type, id)}
				autoComplete={autoComplete}
				min={min}
				max={max}
				pattern={pattern}
				{...(register && { ...register(name, { required }) })}
				value={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
			{hasError && <ErrorMessage id={type + id}>{errorMessage}</ErrorMessage>}
		</div>
	);
};
