import React from 'react';
import classNames from 'classnames';
import styles from './Heading.module.scss';
import DOMPurify from 'isomorphic-dompurify';

export interface HeadingProps {
	children?: React.ReactNode;
	className?: string;
	hasHTML?: boolean;
	headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	style?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xlMaxWidth';
	color?: string;
}

export const Heading: React.FC<HeadingProps> = ({ children, className, headingLevel, style, color, hasHTML = false }) => {
	const headingText = DOMPurify.sanitize(children as string,  {ALLOWED_TAGS: ['&shy;']});
		// if heading contains HTML we cannot use DOMPurify...
		if(hasHTML) {
			return React.createElement(
				headingLevel,
				{ className: classNames(styles.Heading, styles[`Heading___${style}`], className), style: { color: color } },
				children
			);
		} else {
			return React.createElement(
				headingLevel,
				{ className: classNames(styles.Heading, styles[`Heading___${style}`], className), style: { color: color } },
				<span dangerouslySetInnerHTML={{ __html: headingText }} />
			);
		}
		
	};
