import classNames from 'classnames';
import { Icon } from 'ui/components';
import styles from './Button.module.scss';

export interface ButtonProps {
	children?: React.ReactNode;
	style?: 'primary' | 'primarySmall' | 'secondary' | 'secondarySmall';
	title?: string;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	onClick?: (e) => void;
	onKeyDown?: (e) => void;
	icon?: any;
	ref?: any;
	ariaLabel?: string;
}

export const Button: React.FC<ButtonProps> = ({ children, style, title, className, type, disabled, onClick, icon, ref, ariaLabel }) => (
	<button
		type={type || 'button'}
		title={title}
		className={classNames(styles.Button, styles[`Button___${style}`], className)}
		disabled={disabled}
		aria-disabled={disabled}
		onClick={onClick}
		ref={ref}
		aria-label={ariaLabel}
	>
		<span>{children}</span>
		{icon}
	</button>
);
