import classNames from 'classnames';
import styles from './Logo.module.scss';
import { Icon, Link } from 'ui/components';

export interface LogoProps {
	className?: string;
	heading?: string;
	logo?: Content.Image;
	logoLink?: Content.Link;
	centered?: boolean;
	size?: 'sm' | 'md' | 'lg' | 'xl' | 'none';
}

export const Logo: React.FC<LogoProps> = ({ className, logo, logoLink, centered, size = 'xl' }) => (
	<Link ariaLabel={logoLink?.name} url={logoLink?.url && logoLink.url} className={classNames(styles.Logo, className)}>
		<Icon className={classNames(styles.Logo_asset, { [styles.Logo_asset___centered]: centered })} size={size ? size : 'xl'}>
			<img src={logo?.url} alt={logo?.properties.altText} className={styles.Logo_logo} aria-hidden="true" />
		</Icon>
	</Link>
);
