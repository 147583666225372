import classNames from 'classnames';
import styles from './HeroFrontpage.module.scss';
import { Heading, Paragraph, Container, Picture, PictureProps, VideoConsent } from 'ui/components';
import { useEffect, useState } from 'react';

export interface HeroFrontpageProps {
	className?: string;
	backgroundImage?: PictureProps;
	heading: string;
	description: string;
	isTextBlack: boolean;
	video?: string;
	removeAlt?: boolean;
	isSectionEabled?: boolean
}

export const HeroFrontpage: React.FC<HeroFrontpageProps> = ({ 
	className, 
	backgroundImage, 
	heading, 
	description, 
	isTextBlack, 
	removeAlt,
	video,
	isSectionEabled
}) => {
	
	console.log("section", isSectionEabled);
	
	return (
		<div className={classNames(styles.HeroFrontpage, className, 
		{ [styles.HeroFrontpage___noImage]: backgroundImage === null ? true : false },
		{ [styles.HeroFrontpage___isTextBlack]: isTextBlack},
		{ [styles.HeroFrontpage___isContainingVideo]:  (video && video.length > 0)})}>
			{backgroundImage && !video && (
				<>
					<div className={classNames(styles.HeroFrontpage_backgroundImage)}>
						<Picture {...backgroundImage} removeAlt={removeAlt} />
					</div>
					<div className={classNames(styles.HeroFrontpage_overlay)} />
				</>
			)}

			{video && !backgroundImage && (
				<div className={styles.HeroFrontpage_videoContainer}>
					<VideoConsent
					style={{ backgroundColor: `var(--theme-30)` }} 
					video={video} />
				</div>
			)}

			{/* IF BOTH IMAGE AND VIDEO EXISTS, SHOW ONLY VIDEO */}
			{backgroundImage && video && (
				<div className={styles.HeroFrontpage_videoContainer}>
					<VideoConsent
					style={{ backgroundColor: `var(--theme-30)` }} 
					video={video} />
				</div>
			)}
			
			<Container width="Standard">
				<div className={classNames(styles.HeroFrontpage_content, 
					{ [styles.HeroFrontpage_content___isSection]: isSectionEabled },
					{ [styles.HeroFrontpage_content___isContainingVideo]: (video && video.length > 0) })}>
					<Heading
						style="xl"
						headingLevel="h1"
						className={classNames(styles.HeroFrontpage_heading, { [styles.HeroFrontpage_heading___isTextBlack]: isTextBlack })}
					>
						{heading}
					</Heading>
					<Paragraph
						style="manchet"
						className={classNames(styles.HeroFrontpage_description, { [styles.HeroFrontpage_description___isTextBlack]: isTextBlack })}
					>
						<span dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br>')  }}></span>
					</Paragraph>
				</div>
			</Container>
		</div>
	);
};
