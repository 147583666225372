import { Container } from 'ui/components';
import Script from 'next/script';

export const PressRelease: React.FC = () => {
	return (
		<Container width="Standard" className="PressRelease">
			<div id="embedded-pressroom" data-publisher="13560143"></div>
			<Script type="text/javascript" src="https://via.ritzau.dk/embedded/prs_embedded.js"></Script>
		</Container>
	);
};

