import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as ChevronUp } from 'ui/icons/icon-chevron-up.svg';
import styles from './LanguageSelector.module.scss';
import { useRouter } from 'next/router';
import { Link } from 'ui/components';

export interface LanguageSelectorProps {
	className?: string;
	languages?: Content.Link[];
	setIsLanguageSelectorOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	currentLanguage?: string;
	isFrontPage?: boolean;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
	className,
	languages,
	setIsLanguageSelectorOpen,
	currentLanguage,
	isFrontPage = false,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const router = useRouter();

	const toggleOpen = () => {
		setIsOpen(!isOpen);
		if (setIsLanguageSelectorOpen != undefined) {
			setIsLanguageSelectorOpen(!isOpen);
		}
	};

	const handleSelectedOption = (selectedLanguage) => {
		setIsOpen(false);
		if (setIsLanguageSelectorOpen != undefined) {
			setIsLanguageSelectorOpen(false);
		}

		router.push(selectedLanguage.url, selectedLanguage.url, { locale: selectedLanguage.isoCode });
	};

	const onEnterEvent = (event) => {
		if (event.keyCode === 13) {
			toggleOpen();
		}
	};

return languages.length > 1 ? (
		<div className={classNames(styles.LanguageSelector, { [styles.LanguageSelector___isFrontPage]: isFrontPage }, className)}>
			<div
				tabIndex={0}
				className={classNames(styles.LanguageSelector_toggle, {
					[styles.isOpen]: isOpen,
				})}
				onClick={toggleOpen}
				onKeyDown={onEnterEvent}
			>
				<span>{currentLanguage}</span>
				<div>
					<ChevronUp className={classNames(styles.LanguageSelector_icon, { [styles.LanguageSelector_icon___isFrontPage]: isFrontPage })} />
				</div>
			</div>
			<ul role="list" 
				className={classNames(styles.LanguageSelector_wrapper, {
					[styles.isOpen]: isOpen,
				})}
			>
				{languages &&
					languages.map((language, index) => {
						if (language.name === currentLanguage) {
							return false;
						} else {
							return (
								<li role="listitem">
									<Link
										ariaLabel={language.name}
										className={classNames(styles.LanguageSelector_link, { [styles.LanguageSelector_link___isFrontPage]: isFrontPage })}
										key={index}
										url={language.url}
										clickHandler={() => handleSelectedOption(language)}
									>
										{language.name}
									</Link>
								</li>
							);
						}
					})}
			</ul>
		</div>
	) : (
		<></>
	);
};
