import React, { useEffect, useRef } from 'react';
import styles from './CludoFindDirection.module.scss';
import { Picture, Container } from 'ui/components';
import { useRouter } from 'next/router'

export interface CludoFindDirectionProps {
	className?: string;
	filterValue?: string;
	cludoEngineId?: string;
	searchFieldHeading?: string;
	image?: Content.Image;
}

export const CludoFindDirection: React.FC<CludoFindDirectionProps> = ({ searchFieldHeading, filterValue, image, cludoEngineId }) => {
	const scriptRef = useRef<HTMLDivElement | null>(null);
	const scriptBlock = `
		<script>function callback() {
			
			var myScript = document.createElement('script');
			myScript.type = 'text/javascript';
			myScript.src = 'https://customer.cludo.com/assets/3030/12934/cludo-helper.js';
			document.body.appendChild(myScript);
		}</script>
		
		<script onLoad='callback()' type="text/javascript" src="https://customer.cludo.com/scripts/bundles/search-script.min.js"></script>
        <script>
            var cludo_engineIdTop = "${cludoEngineId}"; //Engine ID
            var cludo_searchUrlTop = '/koncern.html'; //Search URL - Ãndr denne til at afspejle søgesiden for den aktuelle koncern/hospital.
            var cludo_searchFieldsTop = ['#cludo-search-input', '#cludo-nav-search-mobile','cludo-nav-search']; // Tilføj eller fjern ID'er eller klasser til at passe med den aktuelle søgning der skal foretages fra toppen og dennes søgesides input.  
            var cludo_visitkort = "FindVej"
            var cludo_language = 'da'; //Sprog
			var cludo_filterKey= "PlanId";
            var cludo_filterValue = "${filterValue}"; // Hospital eller side filter.
			var cludo_engineIdSubsearch = 13116; // Engine id på; liste søgning
            var cludo_searchUrlSubsearch = '/'; //Search URL - change this to reflect the actual search URL
        </script>
	`;

	const reset = () => {
		// @ts-ignore
		clearSearch();
	};

	const router = useRouter();

	useEffect(() => {
		/**
		 * To prevent Cludo from adding a new entry to the history when setting the hash
		 * we preset the url to include the hash that Cludo would otherwise have added
		 * and use the replace method from our router
		*/
		const url = `#?cludoquery=*&cludoPlanId=${filterValue}&cludopage=1&cludoinputtype=standard`
		router.replace(url);
	}, []);

	useEffect(() => {
		const fragment = document.createRange().createContextualFragment(scriptBlock);
		scriptRef?.current?.append(fragment);
	}, [scriptBlock, scriptRef]);

	return (
		<Container width="Standard">
			<div className="u-hidden-in-print">
				<header className="cludo-search-top-container">
					<div className="cludo-input-form-container">
						<h3>{searchFieldHeading}</h3>

						<div className="cludo-input-form" role="search" id="cludo-search-input-subsearch">
							<input
								className="cludo-input-form__input"
								title="Search the page"
								name="searchrequest"
								type="text"
								placeholder="indtast din søgning"
								autoComplete="off"
								aria-autocomplete="list"
								aria-haspopup="true"
							/>
							<button type="submit" title="Search" className="cludo-input-form__search-button">
								Søg
								<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<ellipse cx="14.0016" cy="8.30435" rx="7.63636" ry="7.30435" stroke="#00809C" strokeWidth="1.5"></ellipse>
									<path d="M9.14545 13.993L2 20.8278" stroke="#00809C" strokeWidth="1.5" strokeLinecap="square"></path>
								</svg>
							</button>
							<button className="cancel-search" onClick={reset} tabIndex={0}>Nulstil</button>
						</div>
					</div>
				</header>
			</div>

			

			<div className="">
				<div id="cludo-search-results" className="cludo-search-results cludo-search-findvej">
					<div className="cludo-search-results__layout">
						<div className="cludo-search-results__facets search-filters" aria-controls="search-results"></div>
						<div className="cludo-search-results__results-wrapper">
							<div className="cludo-search-results__search-result-count search-result-count" role="status"></div>
							<div className="cludo-search-results__did-you-mean search-did-you-mean"></div>
							<div
								className="cludo-search-results__results search-results"
								role="region"
								id="search-results"
								aria-live="polite"
								aria-label="Search results"
							></div>
						</div>
					</div>
				</div>
			</div>

			{image && (
				<div className={styles.CludoFindDirection_mediabox}>
					<Picture
						isCover={true}
						focalPoint={image.focalPoint}
						url={image.url}
						altText={image.altText}
						sizes="100vw"
						className={styles.ArticleMedia_mediabox}
					/>
				</div>
			)}

			<div ref={scriptRef}></div>
		</Container>
	);
};
