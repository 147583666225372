import classNames from 'classnames';
import { Heading, Paragraph, Container, Picture } from 'ui/components';
import styles from './VideoTeaser.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as IconNext } from 'ui/icons/icon_btn-right.svg';
import { ReactComponent as IconPrev } from 'ui/icons/icon_btn-left.svg';
import { Link } from 'ui/components';
import PlaceholderImg from '../../../../../public/media/placeholder.png';

export interface VideoTeaserProps {
	className?: string;
	heading?: string;
	headingLink?: Content.Link;
	manchet?: string;
	backgroundColor?: string;
	videoTeaserList?: { properties: { videoEmbed?: string; videoTitle?: string; videoThumbnailUrl?: string }; url?: string; name?: string }[];
}

export const VideoTeaser: React.FC<VideoTeaserProps> = ({ className, heading, headingLink, manchet, videoTeaserList, backgroundColor }) => {
	const [videoCardsAmount, setVideoCardsAmount] = useState<number>();

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	useEffect(() => {
		if (videoTeaserList && videoTeaserList.length <= 4) {
			setVideoCardsAmount(videoTeaserList.length);
		} else {
			setVideoCardsAmount(4);
		}
	}, []);

	return (
		<div
			className={classNames(styles.VideoTeaser, className)}
			style={{
				backgroundColor: backgroundColor,
			}}
		>
			<Container width="Standard">
				{heading && (
					<Heading
						headingLevel="h2"
						style="md"
						className={classNames(styles.VideoTeaser_heading, { [styles.VideoTeaser_heading___link]: headingLink != null })}
					>
						{headingLink ?  
							<Link ariaLabel={heading} url={headingLink.url} target={headingLink.target} title={headingLink.title}>{heading}</Link>
							: heading
						}
					</Heading>
				)}

				{manchet && (
					<Paragraph style="lg" className={classNames(styles.VideoTeaser_description)}>
						<span dangerouslySetInnerHTML={{ __html: manchet.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
					</Paragraph>
				)}

				{videoTeaserList && (
					<>
						{/* MOBILE */}
						<div className={classNames(styles.VideoTeaser_videoCards, styles.VideoTeaser_videoCards___mobile)}>
							<Swiper
								slidesPerView={1.15}
								pagination={{
									clickable: true,
									type: 'bullets',
								}}
							>
								{videoTeaserList.map((videoCard, index) => (
									<SwiperSlide key={index}>
										<div className={classNames(styles.VideoTeaser_videoCard, styles.VideoTeaser_videoCard___mobile)}>
											<Link url={videoCard?.url} ariaLabel={videoCard.properties.videoTitle}>
												{videoCard.properties.videoThumbnailUrl ? (
													<Picture
														url={videoCard.properties.videoThumbnailUrl}
														className={styles.VideoTeaser_mediaBox}
														altText={videoCard.properties.videoTitle}
													/>
												) : (
													<Picture url={PlaceholderImg.src} className={styles.VideoTeaser_mediaBox} altText="placeholder" />
												)}

												<Paragraph style="manchet" className={styles.VideoTeaser_videoCardHeading}>
													{videoCard.properties.videoTitle}
												</Paragraph>
											</Link>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>

						{/* DESKTOP */}
						<div className={classNames(styles.VideoTeaser_videoCards, styles.VideoTeaser_videoCards___desktop)}>
							<Swiper
								slidesPerView={videoCardsAmount}
								navigation={{
									prevEl: navigationPrevRef.current,
									nextEl: navigationNextRef.current,
								}}
							>
								{videoTeaserList.map((videoCard, index) => (
									<SwiperSlide key={index} className={styles.swiperCustom}>
										<div className={classNames(styles.VideoTeaser_videoCard, styles.VideoTeaser_videoCard___desktop)}>
											<Link url={videoCard?.url} ariaLabel={videoCard.properties.videoTitle}>
												{videoCard.properties.videoThumbnailUrl ? (
													<Picture
														url={videoCard.properties.videoThumbnailUrl}
														className={styles.VideoTeaser_mediaBox}
														altText={videoCard.properties.videoTitle}
													/>
												) : (
													<Picture url={PlaceholderImg.src} className={styles.VideoTeaser_mediaBox} altText="placeholder" />
												)}

												<Paragraph style="manchet" className={styles.VideoTeaser_videoCardHeading}>
													{videoCard.properties.videoTitle}
												</Paragraph>
											</Link>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
							<button
								className={classNames(styles.VideoTeaser_navigation, styles.VideoTeaser_navigation___prev)}
								aria-label="Forrige"
								ref={navigationPrevRef}
							>
								<IconPrev aria-hidden="true" />
							</button>

							<button
								className={classNames(styles.VideoTeaser_navigation, styles.VideoTeaser_navigation___next)}
								aria-label="Næste"
								ref={navigationNextRef}
							>
								<IconNext aria-hidden="true" />
							</button>
						</div>
					</>
				)}
			</Container>
		</div>
	);
};
