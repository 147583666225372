import { LinkButton } from '../LinkButton';
import { Container } from 'ui/components/4-habitats';
import styles from './CtaButton.module.scss';
import classNames from 'classnames';

export interface CtaButtonProps{
	title: string,
	link: Content.Link
	position: string
}

export const CtaButton: React.FC<CtaButtonProps> = ({title, link, position}) => {
	return (<Container width={''} className={classNames(styles.ctaButtonContainer, styles[`ctaButtonContainer__${position}`])}>
				<LinkButton
					url={link.url} 
					ariaLabel={link.name}
					style='primary'
					className={classNames(styles.CtaButton)}>
						{title}
					</LinkButton>
			</Container>);
};

