import { axiosInstance as axios } from 'helpers/axiosInstance';
import { useState } from 'react';
import getConfig from 'next/config';
const {publicRuntimeConfig} = getConfig()

export const postVideoListTagsData: (tags: string, pageNumber: number, pageSize: number,) => Promise<Models.VideoListTags> = async (tags, pageNumber, pageSize): Promise<Models.VideoListTags>=> {
	const method = 'POST';
	const headers = {
		'Content-Type': 'application/json',
	};
	const videoTagsApiUrl = `${publicRuntimeConfig.NEXT_PUBLIC_API_URL}api/VideoTags/GetList`;

	const endpointUrl = `${videoTagsApiUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}`;

	return axios({
		url: endpointUrl,
		method: method,
		headers,
		data: tags,
	}).then((res) => {
		const { data } = res;
		if (!data) {
			const mappedData:Models.VideoListTags = {
				videos: [],
				totalCount: 0,
				pageNumber: 0,
				pageSize: 0,
				totalPages: 0,
			};
			return mappedData;
		}
		const mappedData:Models.VideoListTags = {
			pageNumber:pageNumber,
			totalCount:data.totalCount || 0,
			videos: data.videos || [],
			pageSize:data.pageSize || 0,
			totalPages:data.totalPages || Math.ceil(data.totalCount / data.pageSize)
		}
		return mappedData;
	});
};
