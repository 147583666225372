import { LinkButton, LinkButtonProps, LinkProps } from 'ui/components';
import { usePageContext } from 'application/contexts/PageContext';
import { useLocaleContext } from 'application/contexts/LocaleContext';
import NextLink from 'next/link';
import { useEffect } from 'react';
export const LinkButtonFeature: React.FC<LinkButtonProps> = ({ style,  className, url, children, target, title, ariaLabel }) => {
	const { currentLocale } = useLocaleContext();
    const pageContext= usePageContext();
	const LinkWrapper:any = ({ href, children }) => (
		<NextLink prefetch={false} href={`${href}`} locale={currentLocale}>
			{children}
		</NextLink>
	);
    useEffect(()=>{},[pageContext.url,currentLocale])
/* 	function LinkWrapperElement({ wrappedUrl, children }): JSX.Element {
		return LinkWrapper ? <LinkWrapper href={wrappedUrl}>{children}</LinkWrapper> : <>{children}</>;
	} */
	return (
/* 		<LinkWrapperElement wrappedUrl={url}> */
			<LinkButton
				className={className}
				style={style}
				target={target}
				title={title}
				url={url}
                LinkWrapper={LinkWrapper}
				ariaLabel={ariaLabel}
			>
				{children}
			</LinkButton>
	/* 	</LinkWrapperElement> */
	);
};
