import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { SiteContextProvider } from 'application/contexts/SiteContext';
import { DictionaryContextProvider } from 'application/contexts/DictionaryContext';
import { PageContextProvider } from 'application/contexts/PageContext';
import { NavigationContextProvider } from 'application/contexts/NavigationContext';
import { ChildrenContentContextProvider } from 'application/contexts/ChildrenContentContext';
import 'ui/styles/globals.scss';
import { StrictMode } from 'react';
import { NextPage } from 'next';
import { LocaleContextProvider } from 'application/contexts/LocaleContext';
import { Http2ServerRequest } from 'http2';

/**
 * The `DXPContentPage` type is the result of _app.tsx's modifications to page props.
 */
export type DXPContentPage<TProps extends { content?: Content.PageContent }> = NextPage<TProps['content'] & Omit<TProps, 'content' | 'session'>>;
export type DXPContentPage2<TProps extends { childrenContent?: Content.PageContent }> = NextPage<
	TProps['childrenContent'] & Omit<TProps, 'childrenContent' | 'session'>
>;

const App: React.FC<NextAppProps> = ({ Component, pageProps }) => {
	const { content, dictionary, navigation, session, site, childrenContent, locale } = pageProps ?? {};
	const { page } = content ?? {};
	const { culture } = page ?? {};
	return (
		<>
			<Head>
				<meta name="viewport" key="viewport" content="width=device-width, initial-scale=1" />
				<meta name="google-site-verification" content="mQx09qjgvcHWQysgO1umH0_FZCNpgmb6hli-scy2iYY" />
			</Head>
			<StrictMode>
				<LocaleContextProvider initialLocale={locale}>
					<SiteContextProvider site={site}>
						<DictionaryContextProvider dictionary={dictionary ?? []} culture={culture}>
							<PageContextProvider content={content}>
								<NavigationContextProvider navigation={navigation}>
									{/*<ChildrenContentContextProvider childrenContent={childrenContent}> */}
										{/* TODO: Consider refactor to pass all pageProps to Component to follow idiomatic Next.js and avoid future prop naming collisions. */}
										<Component {...content} {...pageProps} />
									{/* </ChildrenContentContextProvider> */ }
								</NavigationContextProvider>
							</PageContextProvider>
						</DictionaryContextProvider>
					</SiteContextProvider>
				</LocaleContextProvider>
			</StrictMode>
		</>
	);
};

export default App;
