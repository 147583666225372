import classNames from 'classnames';
import styles from './SubsiteBar.module.scss';
import { Container, Grid, GridCell, Icon, Link } from 'ui/components';

export interface SubsiteBarProps {
	className?: string;
	subsiteBarLogo?: Content.Image;
	subsiteBarLogoLink?: Content.Link;
	hideSubsiteBar?: boolean;
}

export const SubsiteBar: React.FC<SubsiteBarProps> = ({ className, subsiteBarLogo, subsiteBarLogoLink, hideSubsiteBar }) => {
	if (hideSubsiteBar) return null;
	return (
		<div className={classNames(styles.SubsiteBar, className)} role="banner">
			<Container width="Full width" className={styles.SubsiteBarContainer}>
				<Grid nogutter={true} center={false}>
					<GridCell mobileWidth="10" desktopWidth="10">
						{subsiteBarLogoLink &&
							<Link ariaLabel={subsiteBarLogoLink.name} tabIndex={0} url={subsiteBarLogoLink?.url && subsiteBarLogoLink.url} target={subsiteBarLogoLink?.target && subsiteBarLogoLink.target}>
								<Icon className={styles.Subsitebar_asset} size="xl">
									<img src={subsiteBarLogo?.url} alt={subsiteBarLogo?.properties.altText} aria-hidden="true" />
								</Icon>
							</Link>
						}
						{!subsiteBarLogoLink &&
							<Icon className={styles.Subsitebar_asset} size="xl">
								<img src={subsiteBarLogo?.url} alt={subsiteBarLogo?.properties.altText} aria-hidden="true" />
							</Icon>
						}
					</GridCell>
				</Grid>
			</Container>
		</div>
	);
};
