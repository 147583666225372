import config from 'helpers/imageSizes';
 
interface GetImageUrlProps {
    url: string;
    focalPoint?: {
        left?: number;
        top?: number;
    };
    width?: number;
    height?: number;
    format?: 'webp';
}
 
export const GetImageUrl = ({ url, focalPoint, width, height = 0, format }: GetImageUrlProps): string => {
    if (!url) return '';
 
    let imageUrl = `${url}?width=${width}`;
 
    if (height > 0) {
        imageUrl = `${imageUrl}&height=${height}&mode=crop`;
    }
    if (height > 0 && focalPoint) {
        imageUrl = `${imageUrl}&rxy=${focalPoint.left.toString().replace(',', '.')},${focalPoint.top.toString().replace(',', '.')}`;
    }
 
    if (format) imageUrl = `${imageUrl}&format=webp`;
    
    return imageUrl;
};
 
export const GetSrcSetString = ({
    url,
    focalPoint,
    format,
    aspectRatio,
}: {
    url: string;
    focalPoint: { left?: number; top?: number };
    format?: 'webp';
    aspectRatio?: number;
}): string => {
    const { imageSizes } = config.images;
 
    let string = '';
 
    imageSizes.map((width) => {
        let height = 0;
        if (aspectRatio) {
            height = aspectRatio * width;
        }
 
        return (string += `${GetImageUrl({
            url,
            focalPoint,
            width: width,
            height,
            format,
        })} ${width}w, `);
    });
 
    return string.substring(0, string.length - 2);
};