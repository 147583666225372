import classNames from 'classnames';
import { Heading, Button, FormfieldString, FormfieldCheckboxSelectProps } from 'ui/components';
import styles from './LocalSearchBox.module.scss';
import { ReactComponent as IconSearchGreen } from 'ui/icons/icon_search_nobg.svg';
import { FormfieldCheckboxSelect, Option } from 'ui/components/2-molecules/Form/FormfieldCheckboxSelect';

export interface LocalSearchBoxProps {
	heading?: string;
	inputText?: string;
	buttonText?: string;
	resetText?: string;
	searchSuggestions?: string[];
	filters?: FilterType[];
	activeFilters?:string[];
	onlyFilter?: boolean;
	inputValueText?:string;
	onInputValueChange?: (value) => void;
	onFilterChange?:(opt, activeFilterName) => void;
	onKeyPress?:(opt, activeFilterName) => void;
}

export type FilterType = {
	filterName: string;
	filterId?:number;
	options:Option[];
};
export const LocalSearchBox: React.FC<LocalSearchBoxProps> = ({
	heading,
	inputText,
	buttonText,
	resetText,
	searchSuggestions,
	filters,
	onlyFilter,
	inputValueText,
	onInputValueChange,
	onFilterChange,
	onKeyPress,
}) => {
	return (
		<div className={styles.LocalSearchBox}>
			{heading && (
				<Heading style="sm" headingLevel="h4" className={styles.LocalSearchBox_heading}>
					{heading}
				</Heading>
			)}

			<div className={styles.LocalSearchBox_content}>
				<div className={classNames(styles.LocalSearchBox_inputContainer)}>
					{!onlyFilter && inputValueText && (
						<FormfieldString
							placeholder={inputText}
							type="search"
							id={'search'}
							name={'search'}
							value={inputValueText}
							onChange={(e) => onInputValueChange(e.target.value)}
							className={classNames(
								styles.LocalSearchBox_input,
								{
									[styles.LocalSearchBox_input___isActive]: inputValueText.length > 2,
								},
								styles.LocalSearchBox_inputSearch,
							)}
						/>
					)}
					{searchSuggestions && inputValueText && inputValueText.length > 2 && !onlyFilter && (
						<ul role="list" className={styles.LocalSearchBox_resultsContainer}>
							{searchSuggestions.slice(0, 5).map((result, index) => (
								<li role="listitem" key={index} className={styles.LocalSearchBox_result}>
									{result}
								</li>
							))}
						</ul>
					)}

					<div className={styles.LocalSearchBox_filter}>
						{filters &&
							filters.map((filter, index) => (
								<FormfieldCheckboxSelect
									key={index}
									id={filter.filterName}
									name={filter.filterName}
									className={classNames(styles.LocalSearchBox_input, styles.LocalSearchBox_inputFilter)}
									options={filter.options}
									onChange={(e, optionName)=> onFilterChange(e,optionName)}
									onKeyPress={(e)=> onKeyPress(e, filter.filterName)}
								/>
							))}
					</div>
				</div>

				<div className={classNames(styles.LocalSearchBox_buttons, { [styles.Button__baseline]: !onlyFilter })}>
					<Button type="submit" style="secondary" className={styles.LocalSearchBox_button}>
						<div className={styles.LocalSearchBox_buttonText}>{buttonText}</div>
						<div className={styles.LocalSearchBox_iconWrapper}>
							<IconSearchGreen className={styles.LocalSearchBox_icon} />
						</div>
					</Button>
					<Button className={styles.LocalSearchBox_resetButton}>{resetText}</Button>
				</div>
			</div>
		</div>
	);
};
