import classNames from 'classnames';
import styles from './SocialLinks.module.scss';
import { ReactComponent as IconFacebook } from 'ui/icons/icon_facebook.svg';
import { ReactComponent as IconX } from 'ui/icons/icon_x.svg';
import { ReactComponent as IconYouTube } from 'ui/icons/icon_youtube.svg';
import { ReactComponent as IconInstagram } from 'ui/icons/icon_instagram.svg';
import { ReactComponent as IconLinkedIn } from 'ui/icons/icon_linkedin.svg';
import { Icon, Link } from 'ui/components';

interface SocialLink {
	type?: 'facebook' | 'twitter' | 'youtube' | 'instagram' | 'linkedin' | 'x';
	title: string;
	url: string;
	target?: string;
}

export interface SocialLinksProps {
	className?: string;
	items?: SocialLink[];
}

export const SocialLinks: React.FC<SocialLinksProps> = ({ className, items }) => {
	if (!items) {
		return null;
	}

	const renderIcon = (item: SocialLink) => {
		switch (item.type) {
			case 'facebook':
				return <IconFacebook aria-hidden="true" />;
			case 'twitter':
			case 'x':
				return <IconX aria-hidden="true" />;
			case 'youtube':
				return <IconYouTube aria-hidden="true" />;
			case 'instagram':
				return <IconInstagram aria-hidden="true" />;
			case 'linkedin':
				return <IconLinkedIn aria-hidden="true" />;
		}
	};

	return (
		<ul role='list' className={classNames(styles.SocialLinks, className)}>
			{items.map((item, index) => (
				<li role='listitem' key={index} className={styles.SocialLinks_item}>
					<Link className={styles.SocialLinks_link} ariaLabel={item.title} url={item.url} target={item.target}>
						<Icon className={styles.SocialLinks_icon} title={item.title}>
							{renderIcon(item)}
						</Icon>
					</Link>
				</li>
			))}
		</ul>
	);
};
