/* eslint-disable  */
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { ReactComponent as IconArrow } from 'ui/icons/arrow-right.svg';
import { CSSTransition } from 'react-transition-group';
import styles from './SubNavigationItem.module.scss';
import { Link } from 'ui/components';
import { useRouter } from 'next/router';

export interface SubNavigationItemProps {
	itemHeader?: Navigation.NavigationItem;
	items: Navigation.NavigationItem[];
	className?: string;
	setCurrentSubNav: ({ id, navLevel }: { id: number; navLevel: number }) => void;
	currentSubNav: {
		id: number;
		navLevel: number;
	};
	parentId: number;
	navId: number;
	currentPageId: number;
	navLevel: number;
	isMobile?: boolean;
	closeMenu: () => void;
}

export const SubNavigationItem: React.FC<SubNavigationItemProps> = ({
	itemHeader,
	items,
	className,
	setCurrentSubNav,
	currentSubNav,
	parentId,
	navId,
	currentPageId,
	navLevel,
	isMobile,
	closeMenu,
}) => {
	const router = useRouter();
	const ref = useRef(null);

	useEffect(() => {
		if (navId === currentPageId) {
			setCurrentSubNav({
				id: parentId,
				navLevel: navLevel === 0 ? navLevel : navLevel - 1,
			});
		}
		
		if (router.asPath === "/") {
			setCurrentSubNav({ id: navId, navLevel: 0 });
		}
	}, [router.asPath]);

	return (
		<>
			<CSSTransition
				in={currentSubNav.id === navId}
				timeout={{ appear: 0, exit: 300, enter: 300 }}
				appear={true}
				classNames={{ ...styles }}
				unmountOnExit
				nodeRef={ref}
			>
				<ul
					role='list'
					className={classNames(styles.SubNavigationItem, { [styles.SubNavigationItem___isMobile]: isMobile }, className)}
					style={{ transform: `translateX(${navLevel * 100}%)` }}
					ref={ref}
				>
					{itemHeader && (
						<li
							role='listitem'
							className={classNames(styles.SubNavigationItem_item, styles.SubNavigationItem_itemBack)}
							style={{
								borderLeftColor: `var(--color-theme--50)`, //TODO: PAgeArea does not exixts anymore
							}}
						>
							<button
								className={styles.SubNavigationItem_prev}
								onClick={() => setCurrentSubNav({ id: parentId, navLevel: navLevel - 1 })}
								aria-label="Back to parent"
							>
								<span className={styles.SubNavigationItem_prevIcon}>
									<IconArrow />
								</span>
							</button>
							{itemHeader.url && (
								<Link
									title={itemHeader.title}
									url={itemHeader.url}
									className={styles.SubNavigationItem_topLink}
									mouseDownHandler={closeMenu}
									ariaLabel={itemHeader.title}
								>
									<span className={styles.SubNavigationItem_textBack} aria-label={itemHeader.name}>
										{itemHeader.name}
									</span>
								</Link>
							)}
						</li>
					)}
					{items &&
						items.map((item: Navigation.NavigationItem, index: number) => {
							const hasChildren = item.descendants;
							return (
								<li
									role="listitem"
									className={classNames(
										styles.SubNavigationItem_item,
										{ [styles.isActive]: item.id === currentPageId },
										{ [styles.hasChildren]: hasChildren },
									)}
									style={{
										borderLeftColor: `var(--color-helbredsprofil--50)`, //TODO: change this when pageArea is implemented
									}}
									key={index}
								>
									{hasChildren ? (
										<div className={styles.SubNavigationItem_next} >
											<Link
												asText={item.url ? false : true}
												title={item.title}
												className={styles.SubNavigationItem_text}
												url={item.url}
												mouseDownHandler={closeMenu}
												ariaLabel={item.title}
											>
												{item.name || item.title}
											</Link>
											<button
												onClick={() => setCurrentSubNav({ id: item.id, navLevel: navLevel + 1 })}
												className={styles.SubNavigationItem_nextButton}
												aria-label="See undersides"
											>
												<span className={styles.SubNavigationItem_nextIcon}>
													<IconArrow />
												</span>
											</button>
										</div>
									) : (
										<Link
											mouseDownHandler={closeMenu}
											asText={item.url ? false : true}
											title={item.title}
											className={styles.SubNavigationItem_link}
											url={item.url}
											ariaLabel={item.title}
										>
											{item.name || item.title}
										</Link>
									)}
								</li>
							);
						})}
				</ul>
			</CSSTransition>
			{items &&
				items.map((item: Navigation.NavigationItem, index: number) => (
					<SubNavigationItem
						navLevel={navLevel + 1}
						key={index}
						navId={item.id}
						currentPageId={currentPageId}
						setCurrentSubNav={setCurrentSubNav}
						parentId={navId}
						items={item.descendants}
						itemHeader={item}
						currentSubNav={currentSubNav}
						isMobile={isMobile}
						closeMenu={closeMenu}
					/>
				))}
		</>
	);
};
