import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import styles from './Picture.module.scss';
import { GetImageUrl, GetSrcSetString } from 'helpers/getImageUrl';
import config from 'helpers/imageSizes';
import { inView } from 'helpers/inView';

export interface PictureProps {
	url?: string;
	altText?: string;
	focalPoint?: { left?: number; top?: number };
	sizes?: string;
	lazyload?: boolean;
	objectFitFallback?: boolean;
	isCover?: boolean;
	aspectRatio?: number;
	className?: string;
	properties?: { altText?: string };
	removeAlt?: boolean;
}

export const Picture: React.FC<PictureProps> = ({
	isCover = true,
	sizes = '100vw',
	url,
	altText,
	lazyload = true,
	objectFitFallback = true,
	focalPoint,
	aspectRatio,
	className,
	properties,
	removeAlt
}) => {
	const computedAltText = altText ? altText : properties?.altText;
	const { thumbnailSize, fallbackSize } = config.images;

	const thumbnailImageUrl = GetImageUrl({ url, width: thumbnailSize });
	const thumbnailImageUrlWebp = GetImageUrl({ url, width: thumbnailSize, format: 'webp' });

	const [src, setSrc] = useState(thumbnailImageUrl);
	const [imgSrcSet, setImgSrcSet] = useState(thumbnailImageUrl);
	const [sourceSrcSet, setSourceSrcSet] = useState(thumbnailImageUrlWebp);
	const [isFallback, setIsFallback] = useState(false);

	const pictureRef = useRef(null);

	useEffect(() => {
		if (objectFitFallback) objectFit();
		if (lazyload) inView({ elm: pictureRef.current, callback: reveal });
		if (!lazyload) reveal();
	}, [url, lazyload, objectFitFallback,isCover]);

	const reveal = () => {
		const srcSetString = GetSrcSetString({ url, focalPoint, aspectRatio });
		const srcSetStringWebp = GetSrcSetString({ url, focalPoint, format: 'webp', aspectRatio });
		const srcString = GetImageUrl({ url, width: fallbackSize });

		setImgSrcSet(srcSetString);
		setSourceSrcSet(srcSetStringWebp);
		setSrc(srcString);
	};

	const objectFit = () => {
		// If the useragent doesn't support ObjectFit (IE), we need to
		// add fallback image to the picture element
		const supportObjectFit = 'objectFit' in document.documentElement.style;

		if (!supportObjectFit){
			setIsFallback(true);
		}else{
			setIsFallback(false);
		}
	};

	return (
		<picture
			ref={pictureRef}
			className={classNames(styles.Picture, { [styles.Picture___cover]: isCover }, { [styles.isFallback]: isFallback }, className)}
			style={isFallback ? { backgroundImage: `url(${src})` } : {}}
		>
			{!isFallback && (
				<>
					<source sizes={sizes} type="image/webp" srcSet={sourceSrcSet} />
					{!removeAlt ? <img src={src} alt={computedAltText} className={styles.Picture_asset} srcSet={imgSrcSet} sizes={sizes} />
						: <img src={src} alt="dekorativt billede" aria-hidden="true" className={styles.Picture_asset} srcSet={imgSrcSet} sizes={sizes} />
					}
				</>
			)}
		</picture>
	);
};
