import {
	Spinner,
	BlurbCardProps,
	Container,
	PaginationReact,
	Heading,
	Paragraph,
	Link,
	LinkButton,
} from 'ui/components';
import styles from './NewsList.module.scss';
import classNames from 'classnames';

export interface NewsListProps {
	rootFolderId?: number;
	setOffset?: (n: number) => void;
	onPageClickHandler?: (ev: {
		index: number | null;
		selected: number;
		nextSelectedPage: number | undefined;
		event: object;
		isPrevious: boolean;
		isNext: boolean;
		isBreak: boolean;
		isActive: boolean;
	}) => void;
	pageNumber?: number;
	totalPages?: number;
	loadingStatus?: boolean;
	limit?: number;
	articles?: BlurbCardProps[];
	link?: {
		url: string;
		name?: string;
		target?: string;
	};
}

export const NewsList: React.FC<NewsListProps> = ({ articles, pageNumber, totalPages, loadingStatus, limit, onPageClickHandler, link }) => {
	if (loadingStatus) return <Spinner />;
	if (articles.length == 0) return <></>;

	return (
		<Container width="Standard">
			{link && (
				<div className={classNames(styles.NewsList_buttonContainer)}>
					<LinkButton ariaLabel={link.name} url={link.url} style="secondary" title={link.name} className={classNames(styles.NewsList, styles.NewsList_button___desktop)}>
						{link.name}
					</LinkButton>
				</div>
			)}

			<PaginationReact
				pageCount={totalPages}
				selectedPage={pageNumber}
				onPageNumberClick={onPageClickHandler}
				handlePageClick={onPageClickHandler}
				itemsPerPage={limit}
			/>

			<ul role='list' className={styles.NewsList}>
				{articles.map((item, index) => (
					<li role='listitem' className={styles.NewsList_item} key={index}>
						<Link
							className={styles.NewsList_headingLink}
							hasArrow={false}
							url={item.link?.url}
							target={item.link?.target}
							title={item.link?.name}
							ariaLabel={item.link?.name}
						>
							<Heading headingLevel="h2" style="xs" className={styles.NewsList_heading}>
								{item.heading}
							</Heading>
						</Link>
						<Heading headingLevel="h6" style="2xs" className={styles.NewsList_date}>
							{item.date}
						</Heading>
						<Paragraph style="lg">{item.text}</Paragraph>
					</li>
				))}
			</ul>

			<PaginationReact
				pageCount={totalPages}
				selectedPage={pageNumber}
				onPageNumberClick={onPageClickHandler}
				handlePageClick={onPageClickHandler}
				itemsPerPage={limit}
			/>

			{link && (
				<LinkButton ariaLabel={link.name} url={link.url} style="secondary" className={classNames(styles.NewsList_button, styles.NewsList_button___mobile)}>
						{link.name}
				</LinkButton>
				)}
		</Container>
	);
};
