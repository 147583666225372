import { Grid, GridCell, PaginationProps, Container, Heading, LetterFilter, Picture } from 'ui/components';
import styles from './MapLocations.module.scss';
import { MapLocationProps, MapLocation } from '../../2-molecules/MapLocation'
import { LocalSearchBox, Button } from 'ui/components';
import { ReactComponent as IconPrint } from 'ui/icons/icon_print.svg';
import { useState } from 'react';
import classNames from 'classnames';
export interface MapLocationsProps {
	//LetterSortedlocations?: { character: string; list: MapLocationProps[] }[];
	//pagination?: PaginationProps;
	//searchSuggestions?: string[];
	mapLocationImage?: any | null;
	heading?: string;
	location?: string;
	searchButtonText?: string;
	resetButtonText?: string;
	printButtonText?: string;
	LetterFilterLinkTitle: string;
}

export const MapLocations: React.FC<MapLocationsProps> = ({
	mapLocationImage,
	heading,
	location,
	printButtonText,
}) => {

	return (
		<Container width="Standard" className={styles.MapLocations}>
			{/* THIS BOILERPLATE CODE SHOULD BE DELETED WHEN INSERTING CLUDO SCRIPT!! */}
			{/* <LocalSearchBox
				heading={heading}
				buttonText={searchButtonText}
				searchSuggestions={searchSuggestions}
				resetText={resetButtonText ? resetButtonText : 'Nulstil'}
			/>
			<LetterFilter letters={lettersArr} activeLetter={activeLetter} setActiveLetter={setActiveLetter} linkTitle={LetterFilterLinkTitle} />
			{LetterSortedlocations &&
				LetterSortedlocations.map((item, index) => (
					<div key={index} className={styles.LocationsMap_list}>
						{item.character == activeLetter &&(
							<Heading headingLevel="h4" style="sm" className={styles.MapLocations_list__header}>
								{item.character}
							</Heading>
						)}
						{item.character == activeLetter && (
							<Grid key={index} wrap={true}>
								{item?.list.map((item) => (
									<GridCell key={index} mobileWidth="100" desktopWidth="50">
										<MapLocation {...item} />
									</GridCell>
								))}
							</Grid>
						)}
					</div>
				))} */}
			<Button icon={<IconPrint />} style="secondary">
				{printButtonText ? printButtonText : 'Print'}
			</Button>
			{mapLocationImage &&
				<div className={classNames(styles.MapLocations__map)}>
					<Picture {...mapLocationImage}  aspectRatio={4} isCover={true} />		
				</div>
			}
		</Container>
	);
};
