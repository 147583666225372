import ClassNames from 'classnames'
import styles from './ImageWithTitle.module.scss'
import { Picture, Heading, Paragraph } from 'ui/components';

export interface ImageWithTitleProps{
    image?: {
		url: string;
		altText: string;
		focalPoint?: { left: number; top: number };
	};
    title: string;
    link: Content.Link;
    intro: string;
    numberOfColumns: string;
}

export const ImageWithTitle: React.FC<ImageWithTitleProps> = ({image, title, link, intro, numberOfColumns}) => {
    return (
        <div className={ClassNames(styles.ImageWithTitle, styles[`ImageWithTitle__${numberOfColumns}`])}>
            <Picture {...image} className={ClassNames(styles.Image)}></Picture>
            <div className={ClassNames(styles.ImageWithTitle_overlay)} />
            {link && <a href={link.url} aria-label={link.title} className={ClassNames(styles.Link)}>
                <div className={ClassNames(styles.Title)}>
                    <Heading headingLevel={'h2'} style={'sm'} className={ClassNames(styles.Heading)}>{title}</Heading>
                    <Paragraph style={'md'} className={ClassNames(styles.Intro)}>{intro}</Paragraph>
                </div>
            </a>}
        </div>
    );
};
