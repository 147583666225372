import classNames from 'classnames';
import styles from './Link.module.scss';
import { ReactComponent as Arrow } from '../../../../icons/arrow-right.svg';
import { useRouteContext } from 'ui/contexts/RouteContext';

export interface LinkProps {
	children: React.ReactNode;
	style?: 'primary' | 'sm';
	ariaLabel?: string;
	className?: string;
	url?: string;
	asText?: boolean;
	target?: string;
	title?: string;
	//onClick?: (e?:any) => void;
	hasArrow?: boolean;
	tabIndex?: number;
	rel?: string;
	//LinkWrapper?<T extends unknown>(arg:T):JSX.Element;
	clickHandler?: (event: React.MouseEvent) => void;
	mouseDownHandler?: (event: React.MouseEvent) => void;
}

export const Link: React.FC<LinkProps> = ({ children, style, ariaLabel, className, url, target, asText, title, hasArrow, rel, clickHandler, mouseDownHandler }) => {
	const routeHandler = useRouteContext();	
	// function LinkWrapperElement({wrappedUrl, children}):JSX.Element{
	// 	return LinkWrapper? (<LinkWrapper href={wrappedUrl}>{children}</LinkWrapper>):(<>{children}</>)
	// }
	
	return (
	<>
		{asText ? (
			<p className={classNames(styles.Link, styles[`Link___${style}`], className)}>{children}</p>
		) : (
		//	<LinkWrapperElement wrappedUrl={url}>
			<a
				href={url}
				aria-label={ariaLabel}
				title={title}
				target={target}
				className={classNames(styles.Link, styles[`Link___${style}`], className)}
				//onClick={ onClick }
				rel={rel}
				onMouseDown={mouseDownHandler}
				onClick={(event) => {
					if (clickHandler) {
						clickHandler(event);

						return;
					}

					// We only run the routeHandler if no clickHandler is provided.
					routeHandler && routeHandler(event, url, target);
				}}
			>
				{children}
				{hasArrow && <Arrow aria-hidden="true" className={styles.Link_arrowIcon} />}
			</a>
		//	</LinkWrapperElement>
		)}
	</>
)};
