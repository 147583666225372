export interface ErrorPageProps {
	errorCode: number;
	message?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode, message }) => {
	return (
		<>
			<h1>An error {errorCode} has occurred</h1>
		</>
	);
};
