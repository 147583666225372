import classNames from 'classnames';
import styles from './Notification.module.scss';
export interface NotificationProps {
	children?: React.ReactNode;
	className?: string;
	hidden: boolean;
}

export const Notification: React.FC<NotificationProps> = ({ children, className, hidden }) => {
	return (
		<div
			aria-live="assertive"
			className={classNames(
				styles.Notification,
				'u-grid__cell u-grid__cell--width-100',
				{ [styles.isHidden]: hidden },
				className,
			)}
		>
			{children}
		</div>
	);
};
