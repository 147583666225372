import classNames from 'classnames';
import { Heading, Paragraph, Container, Picture, LinkButton, Link } from 'ui/components';
import styles from './UndersideCard.module.scss';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface UndersideCardProps {
	className?: string;
	backgroundColor?: string;
	image?: Content.Image;
	video?: string;
	heading?: Content.Link;
	description?: string;
	showLinks?: boolean;
	links?: Content.Link[];
	showMore?: boolean;
}

export const UndersideCard: React.FC<UndersideCardProps> = ({
	className,
	backgroundColor = '#FFFFFF',
	image,
	video,
	heading,
	description,
	showLinks,
	links,
}) => {
	const dictionary = useDictionaryContext();

	return (
		<div
			className={classNames(styles.Underside, className, [{ [styles.Underside___noMedia]: !image && !video }])}
			style={{ backgroundColor: backgroundColor ? backgroundColor : `var(--theme-20)` }}
		>
			{(image || video) && (
				<>
					<div className={styles.UndersideCard_mediaContainer}>
						{(image || video) && (
							<div className={styles.Underside_media}>
								{image && !video && <Picture url={image.url} altText={image.properties.altText} />}

								{video && !image && <div dangerouslySetInnerHTML={{ __html: video }} />}

								{/* IF BOTH IMAGE AND VIDEO EXISTS, SHOW ONLY IMAGE */}
								{image && video && <Picture url={image.url} altText={image.properties.altText} />}
							</div>
						)}

						{(heading || description) && (
							<div className={styles.Underside_text}>
								{heading && (
									<Link url={heading.url} ariaLabel={heading.name}>
										<Heading style="md" headingLevel="h2" className={styles.Underside_heading}>
											{heading.name}
										</Heading>
									</Link>
								)}

								{description && (
									<Paragraph style="lg">
										<span dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
									</Paragraph>
								)}
							</div>
						)}
					</div>

					{showLinks && links && (
						<div className={styles.Underside_links}>
							{links.map((link, index) => (
								<LinkButton
									key={index}
									url={link.url}
									ariaLabel={link.name}
									target={link.target}
									style="secondary"
									className={styles.Underside_link}
								>
									{link.name}
								</LinkButton>
							))}
						</div>
					)}

					{!showLinks && heading && (
						<div className={styles.Underside_links}>
							<LinkButton url={heading.url} ariaLabel={heading.name} style="secondary">
								{dictionary.getValue('Action.ShowMore', null, 'Vis mere')}
							</LinkButton>
						</div>
					)}
				</>
			)}

			{!image && !video && (
				<Container width="Standard">
					<div className={styles.UndersideCard_mediaContainer}>
						{(heading || description) && (
							<div className={classNames(styles.Underside_text, styles.Underside_text___noMedia)}>
								{heading && (
									<Link url={heading.url} ariaLabel={heading.name}>
										<Heading style="md" headingLevel="h2" className={styles.Underside_heading}>
											{heading.name}
										</Heading>
									</Link>
								)}

								{description && (
									<Paragraph style="lg">
										<span dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
									</Paragraph>
								)}
							</div>
						)}
					</div>

					{showLinks && links && (
						<div className={classNames(styles.Underside_links, styles.Underside_links___noMedia)}>
							{links.map((link, index) => (
								<LinkButton
									key={index}
									url={link.url}
									ariaLabel={link.name}
									target={link.target}
									style="secondary"
									className={styles.Underside_link}
								>
									{link.name}
								</LinkButton>
							))}
						</div>
					)}

					{!showLinks && heading && (
						<div className={classNames(styles.Underside_links, styles.Underside_links___noMedia)}>
							<LinkButton url={heading.url} ariaLabel={heading.name} style="secondary">
								{dictionary.getValue('Action.ShowMore', null, 'Vis mere')}
							</LinkButton>
						</div>
					)}
				</Container>
			)}
		</div>
	);
};
