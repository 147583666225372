import classNames from 'classnames';
import styles from './SearchField.module.scss';
import { Button, FormfieldString } from 'ui/components';
import { ReactComponent as IconSearchGreen } from 'ui/icons/icon_search_nobg.svg';
import { useState, useRef} from 'react';
import { useRouter } from 'next/router';
export interface SearchFieldProps {
	className?: string;
	buttonText?: string;
	id?: string;
	name?: string;
	label?: string;
	placeholder?: string;
	register?: any;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
		isHidden?: boolean;
	};
	errorMessage?: string;
	searchResults?: string[];
	toggleSearchField?: boolean;

	searchPage?: Content.Link;
}

export const SearchField: React.FC<SearchFieldProps> = ({
	className,
	buttonText,
	label,
	state,
	id,
	name,
	placeholder,
	register,
	searchPage,
	toggleSearchField,
}) => {
	const { disabled, hasError } = state ?? {};
	const [inputValue, setInputValue] = useState<string>('');
	const router = useRouter();

	const onTabEvent = (event) => {
		event.preventDefault();

		// If shift is pressed first, then tab is pressed
		if (event.shiftKey && event.keyCode === 9) {
			document.getElementById('headerSearchClose').focus();
			return;
		}

		// If tab is pressed
		if (event.keyCode === 9) {
			document.getElementById('search').focus();
			return;
		}
	};
	return (
		<div className={classNames(styles.SearchField, className, { [styles.hasError]: hasError }, { [styles.isDisabled]: disabled })}>
			<div className={styles.SearchField_container}>
				<div className={styles.SearchField_wrapper}>
					<FormfieldString
						type="search"
						id={id}
						state={state}
						name={name}
						label={label}
						placeholder={placeholder}
						className={styles.SearchField_input}
						register={register}
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						autoComplete='off'
					/>
					<div className={styles.SearchField_buttonContainer} onKeyDown={onTabEvent}>
						<Button type="submit" style="secondary" disabled={disabled} className={styles.SearchField_button} >
							<div className={styles.SearchField_buttonText}>{buttonText}</div>
							<div className={classNames(styles.SearchField_iconWrapper)}>
								<IconSearchGreen className={classNames(styles.SearchField_icon)} />
							</div>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
