import classNames from 'classnames';
import styles from './ContentCard.module.scss';
import { Heading, RichText, Picture, Paragraph, Link } from 'ui/components';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';

export interface ContentCardProps {
	className?: string;
	image?: {
		url: string;
		altText: string;
		focalPoint?: { left: number; top: number };
	};
	heading?: string;
	multiLink?: Content.Link[];
	richText?: string;
	singleLink?: Content.Link;
	borderColor?: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({ className, image, heading, multiLink, richText, singleLink, borderColor }) => {
	return (
		<div className={classNames(styles.ContentCard, className)}>
			{image && (
				<div
					className={classNames(styles.ContentCard_media, { [styles.ContentCard_media___withBorder]: borderColor ? true : false })}
					style={{ borderColor: borderColor }}
				>
					<span className={styles.ContentCard_mediabox}>
						<Picture aspectRatio={0.5625} {...image} sizes="100vw" />
					</span>
				</div>
			)}
			<div className={styles.ContentCard_textbox}>
				{heading && (
					<Heading className={styles.ContentCard_heading} headingLevel="h3" style="xs">
						{heading}
					</Heading>
				)}

				{richText && <RichText className={styles.ContentCard_richText} content={richText} />}

				{multiLink && multiLink[0] && (
					<ul role="list" className={styles.ContentCard_multiLinks}>
						{multiLink.map((link: Content.Link, index) => (
							<li role='listitem' key={index}>
								<Link ariaLabel={link.name} url={link.url} target={link.target} className={styles.ContentCard_multiLink}>
									<Paragraph>{link.name}</Paragraph>
									<div className={styles.ContentCard_iconWrapper}>
										<ArrowRight className={styles.ContentCard_icon} />
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}

				{singleLink && (
					<Link ariaLabel={singleLink.name} url={singleLink.url} target={singleLink.target} className={styles.ContentCard_singleLink}>
						{singleLink.name}
					</Link>
				)}
			</div>
		</div>
	);
};
