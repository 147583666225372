import classNames from 'classnames';
import styles from './BlurbCard.module.scss';
import { Heading, Paragraph, Link } from 'ui/components';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface BlurbCardProps {
	className?: string;
	link?: {
		url: string;
		target?: string;
		name?: string;
	};
	kicker?: string;
	text?: string;
	date?: Date | string;
	heading?: string;
}

export const BlurbCard: React.FC<BlurbCardProps> = ({ link, className, kicker, text, date }) => {
	const dictionary = useDictionaryContext();
	const dt = new Date(date);

	return (
		<div className={classNames(styles.BlurbCard, className)}>
			<div className={styles.BlurbCard_textbox}>
				{date && (
					<Paragraph style="md" className={styles.BlurbCard_kicker}>
						{dt.toLocaleString(dictionary.culture, { dateStyle: 'long' })}
					</Paragraph>
				)}
				{kicker && (
					<Heading headingLevel="h3" style="2xs" className={styles.BlurbCard_heading}>
						{kicker}
					</Heading>
				)}
				{text && (
					<Paragraph style="md" className={styles.BlurbCard_text}>
						{text}
					</Paragraph>
				)}
				{link && (
					<Link
						className={styles.BlurbCard_link}
						hasArrow={true}
						url={link?.url}
						target={link?.target}
						ariaLabel={link?.name + " " + dictionary.getValue('Action.ReadMore', null, 'Læs mere')}
					>
						{dictionary.getValue('Action.ReadMore', null, 'Læs mere')}
					</Link>
				)}
			</div>
		</div>
	);
};
