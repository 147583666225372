import classNames from 'classnames';
import styles from './Footer.module.scss';
import { Container, Grid, Logo, SocialLinks, Paragraph, Link } from 'ui/components';
import { GridCell } from 'ui/components/4-habitats/Grid';
import { ReactComponent as Arrow } from '../../../icons/arrow-right.svg';
import { FooterNavigation } from './FooterNavigation';

export interface FooterProps {
	className?: string;
	serviceNavigationItems?: Navigation.NavigationItem[];
	footerNavigation?: Navigation.NavigationItem[];
	hideFooterNavigation: boolean;
	columnOneHeading?: string;
	columnOneCustomLink?: Content.Link;
	columnTwoContent?: string;
	socialLinksHeading?: string;
	socialLinks?: Content.SocialLink[];
	logo: Content.Image;
	metaLinks?: Content.Link[];
	logoLink?: Content.Link;
	logoSize?: string;
}

export const Footer: React.FC<FooterProps> = ({
	className,
	serviceNavigationItems,
	footerNavigation,
	hideFooterNavigation,
	columnOneHeading,
	columnOneCustomLink,
	columnTwoContent,
	socialLinksHeading,
	socialLinks,
	logo,
	metaLinks,
	logoLink,
	logoSize
}) => {

	return (
		<>
			{!hideFooterNavigation && <FooterNavigation footerNavigation={footerNavigation} />}
			<footer className={classNames(styles.Footer, className)} role="contentinfo">
				<Container width="Standard">
					<Grid wrap={true} container={true}>
						<GridCell mobileWidth="100" desktopWidth="33" className={styles.Footer_serviceNavContainer}>
							<Paragraph style="xl" className={styles.Footer_heading}>
								{columnOneHeading}
							</Paragraph>
							<ul role='list' className={styles.Footer_list}>
								{serviceNavigationItems &&
									serviceNavigationItems.map((navigationItem: Content.Link, index) => (
										<li role='listitem' key={index} className={styles.Footer_linkShortcutItem}>
											<Link ariaLabel={navigationItem.name} url={navigationItem.url} className={styles.Footer_listLink} title={navigationItem.title}>
												{navigationItem.name}
												<Arrow aria-hidden="true" className={styles.Footer_listLinkIcon} />
											</Link>
										</li>
									))}
							</ul>
							{columnOneCustomLink && (
								<Link ariaLabel={columnOneCustomLink.name} url={columnOneCustomLink.url} target={columnOneCustomLink.target} className={styles.Footer_CustomLink}>
									{columnOneCustomLink.name}
								</Link>
							)}
						</GridCell>
						<GridCell mobileWidth="100" desktopWidth="33" className={styles.Footer_richtextContainer}>
							<div
								className={classNames(styles.Footer_RichText, className)}
								dangerouslySetInnerHTML={{ __html: columnTwoContent }}
							></div>

							<Paragraph style="xl" className={styles.Footer_heading}>
								{socialLinksHeading}
							</Paragraph>
							<SocialLinks
								items={socialLinks?.map((item) => ({
									type: item.type,
									url: item.url?.url,
									title: item.url?.name,
									target: item.url?.target,
								}))}
							></SocialLinks>
						</GridCell>
						<GridCell mobileWidth="100" desktopWidth="33" className={styles.Footer_logoContainer}>
							<Logo logo={logo} logoLink={logoLink} centered={true} size={'none'} className={classNames(
									styles.Footer_logo,
									className,
									{ "logo-koncern": logoSize === "Koncern" },
									{ "logo-small": logoSize === "Smal" },
									{ "logo-smaller": logoSize === "Smallere" },
								)} />
						</GridCell>
					</Grid>
				</Container>
				<Container width="Standard">
					<div className={styles.Footer_metaContainer}>
						<ul role="list" className={styles.Footer_metaList}>
							{metaLinks &&
								metaLinks.map((metaLink: Content.Link, index) => (
									<li
										role='listitem'
									 	key={index}>
										{/* TODO: add title */}
										<Link
											ariaLabel={metaLink.name}
											url={metaLink.url}
											target={metaLink.target}
											className={styles.Footer_metaListLink}
											title={metaLink.title}
										>
											{metaLink.name}
										</Link>
									</li>
								))}
						</ul>
					</div>
				</Container>
			</footer>
		</>
	);
};
