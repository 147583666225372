import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import styles from './ScriptJobTeaserModule.module.scss';
import { Heading, LinkButton } from 'ui/components';
import { Container} from 'ui/components/4-habitats';
import { useRouter } from 'next/router'

export interface ScriptJobTeaserModuleProps {
	script?: string;
	heading?: string;
	amount?: number;
	locations?: Models.locationElement[];
	jobTypes?: Models.jobTypeElement[];
	seeAllLink: Content.Link;
}

export const ScriptJobTeaserModule: React.FC<ScriptJobTeaserModuleProps> = ({ script, heading, locations, jobTypes, amount, seeAllLink }) => {
	const _strLocations = locations?.map((location) => location.properties.locationName).join('|');
	const _strJobTypes = jobTypes?.map((jobType) => jobType.properties.jobTypeName).join('|');
	const router = useRouter();
	const scriptRef = useRef<HTMLDivElement | null>(null);
	const _params = [
		`${_strLocations ? `location=${_strLocations}` : ''}`,
		`${_strJobTypes ? `jobtype=${_strJobTypes}` : ''}`,
		`${amount != 0 ? `amount=${amount}` : ''}`
	].filter(param => param != '')
		.join('&');
	const scriptParameters = _params ? `?${_params}` : '';
	const scriptBlock = `
		<script>function hrOnCallback() {
			var myScript = document.createElement('script');
			myScript.type = 'text/javascript';
			myScript.src = '${script}${scriptParameters}';
			document.body.appendChild(myScript);
		}</script>
		<script onload='hrOnCallback()' type="text/javascript" src="https://region-sjaelland.hr-skyen.dk/frame-api/hr.js"></script>
	`;
	useEffect(() => {
		const fragment = document.createRange().createContextualFragment(scriptBlock);
		scriptRef?.current?.append(fragment);
		return () => {
			
			// HR-on sets a delayed script by 500 ms for loading content. Changing page before 500 ms will cause an endless loop of loading hr on script.
			// Because of this we need to cancel the 'setInterval' as part of the cleanup.
			var timerJob = window["scriptInterval"];
			if(timerJob != undefined){
				clearInterval(timerJob);
			}

			// Because of the react nature we need to cleanup hr script sense every page with the hr module adds an aditional 
			const scriptLeftovers = document.querySelectorAll('script[src="https://region-sjaelland.hr-skyen.dk/js/easyXDM/easyXDM.min.js"]');
			scriptLeftovers.forEach(e => {e.remove()});

			// Remove bottom body script from HR-on which contains the information of how many items to load.
			const bodyScriptElements = document.querySelectorAll("[src^='https://region-sjaelland.hr-skyen.dk/frame-api/customers/regionsjaelland-v3.js']");
			if(bodyScriptElements != undefined){
				for(let scriptElement of bodyScriptElements as NodeListOf<Element>){
					scriptElement.remove();
				}
			}
			

			// Same as above. HR-on script adds an iFrame for each page change which include a hr-on module.
			const iframes = scriptRef?.current?.getElementsByTagName('iframe');
			if(iframes != undefined){
				for(let i = 0; i < iframes.length; i++){
					iframes[i].remove();
				}
			}
		};
	}, [scriptBlock, scriptRef]);
	
	return (
		<div className={styles.ScriptJobTeaserModule}>
			<Container width="Standard">
				<div className={styles.ScriptJobTeaserModule_headerContainer}>
					{heading &&
						<Heading headingLevel="h2" style="lg" className={styles.ScriptJobTeaserModule_Heading}>
							{heading}
						</Heading>
					}
					{seeAllLink && (
						<div className={styles.ScriptJobTeaserModule_seeAllLinkContainer}>
							<LinkButton ariaLabel={seeAllLink.name} url={seeAllLink.url} style="secondary" title={seeAllLink.name} className={classNames(styles.ScriptJobTeaserModule_seeAllLink, styles.ScriptJobTeaserModule_seeAllLink___desktop)}>
								{seeAllLink.name}
							</LinkButton>
						</div>
					)}
				</div>
			
			<div id="hrskyen" ref={scriptRef}><noscript>This page requires javascript</noscript></div>
			<a aria-label="e-Recruitment by HR-ON" style={{fontSize:"8px",color:"#aaaaaa", fontFamily:"verdana, sans-serif", textDecoration:"none"}} href="http://hr-on.com/">&copy; e-Recruitment by HR-ON</a>

			{seeAllLink && (
				<div className={styles.ScriptJobTeaserModule_seeAllLinkContainer}>
					<LinkButton ariaLabel={seeAllLink.name} url={seeAllLink.url} style="secondary" title={seeAllLink.name} className={classNames(styles.ScriptJobTeaserModule_seeAllLink, styles.ScriptJobTeaserModule_seeAllLink___mobile)}>
						{seeAllLink.name}
					</LinkButton>
				</div>
			)}


		</Container>
	</div>
	)
};