import { ImageWithTitle, ImageWithTitleProps } from 'ui/components/2-molecules';
import styles from './ImageGrid.module.scss'
import classNames from 'classnames';

export interface ImageGridProps{
    list: ImageWithTitleProps[];
    numberOfColumns: string;
}

export const ImageGrid: React.FC<ImageGridProps> = ({list, numberOfColumns}) => {
    return (
        <div className={classNames(styles.Grid)}>
            {list.map((element, index) => (
                <ImageWithTitle {...element} key={index} numberOfColumns={numberOfColumns}/>
            ))}
        </div>
    );
};
