import classNames from 'classnames';
import { Heading, Container, LinkButton } from 'ui/components';
import styles from './FindWay.module.scss';

export interface FindWayProps {
	className?: string;
	heading?: string;
	singleLink?: Content.Link;
	findWayItem?: { shortcuts?: Content.Link; text?: string }[];
	noBackground?: boolean;
}

export const FindWay: React.FC<FindWayProps> = ({ className, heading, findWayItem, noBackground = false, singleLink }) => {
	return (
		<Container width="Standard" className={styles.FindWay_container}>
			<div
				className={classNames(styles.FindWay, { [styles.FindWay___noBg]: noBackground }, className)}
				style={noBackground ? {} : { backgroundColor: `var(--theme-20)` }}
			>
				{(heading || singleLink) && (
					<div className={classNames(styles.FindWay_headingContainer)}>
						{heading && (
							<div className={classNames(styles.FindWay_alignMiddle)}>
								<Heading headingLevel="h4" style="sm" className={styles.FindWay_heading}>
									{heading}
								</Heading>
							</div>
						)}

						{singleLink && (
							<LinkButton ariaLabel={singleLink.name} url={singleLink.url} className={classNames(styles.FindWay_alignMiddle, styles.FindWay_buttonMapLink)} style="secondary">
								{singleLink.name}
							</LinkButton>
						)}
					</div>
				)}

				{findWayItem && (
					<div className={styles.FindWay_shortcuts}>
						{findWayItem.map((item, index) => (
							<LinkButton ariaLabel={item.text} key={index} url={item.shortcuts?.url} style="secondary">
								{item.text}
							</LinkButton>
						))}
					</div>
				)}
			</div>
		</Container>
	);
};
