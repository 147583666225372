import classNames from 'classnames';
import styles from './HeroCardCludo.module.scss';
import { Heading, Paragraph, Container, LinkButton, Button, Link } from 'ui/components';
import { ReactComponent as IconPrint } from 'ui/icons/icon_print.svg';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface HeroCardCludoProps {
	className?: string;
	heading?: string;
	manchet?: string;
	isFirstLevel?: boolean;
	anchorLinkText?: string;
	showAnchorLink?: boolean;
	link: Content.Link;
	linkText?: string;
	showPrint: boolean;	
}

export const HeroCardCludo: React.FC<HeroCardCludoProps> = ({
	className,
	heading,
	manchet,
	link,
	linkText,
	showAnchorLink,
	anchorLinkText,
	showPrint
}) => {
	const dictionary = useDictionaryContext();

	return (
		<div className={classNames(styles.HeroCardCludo, className)} style={{ backgroundColor: `var(--theme-30)` }}>
			<div
				className={classNames(
					styles.HeroCardCludo_textboxContainer
				)}
			>
				{(heading || manchet) && (
					<Container width={'Standard'}>
						<div className={styles.HeroCardCludo_textbox}>
							<div className={styles.HeroCardCludo_textbox__inner}>
								{heading && (
									<Heading headingLevel="h1" style="xl" className={styles.HeroCardCludo_heading}>
										{heading}
									</Heading>
								)}
								<div className={styles.HeroCardCludo_headingButtons}>
									{link && (
										<LinkButton ariaLabel={linkText ? linkText : link.name} url={link.url} target="_blank" style="secondary" className={styles.HeroCardCludo_link}>
											{linkText ? linkText : link.name}
										</LinkButton>
									)}
									{showPrint &&
										<Button className={styles.HeroCardCludo_link} style="secondarySmall" icon={<IconPrint />} onClick={() => window.print()}>
											{dictionary.getValue('Action.Print', null, 'Udskriv')}
										</Button>
									}
								</div>
							</div>
							{manchet && (
								<Paragraph style="manchet" className={classNames(styles.HeroCardCludo_manchet)}>
									<span dangerouslySetInnerHTML={{ __html: manchet.replace(/(?:\r\n|\r|\n)/g, '<br>')  }}></span>
								</Paragraph>
							)}
							{showAnchorLink && (
								<Link ariaLabel={anchorLinkText} url="#contactBox" hasArrow={true} className={styles.HeroCardCludo_contactAnchorLink}>
									{anchorLinkText}
								</Link>
							)}
						</div>
					</Container>
				)}
			</div>
		</div>
	);
};
